import React, { useEffect, useState } from 'react'
import { favRed, favouriteLight, profile_image_placeholder, seeAllArrow } from '../../../../Constants/images';
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';
import './channels.css'

function Channels(props) {
   const [channelList, setChannelList] = useState([])
   const navigate = useNavigate()
   const [_, forceUpdate] = useState(0)
   const [refresh, setRefresh] = useState(false)

   async function getAPI() {
      props?.setIsLoading(true)
      let url;
      if (props?.category != 0) {
         url = "channel/channels-list?category_id=" + props.category + "&offset=0&limit=5&sort=z-a"
      } else {
         url = "channel/channels-list?filterSort=asc&limit=10"
      }
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setChannelList(result.data.channels)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }

      }
   }

   async function addFavourites(item) {
      if (item.is_fav == 0) {
         item.is_fav = 1
      } else {
         item.is_fav = 0
      }
      let data = {
         "channel_id": item?.id,
         "type": "1"
      }
      forceUpdate(Math.random())
      let result = await apiHelper.postRequest("channel/add-favorites", data)
      if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      }
   }

   useEffect(() => {
      getAPI()
   }, [props.category, refresh]);


   return (
      <div>
         {
            channelList?.length > 0 &&
            <section className="channel response-padding channel-home-main mt-4" style={{cursor:'pointer'}}>
               < div className="container">
                  <div className="chanel-header" >
                     <h3 className="fw-600 mb-0 common-btn btn-blue btn-shape f20-size" style={{fontSize:"20px"}}>Latest Networks</h3>
                     <button onClick={() => navigate(ROUTES.CHANNELS)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} className="ms-2" /></button>
                  </div>
                  <div className="chanel-wrapper mt-4">
                     <div className="row gy-2">
                        {channelList?.slice(-8)?.map((channel) =>
                           <div key={channel?.id} className="col-lg-3 col-md-6 px-2 position-relative">
                              <div onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=latestChannel", { state: { item: channel } })} className="chanel-content">
                                 
                                 <img src={channel?.profile_pic?channel?.profile_pic:profile_image_placeholder} className="chanl-img" alt="hello" />
                                 <div className="chanel-text channel-head-space">
                                    <h4 className='mb-2'>{channel?.name}</h4>
                                    {/* <p className='mb-0'>{channel?.category?.name}</p> */}
                                 </div>
                              </div>
                              <div className='like-img-box'>
                                 {channel.is_fav == 1 ?
                                    <img onClick={() => addFavourites(channel)} src={favRed} className="" /> :
                                    <img onClick={() => addFavourites(channel)} src={favouriteLight} className="" />
                                 }
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>


            </section>
         }
      </div>
   )
}

export default Channels;