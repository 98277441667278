import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './bucket.css';
import ReactLoader from '../../ControlComponents/react-loader'
import {bucketImg } from '../../Constants/images';
import AddBucketModal from './addBucketModal';
import BucketList from './bucketList';


const Bucket = () => {
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [addBucketModal, setAddBucketModal] = useState(false)
    const [tierList, setTierList] = useState([])
    const handleAdd = () => {
        setAddBucketModal(!addBucketModal)
    }

    async function getAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/get-tiers-list")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setTierList(result.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }
    
    useEffect(() => {
        getAPI()
    }, [refresh])

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5 ps-4'>
                        <div className="">
                            <h3 className="fw-600 mb-0">Plans</h3>
                        </div>
                    </div>
                    {
                        (tierList.length == 0 && isLoading == false) ?
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={bucketImg} />
                                <div className="mt-5" onClick={handleAdd}>
                                    <a className="bucket-btn common-btn w-100" style={{ cursor: "pointer" }}>Add Plan</a>
                                </div>
                            </div> :
                            <>{isLoading == false &&
                                <BucketList getAPI={getAPI} tierList={tierList} setIsLoading={setIsLoading} />}</>
                    }
                </div>
            </div>
            {addBucketModal && <AddBucketModal setAddBucketModal={setAddBucketModal} tierList={tierList} getAPI={getAPI} />}
        </div>
    )
}

export default Bucket;