import React, { useEffect, useRef, useState } from 'react';
import {  no_plan_placeholder, profile_image_placeholder } from '../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ROUTES from '../../Helper/route-helper';
import './allchallenges.css';

function AllChallenges() {
  const navigate = useNavigate()
  const [allChallenges, setAllChallenges] = useState([])
  const [featuredList, setFeaturedList] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1);
  const loadMoreRef = useRef(null);
  const [scroll, setScroll] = useState(false)
  const [hasMoreItems, setHasMoreItems] = useState(true);

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest(`challenges/challenges-list?offset=${page}`)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setFeaturedList(result.data.featured);
      const newChallenges = result?.data.allChallenge;
      if (page === 1) {
        setAllChallenges(newChallenges);
      } else {
        setAllChallenges((prevBites) => [...prevBites, ...newChallenges]);
      }
      setHasMoreItems(newChallenges.length === 12);
      let response = await apiHelper.getRequest("auth/category-list")
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
  }, [refresh, page])


  const handleChange = async (e) => {
    let result = await apiHelper.getRequest("challenges/challenges-list?search=" + e.target.value)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setAllChallenges(result.data.data);
      setIsLoading(false)
    }
  }

  const handleNavigate = (item) => {
    if (item.tier_details?.is_purchased == 0) {
      localStorage.setItem('tierContentId', item.id + 'challenge')
      navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
    }
    else {
      localStorage.setItem("userChallengeId", item.id)
      localStorage.setItem("challengeItem", JSON.stringify(item))
      navigate(ROUTES.CHALLENGES_DETAILS, { state: { item } })
    }

  }

  const handleLoadMore = () => {
    setScroll(true)
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    if (loadMoreRef.current && scroll) {
      loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setScroll(false)
    }
  }, [allChallenges, scroll]);

  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      {
        allChallenges.length > 0 && isLoading == false ? <section className="favourite-cus bite response-padding ">
          <section className="featured response-padding ">
            <div className="container">
              <div className="chanel-header">
                <h3 className="fw-600">Featured</h3>
              </div>
              <div className="challene-wrapper mt-4">
                <div className="row">
                  {
                    featuredList.slice(0, 3).map((element, index) => {
                      return (
                        <div
                          className="featured-box-cus position-relative col-lg-4 mb-4 mb-lg-0"
                          key={index}
                        >
                          <div className="video-bg video-wrapr" style={{ cursor: "pointer" }}>
                            <div className="video-content">
                              <img
                                src={element?.thumbnail}
                                className="img-video img-fluid"
                                alt=""
                                onClick={() => handleNavigate(element)} style={{ cursor: 'pointer' }}
                              />
                            </div>
                            <div className='content-video-feat'>
                              <div className='tooltip-container'>
                                <h6 className="schedule-title my-3">{element.title}</h6>
                                <span className="tooltip-text">{element?.title}</span>
                              </div>
                              <div className="video-detail">
                                <div>
                                  <img
                                    onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=challenges", { state: { item: element } })}
                                    src={element?.channelData?.profile_pic ? element?.channelData?.profile_pic : profile_image_placeholder}
                                    className="bite-inner-img chllge-img"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <h5 className="mb-0 fw-600" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=challenges", { state: { item: element } })} >{element?.channelData?.name}</h5>
                                  <p className="mb-0" onClick={() => navigate(ROUTES.CATEGORY, { state: { id: element?.category?.id, catName: element?.category?.name } })}>{element?.category.name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>

            </div>
          </section>

          <div className="container">
            <div className="chanel-header d-flex align-items-center justify-content-between">
              <h3 className="fw-600 mb-0 mt-3">Challenges</h3>

              {/* <div className="col-lg-4 ">
              <form>
                <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                  <input onChange={handleChange} type="text" name="search" className="form-control" placeholder="Search" />
                  <button type="button" className="icon-search-category">
                    <img src={search} className="search-img" />
                  </button>
                </div>
              </form>
            </div> */}
            </div>

            <div className="chanel-wrapper mt-4">
              <div className="row gy-2">
                {
                  allChallenges?.map((element, index) => {
                    return (
                      <div key={element?.id} className="col-12  col-lg-6 col-md-6 col-xxl-4">
                        <div className="chanel-content">
                          {element?.thumbnail != null && <img src={element?.thumbnail} className="bite-img-fav img-width" alt="" />}
                          <div className="bite-wrapper">
                            <div className='tooltip-container'>
                              <h4 className="schedule-title mb-0">{element.title}</h4>
                              <span className="tooltip-text">{element?.title}</span>
                            </div>
                            <div className="chanel-text d-flex gap-3 align-items-center" style={{ cursor: 'pointer' }}>
                              <img src={element.channelData.profile_pic} className="bite-inner-img" alt="" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=challenges", { state: { item: element } })} />
                              <div>
                                <h6 className='fw-600 mb-0' onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=challenges", { state: { item: element } })}>{element.channelData.name}</h6>
                                <p className='mt-1' onClick={() => navigate(ROUTES.CATEGORY, { state: { id: element?.category?.id, catName: element?.category?.name } })}>{element.category.name}</p>
                              </div>
                            </div>
                            <button className="common-btn" style={{ cursor: "pointer" }}
                              onClick={() => handleNavigate(element)}
                            >View Detail</button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>

              {
                hasMoreItems && <div style={{ cursor: 'pointer' }} onClick={handleLoadMore} className="load-text mb-3">
                  Load More...
                </div>
              }
              <div ref={loadMoreRef} ></div>
            </div>
          </div>
        </section> :
          <>
            {
              !isLoading &&
              <>
                {allChallenges?.length == 0 &&
                  <>
                    <h3 className="fw-600 mb-0 mx-5 my-3">Challenges</h3>

                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                      <img src={no_plan_placeholder} alt="No Challenges" />
                      <button className='common-btn' onClick={() => navigate(ROUTES.CHANNELS + "?cd=network")} >Explore</button>
                    </div>
                  </>
                }
              </>
            }
          </>
      }

    </div>
  )
}

export default AllChallenges;