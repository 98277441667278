import React from 'react';
import { useNavigate } from 'react-router-dom';
import { noSeries, } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';

function UserBucketSeries({ dataList, detail }) {
  const navigate = useNavigate()
  const tierContentId = localStorage.getItem('tierContentId') || 0 + 'series'

  const handleView = (id) => {
    if (detail?.is_activated == 1) {
      navigate(ROUTES.SERIES_DETAIL, { state: { id: id } })
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className="fw-600 mb-0 mt-3" style={{ marginLeft: 12 }}>Series</h3>
      </div>
      <section className="favchannel-main-cus ">
        <div className="container-fluid">
          <div className='row'>
            <div className="chanel-wrapper mt-0">
              <div className="row gy-2">
                {
                  dataList?.length > 0 ?
                    <>
                      {
                        dataList?.map((item) => {
                          const isHighlighted = tierContentId?.replace(/\D/g, '') === item.series_detail?.id.toString();
                          return (
                            <div
                              key={item.series_detail?.id}
                              className={`col-md-6 col-xxl-4 response-col`}
                              onClick={() => handleView(item.series_detail?.id)}
                              style={{ cursor: detail.is_activated == 1 ? 'pointer' : 'not-allowed' }}
                            >
                              <div className={`bucketseries-custom channel-bites-content  ${isHighlighted ? 'highlight' : ''}`}>
                                <div className="position-relative">
                                  <img src={item?.series_detail?.thumbnail} className="channelbite-img" alt="" />
                                </div>
                                <div className="bite-wrapper-series">
                                  <div className='tooltip-container'>
                                    <h4 className='schedule-title mb-0'>{item.series_detail.title?.length > 40 ? item.series_detail.title?.substring(0, 40) + ".." : item.series_detail.title}</h4>
                                    <span className="tooltip-text">{item.series_detail?.title}</span>
                                  </div>       
                                                           <div className="chanel-text d-flex gap-3 align-items-center">
                                    <div>
                                      <p className="mt-1 mb-0">
                                        {item.series_detail?.episodes_count}{" "}
                                        {item.series_detail?.episodes_count === 1 ? "Episode" : "Episodes"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </> :
                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                      <img src={noSeries} alt="No Challenges" />
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default UserBucketSeries;