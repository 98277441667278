import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import ShopVideo from './shopVideo'
import ShopEpisode from './shopEpisode'
import ShopTrailor from './shopTrailor'
import UserSeriesChat from '../Frame20/userSeriesChat'
import ShopOtherSeries from './shopOtherSeries'
import BitesShop from './bitesShop'
import ShopCast from './castShop'
import RecommendSeries from './recommendSeries'
function ShopSeriesDetail() {
    const location = useLocation()
    const ref = useRef()
    const [seriesDetail, setSeriesDetail] = useState(null)
    const [seriesDetailNew, setSeriesDetailNew] = useState()
    const [id, setId] = useState(location?.state?.id)
    const [chatOpen, setChatOpen] = useState(false)
    const [creatorName, setCreatorName] = useState(null)
    const [creatorImg, setCreatorImg] = useState(null)
    const [moreSeries, setMoreSeries] = useState([])
    const [recommend, setRecommend] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [_, forceUpdate] = useState(0)
    const [activeComponent, setActiveComponent] = useState(0);
    const containerRef = useRef(null);
    const [click, setClick] = useState(false)


    async function getMyDetailsAPI() {
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setCreatorName(result?.data?.name);
            setCreatorImg(result.data?.profile_pic)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult?.data?.token)
                localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("series/series-detail?id=" + id)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setSeriesDetail(result.data.series)
            setSeriesDetailNew(result.data.series)
            setMoreSeries(result?.data?.moreSeries)
            setRecommend(result.data.recommend)
            const trimmedName = result?.data?.series?.title?.replace(/\s/g, '');;
            const chatId = `${result?.data?.series?.id}${trimmedName}`;
            localStorage.setItem("userChatId", chatId)
            setChatOpen(true)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
        getMyDetailsAPI()
        if (containerRef.current && click) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
            setClick(false)

        }
    }, [refresh, id])

    function modifyDuration(duration) {
        const durationParts = duration?.split(':');
        if (durationParts && durationParts[0] === '00') {
            return durationParts.slice(1)?.join(':');
        }
        return duration;
    }

    async function addFavourites(item) {
        if (item.is_fav == 0) {
            item.is_fav = 1
        } else {
            item.is_fav = 0
        }
        let data = {
            "series_id": item?.id,
            "type": "2"
        }
        forceUpdate(Math.random())
        let result = await apiHelper.postRequest("channel/add-favorites", data)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
        }
    }

    return (
        <div ref={containerRef}>
            <div ref={ref} className="main-inner-content">
                {isLoading && <ReactLoader />}
                <ShopVideo seriesDetail={seriesDetail} addFavourites={addFavourites} setIsLoading={setIsLoading} id={id} />
                <div className='container' style={{ display: 'flex', flexDirection: 'row', gap: 15, cursor: "pointer" }}>
                    <div className="stream-btn white-height">
                        <button className={activeComponent == 0 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(0)}>Episodes</button>
                        <button className={activeComponent == 1 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(1)}>Cast & Crew</button>
                        <button className={activeComponent == 2 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(2)}>Bites</button>
                        <button className={activeComponent == 3 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(3)}>Trailor & Clips</button>
                        <button className={activeComponent == 4 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(4)}>Comments</button>
                    </div>
                </div>
                {activeComponent === 0 && <ShopEpisode seriesDetail={seriesDetail} modifyDuration={modifyDuration} chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />}
                {activeComponent === 1 && <ShopCast seriesDetail={seriesDetail} />}
                {activeComponent === 2 && <BitesShop seriesDetail={seriesDetail} />
                }
                {activeComponent === 3 && <ShopTrailor seriesDetail={seriesDetail} modifyDuration={modifyDuration} />}
                {activeComponent === 4 && <UserSeriesChat chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />
                }
                <ShopOtherSeries setId={setId} seriesDetail={seriesDetail} moreSeries={moreSeries} getDetailsAPI={getDetailsAPI} setIsLoading={setIsLoading} setClick={setClick} />
                <RecommendSeries setId={setId} seriesDetail={seriesDetail} recommend={recommend} getDetailsAPI={getDetailsAPI} setIsLoading={setIsLoading} setClick={setClick} />
            </div>
        </div>
    )
}


export default ShopSeriesDetail;