import React from 'react'
import SearchSeries from '../../SeriesSearch/Components/SearchSeries/SearchSeries'
import ChannelSearch from '../../SearchChannels/Components/ChannelSearch/ChannelSearch'
import BiteSearch from '../biteSearch'
import LiveSearch from '../liveSearch'
import ShopSeacrh from '../shopSearch'

function AllSearch({ shopList, channelList, lives, bitesList, seriesList,tabSwitch , handleSearch,setIsLoading}) {
    return (
        <>
         
            <SearchSeries seriesList={seriesList} tabSwitch={tabSwitch}  handleSearch={handleSearch} setIsLoading={setIsLoading}/>
            <ChannelSearch channelList={channelList} tabSwitch={tabSwitch} />
            <BiteSearch bitesList={bitesList} tabSwitch={tabSwitch} />
            <LiveSearch lives={lives} tabSwitch={tabSwitch}/>
            <ShopSeacrh shopList={shopList} tabSwitch={tabSwitch} />
        </>
    )
}

export default AllSearch