import React, { useEffect, useState } from 'react'
import { addImg, modalCross } from '../../Constants/images'
import uploadToS3 from '../component/fileUpload';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import ERR_MESSAGE from '../../Helper/error-helper';
import { NotificationManager } from 'react-notifications';
import ReactLoader from "../../ControlComponents/react-loader"
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
const AddBucketModal = ({ setAddBucketModal, tierList, getAPI, item }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState();
  const [progressLoading, setProgressLoading] = useState(false)
  const [thumbnail, setThumbnail] = useState('')
  const [errors, setErrors] = useState("")
  const tierLength = tierList?.length == 0
  const [feilds, setFeilds] = useState({
    tierName: "",
    price: tierLength ? 'Free' : '',
    duration: "",
    description: "",
  })
  const handleChange = (e) => {
    setErrors('')
    setFeilds({
      ...feilds,
      [e.target.name]: e.target.value
    })
  };

  useEffect(() => {
    if (item) {
      setFeilds({
        ...feilds,
        tierName: item.plan_name,
        price: item.plan_type == 'free' ? 'Free' : item.price?.slice(0, 3),
        duration: `${item.duration} month`,
        description: item.description,
      })
    }
    setThumbnail(item?.image)
  }, [])

  const fileChange = (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/svg + xml' || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        fileUpload(file)
      }
    }
  }

  const fileUpload = async (file, thumb) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation) {
          setProgressLoading(false)
          setThumbnail(state.videoLocation)
        }
      }
    } catch (error) {
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const { tierName, price, duration, description } = feilds
    if (!thumbnail) {
      formIsValid = false;
      errors["thumbnail"] = ERR_MESSAGE.THUMBNAIL_EMPTY1
    }
    if (!tierName) {
      formIsValid = false;
      errors["tierName"] = ERR_MESSAGE.EMPTY_TIERNAME
    }

    if (!price) {
      formIsValid = false;
      errors["price"] = ERR_MESSAGE.EMPTY_PRICE
    }
    if (item) {
      if (item.plan_type != 'free') {
        if (!duration) {
          formIsValid = false;
          errors["duration"] = ERR_MESSAGE.EMPTY_DURATION
        }
      }
    }
    else {
      if (!tierLength) {
        if (!duration) {
          formIsValid = false;
          errors["duration"] = ERR_MESSAGE.EMPTY_DURATION
        }
      }
    }

    if (!description) {
      formIsValid = false;
      errors["description"] = ERR_MESSAGE.DESCRIPTION_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    let data;
    if (handleValidation()) {
      data = {
        "image": thumbnail,
        "plan_name": feilds.tierName,
        "plan_type": tierLength ? 'free' : 'paid',
        "description": feilds.description,
      }
      if (!tierLength) {
        data.price = feilds.price,
          data.duration = feilds.duration.match(/\d+/)?.[0]
      }
      setIsLoading(true)
      let result = await apiHelper.postService('creator/add-tier', data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false)
        setAddBucketModal(false)
        NotificationManager.success(result.message)
        getAPI()
      }
      else {
        setIsLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  const handleEdit = async (e) => {
    e.preventDefault()
    let data;
    if (handleValidation()) {
      data = {
        "id": item.id,
        "image": thumbnail,
        "plan_name": feilds.tierName,
        "plan_type": item?.plan_type == 'free' ? 'free' : 'paid',
        "description": feilds.description,
      }
      if (item?.plan_type != 'free') {
        data.price = feilds.price,
          data.duration =  feilds.duration.match(/\d+/)?.[0]
      }
      setIsLoading(true)
      let result = await apiHelper.postService('creator/edit-tier', data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false)
        setAddBucketModal(false)
        NotificationManager.success(result.message)
        getAPI()
      }
      else {
        setIsLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  return (
    <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      {isLoading && <div className='loader'>
        <ReactLoader />
      </div>}
      {progressLoading && <>
        <ProgressBarComponent progress={progress} />
      </>
      }

      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius  border-0">
          <div className="modal-header forgot-header align-items-center pt-4">
            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">{item ? "Edit" : "Add"} Plan</h3>
            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setAddBucketModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid" /></a>
          </div>
          <div className="modal-body forgot-body verify-modal-box px-0">
            <form >
              <div className='bite-height'>
                <div className='mb-3 tier-thumbnail position-relative'>
                  <img src={thumbnail ? thumbnail : addImg} className={`img-fluid ${thumbnail && "tier-thumbnail-preview"}`} />
                  <input type="file" className='add-img' accept='.jpg, .jpeg, .png, .svg' onChange={fileChange} />

                </div>
                {errors.thumbnail && <span className="err_msg d-flex justify-content-center">{errors.thumbnail}</span>}

                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Plan Name</label>
                  <input type='text' name="tierName" value={feilds.tierName} onChange={handleChange} className='border-edit  w-100' placeholder='Enter Here' ></input>
                  {errors.tierName && <span className="err_msg">{errors.tierName}</span>}

                </div>

                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Price</label>
                  <input
                    type='text'
                    name="price"
                    value={feilds?.price}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        value = value.slice(0, 3);
                      } else {
                        value = "free";
                      }
                      handleChange({ target: { name: "price", value } });
                    }}
                    className='border-edit  w-100'
                    placeholder='Enter Here'
                  />
                  {errors.price && <span className="err_msg">{errors.price}</span>}
                </div>

                {
                  item ?
                    <> {
                      item?.plan_type != 'free' &&
                      <>
                        <label className='fw-500 f18-size text-black pb-2'>Duration(In month)</label>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='mb-3'>
                              <select className='form-select border-edit w-100 month-tier scrollable-dropdown' name="duration" value={feilds.duration} onChange={handleChange}>
                                <option>Select Month</option>
                                {Array.from({ length: 5 }, (_, i) => (
                                  <option key={i + 1}>{` ${i + 1} month`}</option>
                                ))}
                              </select>
                              {errors.duration && <span className="err_msg">{errors.duration}</span>}

                            </div>
                          </div>
                        </div></>
                    }</> :
                    <> {
                      !tierLength &&
                      <>
                        <label className='fw-500 f18-size text-black pb-2'>Duration(In month)</label>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='mb-3'>
                              <select className='form-select border-edit w-100 month-tier scrollable-dropdown' name="duration" value={feilds.duration} onChange={handleChange}>
                                <option>Select Month</option>
                                {Array.from({ length: 5 }, (_, i) => (
                                  <option key={i + 1}>{` ${i + 1} month`}</option>
                                ))}
                              </select>
                              {errors.duration && <span className="err_msg">{errors.duration}</span>}

                            </div>
                          </div>
                        </div></>
                    }</>
                }

                <div className="mb-3"><label className="fw-500 f18-size text-black pb-2">Description</label><textarea className=" text-summary place-grey d-block w-100" placeholder="Enter Here" rows="6" name="description" value={feilds.description} onChange={handleChange}></textarea>
                  {errors.description && <span className="err_msg">{errors.description}</span>}
                </div>
              </div>
              {
                item ?
                  <div className=" mt-4 mb-5">
                    <input type='button' onClick={handleEdit} value="Save" className="common-btn w-100" />
                  </div> :
                  <div className=" mt-4 mb-5">
                    <input type='button' onClick={handleSubmit} value="Save" className="common-btn w-100" />
                  </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBucketModal
