import React from 'react';
import { useNavigate } from 'react-router-dom';
import {  dotsbg, noSeries, } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'
import { Dropdown } from 'react-bootstrap';
import DeleteContent from './deleteContent';
function BucketSeries({ setDeltId, getDeleteAPI, dataList,setDeleteModal,deleteModal }) {
  const navigate = useNavigate()

  const handleDeleteOpen = (id) => {
    setDeleteModal(true)
    setDeltId(id)
  }
  const handleView = (id) => {
    localStorage.setItem("series_id", id)
    navigate(ROUTES.CREATER_SERIES_DETAIL)
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className="fw-600 mb-0" style={{marginLeft: 12}}>Series</h3>
        <div className='col-6 text-end'>
          <button className=" common-btn" onClick={() => {
            navigate(ROUTES.CREATER_BUCKET_SERIES)
          }}>+Add New</button>
        </div>
      </div>
      <section className="favchannel-main-cus ">
        <div className="container-fluid">
          <div className='row'>
            <div className="chanel-wrapper mt-0">
              <div className="row gy-2">
                {
                  dataList?.length > 0 ?
                    <>
                      {
                        dataList?.map((item) => (
                          <div className="col-md-6 col-xxl-4 response-col">
                            <div className="bucketseries-custom channel-bites-content">
                              <div className='position-relative'>
                                <img src={item?.series_detail?.thumbnail} className="channelbite-img" alt="" />
                                <div className='dotsmain'>
                                  <Dropdown>
                                    <Dropdown.Toggle id="custom-dropdown-toggle">
                                      <img src={dotsbg} className='img-fluid'></img>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                      <Dropdown.Item
                                        onClick={() => handleDeleteOpen(item.id)}
                                      > Remove</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="bite-wrapper-series" onClick={() => handleView(item.series_detail?.id)} style={{cursor:'pointer'}}>
                                <div className='tooltip-container'>
                                    <h4 className='schedule-title mb-0'>{item.series_detail.title?.length > 40 ? item.series_detail.title?.substring(0, 40) + ".." : item.series_detail.title}</h4>
                                    <span className="tooltip-text">{item.series_detail?.title}</span>
                                  </div>
                                <div className="chanel-text d-flex gap-3 align-items-center">
                                  <div>
                                    <p className='mt-1 mb-0'>{item.series_detail.episodes_count} {item.series_detail.episodes_count == 1 ? "Episode" : 'Episodes'}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </> :
                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                      <img src={noSeries} alt="No Challenges" />
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </section>
      {deleteModal && <DeleteContent setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI }check='series' />}
    </div>
  )
}

export default BucketSeries;