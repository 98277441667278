import React, { useState } from 'react';
import { noFavourites } from '../../Constants/images';
import './team-series.css'
import MemberModal from './memberModal';

function TeamMember() {
  const [memberModal, setMemberModal] = useState(false)
  const handleOpen = () => {
    setMemberModal(!memberModal)
  }
  
  return (
    <div>
      <div className="main-inner-content">
        <section className="favourite-cus bite response-padding ">
          <div className="container">
            <div className="chanel-header d-flex align-items-center justify-content-between">
              <h3 className="fw-600 mb-0">Team Members</h3>
              <div className="col-lg-4" >
                <form>
                  <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                  </div>
                </form>
              </div>
            </div>
            <section className="py-60  favchannel-main-cus">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative" onClick={handleOpen}>
                    <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                      <img src={noFavourites} className="img-fluid channel-img" />
                      <h4 className="channel-text fw-600 mt-3 mb-1">John Smith</h4>
                      <p className="channel-para mb-0 team-mail">Smithjohn@gmail.com</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                    <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                      <img src={noFavourites} className="img-fluid channel-img" />
                      <h4 className="channel-text fw-600 mt-3 mb-1">John Smith</h4>
                      <p className="channel-para mb-0 team-mail">Smithjohn@gmail.com</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                    <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                      <img src={noFavourites} className="img-fluid channel-img" />
                      <h4 className="channel-text fw-600 mt-3 mb-1">John Smith</h4>
                      <p className="channel-para mb-0 team-mail">Smithjohn@gmail.com</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                    <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }} >
                      <img src={noFavourites} className="img-fluid channel-img" />
                      <h4 className="channel-text fw-600 mt-3 mb-1">John Smith</h4>
                      <p className="channel-para mb-0 team-mail">Smithjohn@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
              {memberModal && <MemberModal setMemberModal={setMemberModal} />}
            </section>
          </div>
        </section>
      </div>
    </div>
  )
}

export default TeamMember;