import React, { useEffect, useState } from 'react'
import apiHelper from '../../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../../Helper/config'
import ReactLoader from '../../../ControlComponents/react-loader'
import SeriesList from './seriesList'
import { no_plan_placeholder } from '../../../Constants/images'
import FeatureSlider from './featureSlider'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../Helper/route-helper'
import MySeriesList from './myseries'

function UserSeries() {
  const [featuredList, setFeaturedList] = useState([])
  const [seriesList, setSeriesList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(false)
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [mySeriesList, setMySeriesList] = useState([])
  const [tabSwitch, setTabSwitch] = useState(0)
  const navigate = useNavigate()

  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest(`series/series-list?offset=${page}`)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      const newSeries = result?.data;
      if (page === 1) {
        setSeriesList(result?.data);
      } else {
        setSeriesList((prevSeries) => [...prevSeries, ...newSeries]);
      }
      setHasMoreItems(newSeries.data.length === 12)
      setIsLoading(false)
      setFeaturedList(result?.data)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function getMyAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest(`series/my-purchased-series?offset=${page}&limit=12`)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      const newSeries = result?.data;
      if (page === 1) {
        setMySeriesList(result?.data);
      } else {
        setMySeriesList((prevSeries) => [...prevSeries, ...newSeries]);
      }
      setHasMoreItems(newSeries?.series?.length == 12)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  const handleLoadMore = () => {
    setScroll(true)
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    getAPI()
    getMyAPI()
  }, [refresh, page, tabSwitch])

  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      {
        featuredList?.data?.length > 0 ? <>
          {/* <Featured featuredList={featuredList} getAPI={getAPI} /> */}
          <FeatureSlider featuredList={featuredList} getAPI={getAPI} setIsLoading={setIsLoading} />
          <section className="recoomended-main-cus recomended response-padding mt-5">
            <div className="container">
              <div className="chanel-header">
                <h3 className="fw-600 mb-0">Series</h3>
              </div>
              <div className="stream-btn pt-3">
                <button onClick={() => setTabSwitch(0)} className={`common-btn ${tabSwitch == 0 ? "btn-blue" : "btn-white"}`}>Series</button>
                <button onClick={() => setTabSwitch(1)} className={`common-btn ${tabSwitch == 1 ? "btn-blue" : "btn-white"}`}>My Series</button>
              </div>
              <>
                {
                  tabSwitch == 0 ?
                    <SeriesList featuredList={seriesList} getAPI={getAPI} scroll={scroll} handleLoadMore={handleLoadMore} hasMoreItems={hasMoreItems} setScroll={setScroll} setIsLoading={setIsLoading} /> :
                    <MySeriesList featuredList={mySeriesList} getAPI={getAPI} scroll={scroll} handleLoadMore={handleLoadMore} hasMoreItems={hasMoreItems} setScroll={setScroll} />
                }
              </>
            </div>
          </section>
        </>
          :
          <>
            {(!isLoading && featuredList?.data?.length == 0) && <><div className="chanel-header">
              <h3 className="fw-600 mb-0 mx-5 my-3">Series</h3>
            </div>
              <div style={{ flexDirection: 'column' }} className='no-search-results'>
                <img src={no_plan_placeholder} alt="No Challenges" />
                <button className='common-btn' onClick={() => navigate(ROUTES.CHANNELS + "?cd=network")} >Explore</button>
              </div></>}
          </>
      }
    </div>
  )
}

export default UserSeries;