import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ChannelSearch from '../SearchChannels/Components/ChannelSearch/ChannelSearch';
import SearchSeries from '../SeriesSearch/Components/SearchSeries/SearchSeries';
import './mainSearch.css';
import { filter, noSearchResults, search } from '../../../Constants/images';
import AllChallengesFilter from '../../AllChallenges/Components/AllChallengesFilter/AllChallengesFilter';
import ReactLoader from '../../../ControlComponents/react-loader';
import debounce from 'lodash/debounce';
import BiteSearch from './biteSearch';
import LiveSearch from './liveSearch';
import ShopSeacrh from './shopSearch';
import AllSearch from './Components/allSearch';

function MainSearch() {
  const location = useLocation()
  const searchTag = location?.state?.item
  const [catList, setCatList] = useState([])
  const [shopList, setShopList] = useState([])
  const [lives, setLives] = useState([])
  const [bitesList, setBitesList] = useState([])
  const [tags, setTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tabSwitch, setTabSwitch] = useState(searchTag ? 1 : 0)
  const [tabSwitch1, setTabSwitch1] = useState()
  const [inputValue, setInputValue] = useState("")
  const [channelList, setChannelList] = useState([])
  const [seriesList, setSeriesList] = useState([])
  const [concatList, setConcatList] = useState([])
  const [check, setCheck] = useState(false)
  const [selectedTags, setSelectedTags] = useState(tagsSelected);
  const [filterData, setFilterData] = useState(false)
  const handleSearch = async (searchValue) => {
    const trimmedSearchValue = searchValue ? searchValue?.trim() : inputValue?.trim()
    if (!trimmedSearchValue) {
      setChannelList([]);
      setSeriesList([]);
      const url = `auth/global-search?type=${tabSwitch}&search=`;
      let result = await apiHelper?.getRequest(url);
      if (result.code === DEVELOPMENT_CONFIG?.statusCode) {
        if (tabSwitch == 2) {
          setChannelList([]);
        } else if (tabSwitch == 1) {
          setSeriesList([]);
        }
        else if (tabSwitch == 3) {
          setBitesList([])
        }
        else if (tabSwitch == 4) {
          setLives([])
        }
        else if (tabSwitch == 5) {
          setShopList([])
        }
        else {
          setChannelList([]);
          setSeriesList([]);
          setLives([])
          setShopList([])
          setBitesList([])
        }
      }
    }
    else {
      let url;
      if (tags.length > 0) {
        const tagsSearch = tags.join(',');
        url = `auth/global-search?type=${tabSwitch}&search=${encodeURIComponent(trimmedSearchValue)}&category_id=${encodeURIComponent(tagsSearch)}`;
      } else {
        url = `auth/global-search?search=${encodeURIComponent(trimmedSearchValue)}`;
      }

      if (tabSwitch != 0) {
        url += `&type=${tabSwitch}`;
      }

      let result = await apiHelper?.getRequest(url);

      if (result.code === DEVELOPMENT_CONFIG?.statusCode) {
        if (tabSwitch == 2) {
          setChannelList(result?.data?.channels);
        } else if (tabSwitch == 1) {
          setSeriesList(result?.data?.series);
        }
        else if (tabSwitch == 3) {
          setBitesList(result.data?.reels)
        }
        else if (tabSwitch == 4) {
          setLives(result.data.liveEvents)
        }
        else if (tabSwitch == 5) {
          setShopList(result.data.shopProducts)
        }
        else {
          setChannelList(result?.data?.channels);
          setSeriesList(result?.data?.series);
          setBitesList(result.data?.reels)
          setLives(result.data.liveEvents)
          setShopList(result.data.shopProducts)

        }
        setConcatList([...result?.data?.series, ...result?.data?.channels, ...result?.data?.reels, ...result?.data?.liveEvents, ...result?.data?.shopProducts])
      }
    }


  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), [tags, tabSwitch]);

  const handleChange = (e) => {
    const searchValue = e.target.value;
    setInputValue(searchValue);
    debouncedSearch(searchValue);
  };


  const getFilterApi = async (tagsSearch) => {
    let url = "auth/global-search?type=" + tabSwitch + "&category_id=" + tagsSearch
    let result = await apiHelper.getRequest(url)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      setSeriesList(result?.data?.series)
      setCheck(true)
      setTabSwitch1()
      // setSelectedTags([])
      setFilterData(true)
    }
  }

  const searchTagsApi = async () => {
    setInputValue(searchTag)
    let url = "auth/global-search?type=" + tabSwitch + "&tags=" + searchTag
    let result = await apiHelper?.getRequest(url)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      setSeriesList(result?.data?.series)
    }
  }

  useEffect(() => {
    if (searchTag) {
      searchTagsApi()
    }
  }, [searchTag])

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("auth/category-list")
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setCatList(result?.data)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
  }, [refresh, tags, tabSwitch])

  console.log(concatList, "concatListconcatList")
  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      <div className='container main-search-main'>
        <div className="search-title">
          <h3 className="fw-600 mb-4">Search</h3>
        </div>
        <div className="form-allchannel-search-cus  position-relative ">
          <form>
            <input onChange={handleChange} type="text" name="search" className="form-control" placeholder="Search" value={inputValue} />
            <button type="button" className="icon-search">
              <img src={search} alt="" className="search-img" />
            </button>
            {
              tabSwitch == 1 && <button data-bs-target="#Filter" data-bs-toggle="modal" type="button" className="filter-search">
                <img src={filter} alt="" className="search-img" /></button>
            }
          </form>
        </div>
        <div className="container-fluid mt-4">
          <div className="main-search-btn">
            <div className="stream-btn pt-3">
              <button
                onClick={() => setTabSwitch(tabSwitch === 1 ? 0 : 1)}
                className={tabSwitch === 1 ? "common-btn btn-blue" : "common-btn btn-white"}
              >
                Series
              </button>
              <button
                onClick={() => setTabSwitch(tabSwitch === 2 ? 0 : 2)}
                className={tabSwitch === 2 ? "common-btn btn-blue" : "common-btn btn-white"}
              >
                Network
              </button>
              <button
                onClick={() => setTabSwitch(tabSwitch === 3 ? 0 : 3)}
                className={tabSwitch === 3 ? "common-btn btn-blue" : "common-btn btn-white"}
              >
                Bite
              </button>
              <button
                onClick={() => setTabSwitch(tabSwitch === 4 ? 0 : 4)}
                className={tabSwitch === 4 ? "common-btn btn-blue" : "common-btn btn-white"}
              >
                Live
              </button>
              <button
                onClick={() => setTabSwitch(tabSwitch === 5 ? 0 : 5)}
                className={tabSwitch === 5 ? "common-btn btn-blue" : "common-btn btn-white"}
              >
                Shop
              </button>
            </div>

          </div>
        </div>
        {
          tabSwitch == 2 ?
            <>
              {
                channelList?.length == 0 && inputValue != '' ?
                  <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                    {/* <img src={noSearchResults} /> */}
                    <p className="mb-0 my-5 ">
                      No results found for {inputValue}, try searching again with another keyword
                    </p>
                  </div>
                  : channelList?.length == 0 && check == true ?
                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                      <img src={noSearchResults} />
                      <h6>No channel found</h6>
                    </div> :
                    <ChannelSearch channelList={channelList} />
              }
            </>
            :
            tabSwitch == 1 ?
              <>
                {
                  seriesList?.length == 0 && inputValue != '' ?
                    <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                      <p className="mb-0 my-5">
                        No results found for {inputValue}, try searching again with another keyword
                      </p>
                    </div> : seriesList?.length == 0 && check == true ?
                      <div style={{ flexDirection: 'column' }} className='no-search-results'>
                        <img src={noSearchResults} />
                        <h6>No series found</h6>
                      </div> :
                      <SearchSeries seriesList={seriesList} handleSearch={handleSearch} setIsLoading={setIsLoading}/>
                }
              </> :
              tabSwitch == 3 ?
                <>
                  {
                    bitesList?.length == 0 && inputValue != '' ?
                      <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                        <p className="mb-0 my-5">
                          No results found for {inputValue}, try searching again with another keyword
                        </p>
                      </div> : bitesList?.length == 0 && check == true ?
                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                          <img src={noSearchResults} />
                          <h6>No series found</h6>
                        </div> :
                        <BiteSearch bitesList={bitesList} />
                  }
                </>
                : tabSwitch == 4 ?

                  <>
                    {
                      lives?.length == 0 && inputValue != '' ?
                        <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                          <p className="mb-0 my-5">
                            No results found for {inputValue}, try searching again with another keyword
                          </p>
                        </div> : lives?.length == 0 && check == true ?
                          <div style={{ flexDirection: 'column' }} className='no-search-results'>
                            <img src={noSearchResults} />
                            <h6>No series found</h6>
                          </div> :
                          <LiveSearch lives={lives} setIsLoading={setIsLoading} handleSearch={handleSearch} />
                    }
                  </> :
                  tabSwitch == 5 ?
                    <>
                      {
                        shopList?.length == 0 && inputValue != '' ?
                          <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                            <p className="mb-0 my-5">
                              No results found for {inputValue}, try searching again with another keyword
                            </p>
                          </div> : shopList?.length == 0 && check == true ?
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                              <img src={noSearchResults} />
                              <h6>No series found</h6>
                            </div> :
                            <ShopSeacrh shopList={shopList} setIsLoading={setIsLoading} handleSearch={handleSearch} />
                      }
                    </>
                    :
                    <>
                      {
                        (!tabSwitch) && <>
                          <AllSearch shopList={shopList} channelList={channelList} lives={lives} bitesList={bitesList} seriesList={seriesList} tabSwitch={tabSwitch}  handleSearch={handleSearch} setIsLoading={setIsLoading} />
                        </>
                      }
                      <>
                        {
                          inputValue != '' && concatList?.length == 0 &&
                          <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                            <p className="mb-0">
                              No results found for {inputValue}, try searching again with another keyword
                            </p>
                          </div>
                        }

                      </>
                    </>
        }
      </div>
      <AllChallengesFilter tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} cat={catList} setTags={setTags} tags={tags} getFilterApi={getFilterApi} setTabSwitch={setTabSwitch1} tabSwitch={tabSwitch1} setSelectedTags={setSelectedTags} selectedTags={selectedTags} filterData={filterData} searchTagsApi={searchTagsApi} />

    </div>
  )
}

export default MainSearch;