import React, { useState } from 'react'
import { modalCross } from '../../Constants/images'
import { InputAdornment, TextField } from '@mui/material'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import { NotificationManager } from 'react-notifications'
const TipModal = ({ setTipOpen, userId, getApi, check
}) => {
    const [price, setPrice] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false)
    function changeHandler(e) {
        setErrorMessage("")
        setPrice(e.target.value);
    }

    function submitHandler(e) {
        e.preventDefault();
        if (!price || price.trim() === "") {
            setErrorMessage("Enter price");
        } else {
            postTip()

        }
    }

    async function postTip() {
        setLoading(true);
        let data = {
            "channel_id": userId,
            "amount": parseInt(price?.slice(0, 3))
        }
        try {
            const result = await apiHelper.postRequest("tipsAndGifts/give-tip", data);
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                NotificationManager.success(result.message)
                setLoading(false)
                setTipOpen(false)
                if (check == 'history') {
                    getApi()
                }
            }
            else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            submitHandler(e)
        }
    }

    return (

        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            {
                isLoading && <ReactLoader />
            }
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-5">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Tip</h3>
                        <a onClick={() => {
                            setTipOpen(false)
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                        <form className='price-form-input' onSubmit={submitHandler}>
                            <label className='fw-500 f18-size text-black pb-2'>Tip Amount</label>

                            <TextField
                                className='price-input'
                                type='number'
                                min="1"
                                value={price?.slice(0, 3)}
                                onChange={changeHandler}
                                placeholder='Enter Price'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            {errorMessage && <span className="err_msg">{errorMessage}</span>}

                            <button type='submit' className='price-submit-btn my-5' onKeyDown={handleKeyDown}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TipModal
