import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dotsbg, } from '../../Constants/images';
import "../../../src/CreaterDashboard/Series/allSeries.css"
import ROUTES from '../../Helper/route-helper';
import { Dropdown } from 'react-bootstrap';
import DeleteSeriesModal from './deleteSeriesModal';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from '../../ControlComponents/react-loader';
import { NotificationManager } from 'react-notifications';
function MySeries({ favSeries, getDetailsAPI }) {
   const navigate = useNavigate()
   const [isLoading, setIsLoading] = useState(false)
   const [deleteModal, setDeleteModal] = useState(false)
   const [refresh, setRefresh] = useState(false)
   const [idDelt, setIdDelt] = useState(null)
   const handleOpen = (id) => {
      setDeleteModal(!deleteModal)
      setIdDelt(id)
   }

   const handleView = (id) => {
      localStorage.setItem("series_id", id)
      navigate(ROUTES.CREATER_SERIES_DETAIL)
   }

   const handleEdit = (id) => {
      navigate(ROUTES.CREATER_EDIT_SERIES1, { state: { id } })
   }

   async function getDeleteAPI() {
      setIsLoading(true)
      let result = await apiHelper.getRequest("creator/delete-series?seriesId=" + idDelt)
      if (result.code == DEVELOPMENT_CONFIG.statusCode) {
         setIsLoading(false)
         setDeleteModal(false)
         NotificationManager.success(result.message)
         getDetailsAPI()
      }
      else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
      else {
         NotificationManager.error(result.message)

      }
   }

   return (
      <div>
         {isLoading && <ReactLoader />}
         <section className="mt-4 create-series ">
            <div className="container-fluid">
               <div className="row gy-2">
                  {
                     favSeries.map((item) => (
                        <div className="col-md-6 col-xxl-4  response-col" key={item.id}>
                           <div className="channel-bites-content">
                              <div className='position-relative'>
                                 <img src={item.thumbnail} className="channelbite-series" alt="" />
                                 <div className='dotsmain'>
                                    <Dropdown>
                                       <Dropdown.Toggle id="custom-dropdown-toggle">
                                          <img src={dotsbg} className='img-fluid'></img>
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => handleEdit(item.id)}>Edit</Dropdown.Item>
                                          <Dropdown.Item onClick={() => handleOpen(item.id)}> Delete</Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </div>
                              </div>
                              <div className="series-box bite-wrapper  ">
                                 <div className="series-text d-flex gap-3 align-items-center pb-2">
                                    <div>
                                       <div className='tooltip-container'>
                                          <h6 className='fw-600 mb-0'>{item.title}</h6>
                                          <span className="tooltip-text">{item?.title}</span>
                                       </div>
                                       <p className='my-2'>{item.episodesCount} {item.episodesCount == 1 ? "Episode" : "Episodes"}</p>
                                    </div>
                                 </div>
                                 <button className="common-btn" style={{ cursor: "pointer" }} onClick={() => handleView(item.id)}>View Details</button>
                              </div>
                           </div>
                        </div>
                     ))
                  }

               </div>
            </div>
            {deleteModal && <DeleteSeriesModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />}
         </section>

      </div>
   )
}

export default MySeries;