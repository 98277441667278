import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { amasmall,  df, discovery, sky, testimonialPng, testimonialsvg } from './../../Constants/images';
import './popularTestimonial.css'

function PopularTestimonial() {
  const owlCarousel2 = {
    autoplay: true,
    rewind: true,
    margin: 20,

    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 7000,
    smartSpeed: 800,
    nav: false,
    responsive: {
      0: {
        items: 1
      },

      600: {
        items: 1
      },

      1024: {
        items: 1
      },

      1366: {
        items: 1
      }
    }
  }

  const owlCarousel1 = {
    autoplay: true,
    margin: 0,
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 7000,
    smartSpeed: 700,
    nav: false,
    autoplay:true,
    responsive: {
      0: {
        items: 1
      },

      600: {
        items: 2
      },

      1024: {
        items: 3
      },

      1366: {
        items: 4
      }
    }
  }

  return (
    <>
      <section className="popular-channel text-center py-60">
        <h2 className="mb-5">Popular Channels</h2>

        <div className="owl-slider mt-3" >
          <OwlCarousel id="popular" className="owl-carousel" {...owlCarousel1}>
            <div className="item">
              <div className='popular-chnl-main popular-box popular-div'>
                <img src={amasmall} className='chnl-img-box' />
              </div>
            </div>
            <div className="item">
              <div className="popular-chnl-main popular-box">
                <img src={discovery} className="chnl-img-box"  />
              </div>
            </div>
            <div className="item">
              <div className="popular-chnl-main popular-box">
                <img src={sky} className="chnl-img-box"  />
              </div>
            </div>
            <div className="item">
              <div className="popular-chnl-main popular-box">
                <img src={df} className='chnl-img-box'  />
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
      {/* <!-- testimonial  start--> */}

      <section className="testimonial py-60">
        <div className="container wrapper-border given-padding py-60">
          <div className="owl-slider ">
            <OwlCarousel id="testimonial" className="owl-carousel" {...owlCarousel2}>
              <div className="item">
                <div className="row align-items-center ">
                  <div className="col-lg-4 px-5">
                    <img src={testimonialPng} className="img-fluid px-2 test-client" alt="" />
                  </div>
                  <div className="col-lg-8 px-2 test-svg">
                    <img src={testimonialsvg} alt="" />
                    <h2 className="py-3">"<span className="f-70 pink">M</span>ore Than 600 Transaction Each
                      Day Can We Automate With Abang Digital Banking "</h2>
                    <div className="testimonial-client mt-4">
                      <h3 className="pink fw-600 py-2">Mrs.Riya Parker</h3>
                      <h4 className="fw-500">BUSINESS OWNER</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-center ">
                  <div className="col-lg-4 px-5">
                    <img src={testimonialPng} className="img-fluid px-2 test-client" alt="" />
                  </div>
                  <div className="col-lg-8 px-2 test-svg">
                    <img src={testimonialsvg} alt="" />
                    <h2 className="py-3">"<span className="f-70 pink">M</span>ore Than 600 Transaction Each
                      Day Can We Automate With Abang Digital Banking "</h2>
                    <div className="testimonial-client mt-4">
                      <h3 className="pink fw-600 py-2">Mrs.Riya Parker</h3>
                      <h4 className="fw-500">BUSINESS OWNER</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-center ">
                  <div className="col-lg-4 px-5">
                    <img src={testimonialPng} className="img-fluid px-2 test-client" alt="" />
                  </div>
                  <div className="col-lg-8 px-2 test-svg">
                    <img src={testimonialsvg} alt="" />
                    <h2 className="py-3">"<span className="f-70 pink">M</span>ore Than 600 Transaction Each
                      Day Can We Automate With Abang Digital Banking "</h2>
                    <div className="testimonial-client mt-4">
                      <h3 className="pink fw-600 py-2">Mrs.Riya Parker</h3>
                      <h4 className="fw-500">BUSINESS OWNER</h4>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>

        </div>
      </section>
    </>
  )
}

export default PopularTestimonial;