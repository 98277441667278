import React, { useState } from 'react'
import { buy_btn, DEMO_IMAGE_SEVEN, playBtn, playLightbtn } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailVideoModal from './detailVideoModal'
import BuyModal from './buyModal'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'

function Video({ setTabSwitch, detailList, loading, setLoading, getListAPI, check }) {
    const [deltId, setDeltId] = useState(null)
    const [addDetailVideoModal, setAddDetailVideoModal] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})
    const navigate = useNavigate()
    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    const handleClick = (item) => {
        setDeltId(item.id)
        setAddDetailVideoModal(true)
    }

    return (
        <>
            {loading && <ReactLoader />}
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                {check === "download" ? (
                                    detailList.filter(item => item.is_purchase === 1).length > 0 ?
                                        detailList.filter(item => item.is_purchase == 1)?.reverse().map(item => (
                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                                <div className="latest-video mx-auto">
                                                    <div className='position-relative'>
                                                        <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                            <img
                                                                src={item.shop_media[0]?.media_thumbnail}
                                                                className="img-fluid latest-vid-img new-latest-video"
                                                                alt="thumbnail"
                                                            />
                                                            <img src={playLightbtn} className='play-btn-video'  alt="play button" />

                                                            <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=imgChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                                <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                                <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.name}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 py-3 schedule-grid">
                                                        <div className=''>
                                                            <h4 className="schedule-title">{item.title}</h4>
                                                            <h6 className="images-count">
                                                                {item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}
                                                            </h6>
                                                            <p className="images-description shop-description-detail">{item.description}</p>

                                                            <span style={{ cursor: "pointer" }} onClick={() => handleClick(item)} className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                        (
                                            <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                                <img src={DEMO_IMAGE_SEVEN} alt="No Challenges" />
                                                <h6 className='position-absolute' style={{ bottom: 15 }}>No videos yet</h6>
                                                {/* <img src={no_videos} alt="no videos" />
                                     <h6 className='position-absolute' style={{ bottom: 21 }}>No Videos yet</h6> */}
                                            </div>
                                        )
                                ) : (
                                    detailList?.reverse()?.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <img
                                                            src={item.shop_media[0]?.media_thumbnail}
                                                            className="img-fluid latest-vid-img new-latest-video"
                                                            alt="thumbnail"
                                                        />
                                                        {item.is_purchase === 0 && <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${item.price}`} secondValue={"00"} />}
                                                        <img src={playLightbtn} className='play-btn-video' alt="play button" />
                                                        <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=imgChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                            <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                            <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-3 py-3 schedule-grid">
                                                    <div className=''>
                                                        <div className="tooltip-container">
                                                            <h4 className="schedule-title">{item.title?.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <h6 className="images-count">
                                                            {item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}
                                                        </h6>
                                                        <p className="images-description shop-description-detail">{item.description}</p>
                                                        {item.is_purchase === 0 ? (
                                                            <span style={{ cursor: "pointer" }} onClick={() => handleOpen(item)} className="text-end">
                                                                <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        ) : (


                                                            <span style={{ cursor: "pointer" }} onClick={() => handleClick(item)} className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={DEMO_IMAGE_SEVEN} alt="No Challenges" />
                                        <h6 className='position-absolute' style={{ bottom: 15 }}>No videos yet</h6>
                                        {/* <img src={no_videos} alt="no videos" />
                                        <h6 className='position-absolute' style={{ bottom: 21 }}>No Videos yet</h6> */}
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                </div>

                {
                    addDetailVideoModal && <AddDetailVideoModal setAddDetailVideoModal={setAddDetailVideoModal} setTabSwitch={setTabSwitch} deltId={deltId} />
                }
                {
                    buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} check="videos" getListAPI={getListAPI} />
                }
            </div>
        </>
    )
}

export default Video