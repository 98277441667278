import React, { useEffect, useRef, useState } from 'react'
import { buy_btn, playBtn, profile_image_placeholder } from '../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';
import PriceTag from '../../../CreaterDashboard/component/price-tag';
import BuyPlanSeries from '../../../Components/Buy/BuyPlanSeries';

function SeriesList(props) {
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState({})
    const loadMoreRef = useRef(null);
    const navigate = useNavigate()

    useEffect(() => {
        if (loadMoreRef.current && props.scroll) {
            loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            props.setScroll(false)
        }
    }, [props.featuredList, props.scroll])

    return (
        <div>
            <div className="chanel-wrapper mt-4">
                <div className="row">
                    {
                        props?.featuredList?.data?.map((featured) => {
                            return (
                                <div
                                    // onClick={() => navigate(ROUTES.USER_SERIES_DETAIL, { state: { id: featured?.id } })}
                                    key={featured?.id} style={{ cursor: "pointer" }} className="col-md-6 col-xxl-4 response-col">
                                    <div className="chanel-content dis-recommend-cus">
                                        <div className="bite-relatives position-relative">
                                            <img
                                                src={featured?.thumbnail}
                                                className="bite-img"
                                                alt=""
                                             />
                                            {
                                                (featured.is_purchase == 0 && featured.tier_details == null) &&
                                                <PriceTag classes={"price_tag series-tag"} firstValue={`$${featured?.price}`} secondValue={"00"} />
                                            }
                                        </div>
                                        <div className="bite-wrapper">
                                            <div className='tooltip-container'>
                                                <h4>{featured?.title}</h4>
                                                <span className="tooltip-text">{featured.title}</span>
                                            </div>

                                            <div className="chanel-text d-flex gap-3 align-items-center">
                                                <img src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder} className="series-inner-img" alt="" onClick={() => {
                                                    navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: featured } });
                                                }} />
                                                <div>
                                                    <h6 className='fw-600 mb-0' onClick={() => {
                                                        navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: featured } });
                                                    }}>{featured?.channelDetail?.name ? featured?.channelDetail?.name : profile_image_placeholder}</h6>
                                                    <p className='mt-1' onClick={() => navigate(ROUTES.CATEGORY, { state: { id: featured?.category?.id, catName: featured?.category?.name } })}>{featured?.category?.name}</p>
                                                </div>
                                            </div>
                                            <p>
                                                {featured?.episodes_count} {featured.episodes_count == 1 ? "Episode" : "Epsiodes"}</p>
                                        </div>
                                        {
                                            featured.tier_details == null ?
                                                <>
                                                    {
                                                        featured.is_purchase == 0 ?
                                                            <div className='bites-recoomend-bottom'>
                                                                <img
                                                                    onClick={() => {
                                                                        setOpen(true)
                                                                        setItem(featured)
                                                                    }}
                                                                    src={buy_btn}
                                                                    className="bite-inner-img"
                                                                    alt=""
                                                                />
                                                            </div> :
                                                            <div className='bites-recoomend-bottom'>
                                                                <img
                                                                    onClick={() => {
                                                                        navigate(`/series-list/series-detail/${featured.id}`)

                                                                    }}
                                                                    src={playBtn}
                                                                    className="bite-inner-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div className='bites-recoomend-bottom'>
                                                    <img
                                                        onClick={() => {

                                                            if (featured.tier_details?.is_purchased == 1) {
                                                                navigate(`/series-list/series-detail/${featured.id}`)
                                                            }
                                                            else {
                                                                localStorage.setItem('tierContentId', featured.id + 'series')
                                                                navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: featured.tier_details.tier_id } })
                                                            }
                                                        }}
                                                        src={playBtn}
                                                        className="bite-inner-img"
                                                        alt=""
                                                    />
                                                </div>
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    props.hasMoreItems && <div style={{ cursor: 'pointer' }} onClick={props.handleLoadMore} className="load-text mb-3">
                        Load More...
                    </div>
                }
                <div ref={loadMoreRef}></div>
            </div>
            {
                open &&
                <BuyPlanSeries setOpen={setOpen} item={item} getAPI={props.getAPI} setIsLoading={props.setIsLoading} />
            }
        </div>
    )
}

export default SeriesList;