import React, { useState } from 'react'
import { playLightbtn } from '../../Constants/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import EpisodeSeriesVideoPlayer from '../../CreaterDashboard/component/episodeSeriesPlayer';

function ShopEpisode(props) {
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? m : "00";
    var sDisplay = s > 0 ? s : "00";
    return hDisplay + mDisplay + ":" + sDisplay;
  }

  const [video, setVideo] = useState("")
  const [show, setShow] = useState(false)

  const handleClick = (item) => {
    setVideo(item?.video)
    setShow(!show)
    const trimmedName = item.title?.replace(/\s/g, '');;
    const chatId = `${item?.id}${trimmedName}`;
    localStorage.setItem("episodeChatId", chatId)
  }

  return (
    <div>
      <section className="epsiode">
        <div className="container">
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <h3 className="fw-600">Episodes</h3>
            {/* <button onClick={() => navigate(ROUTES.EPISODE_LIST, { state: { episodes: props?.seriesDetail } })}
              className="see-all fw-600 f18-size">See all <img src={seeAllArrow} className="ms-2" /></button> */}
          </div>
          <div className="row mt-4 pt-3">
            {
              props?.seriesDetail?.episodes?.map((item, index) => {
                return (
                  <div className='col-12 col-sm-6 col-lg-4'>
                    <div key={item?.id} onClick={() => handleClick(item)} className="item mb-5">
                      <div className="epsoide-slider-custom position-relative series-episodes-thumb">
                        <div className='position-relative'>
                          <img
                            src={item?.thumbnail}
                            className="episode-img-thumb"
                            alt=""
                          />
                          <span className='episodes-label episodes-text'>Episode  {item.sequence_no ? item.sequence_no?.toString().padStart(2, '0') : (index + 1).toString().padStart(2, '0')}</span>
                        </div>
                        <div className='epsoide-title d-flex justify-content-between align-items-center gap-3'>
                          <div>
                            <h5 className=" m-0 fw-600">  <h5 className="m-0 fw-600">
                              {item?.title.length > 15 ? item.title.charAt(0).toUpperCase() + item.title.slice(1).substring(0, 25) + "..." : item.title.charAt(0).toUpperCase() + item.title.slice(1)}                              </h5>
                            </h5>
                            <p className="mb-0 fw-500" style={{ color: "#9DA4BF" }}>{item?.duration}</p>
                          </div>
                          <button className="episodes-play-btn">Play</button>
                        </div>
                        {/* <div className="trending-number-epi">
                            <h2 className="white">{index + 1}</h2>
                          </div> */}
                        <div className="epsoide-play">
                          <button className="eps-play esp-playbtn">
                            <img src={playLightbtn} alt="" className='play-epi-slider' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                )
              })
            }
          </div>
        </div>
      </section >

      {
        show && <EpisodeSeriesVideoPlayer chatOpen={props.chatOpen} creatorName={props.creatorName} creatorImg={props.creatorImg} show={show} setShow={setShow} video_url={video} />
      }
    </div >
  )
}

export default ShopEpisode;