import React, { useEffect, useRef, useState } from 'react';
import './dashHomeSlider.css';
import Slider from "react-slick";
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';
import { NextArrow, PrevArrow } from './buttons';
import { mute_trailor, unmute_trailor } from '../../../../Constants/images';
import PriceTag from '../../../../CreaterDashboard/component/price-tag';
import BuyPlanSeries from '../../../../Components/Buy/BuyPlanSeries';

const DashHomeSlider = (props) => {
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState({})
    const [seriesList, setSeriesList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isMuted, setIsMuted] = useState(() => {
        return localStorage.getItem('isMuted') === 'true';
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const videoRefs = useRef([]);
    const sliderRef = useRef(null);

    async function getAPI() {
        props?.setIsLoading(true);
        let result = await apiHelper.getRequest("series/series-list?offset=1");
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
            setSeriesList(result?.data?.data);
            props?.setIsLoading(false);
        } else if (result?.code === 401) {
            let refreshToken = localStorage.getItem("refresh-token");
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            };
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
            if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token);
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                setRefresh(true);
            }
        }
    }

    useEffect(() => {
        getAPI();
    }, [refresh]);

    useEffect(() => {
        if (videoRefs.current[activeSlide]) {
            videoRefs.current[activeSlide].muted = isMuted;
            videoRefs.current[activeSlide].play().catch((error) => {
                console.log("Autoplay failed for the first video. Muting video and retrying play.", error);
                videoRefs.current[activeSlide].muted = true;
                videoRefs.current[activeSlide].play();
            });
        }
    }, [seriesList, isMuted]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: (current) => handleVideoPlayback(current),
    };

    const handleVideoPlayback = (current) => {
        videoRefs.current.forEach((video, index) => {
            if (video) {
                if (index === current) {
                    video.muted = isMuted;
                    video.play().catch((error) => {
                        console.log("Autoplay failed. Muting video and retrying play.", error);
                        video.muted = true;
                        video.play();
                    });
                } else {
                    video.muted = true;
                    video.pause();
                }
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    const userMuted = localStorage.getItem('isMuted') === 'true';
                    setIsMuted(userMuted);
                    localStorage.setItem('focus', 1);
                } else {
                    localStorage.setItem("focus", 0)

                    setIsMuted(true);
                }
            },
            { threshold: 0.1 }
        );

        if (sliderRef.current) {
            observer.observe(sliderRef.current);
        }

        return () => {
            if (sliderRef.current) {
                observer.unobserve(sliderRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setIsMuted(true);
            } else {
                const focus = localStorage.getItem("focus")
                const userMuted = localStorage.getItem('isMuted') === 'true';
                setIsMuted(userMuted);

            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (isLoaded && props.containerRef.current) {
            props.containerRef.current.scrollIntoView({ behavior: 'smooth' });
            
        }
    }, [isLoaded]);

    const handleVideoReady = () => {
        setIsLoaded(true);
    };

    const toggleMute = () => {
        setIsMuted(prevIsMuted => {
            const newMutedState = !prevIsMuted;
            localStorage.setItem('isMuted', newMutedState);
            return newMutedState;
        });
    };

    useEffect(() => {
        return () => {

            localStorage.setItem('isMuted', false);
            localStorage.removeItem('focus');
        };
    }, []);

    const handleSearch = (item) => {
        navigate(ROUTES.GLOBAL_SEARCH, { state: { item } })
    }
   
    return (
        <section ref={sliderRef}>
            <div className='container'>
                <div className="epsiode-slider mt-2 episode-carousel-main">
                    <Slider {...settings}>
                        {seriesList?.slice(0, 10)?.map((item, index) => (
                            <div
                                key={item.id}
                                style={{ cursor: 'pointer' }}
                                className="epsoide-slider-wrapper position-relative"
                            >
                                <div
                                    className='d-flex gap-3 position-absolute align-items-center'
                                    style={{ top: 15, left: 45, cursor: 'pointer' }}
                                    onClick={() => {
                                        navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: item } });
                                    }}
                                >
                                    <img
                                        src={item?.channelDetail?.profile_pic}
                                        style={{ height: "50px", width: "50px", borderRadius: "100%", objectFit: "cover" }}
                                        alt="channel"
                                    />
                                    <h5 className='text-white mb-0'>{item?.channelDetail?.name}</h5>

                                </div>

                                <div style={{ cursor: 'pointer' }}>

                                    <video
                                        className="img-fluid banner-video"
                                        src={item?.series_clips?.find(clip => clip?.type === 1)?.video}
                                        autoPlay
                                        loop
                                        playsInline
                                        muted
                                        ref={(el) => (videoRefs.current[index] = el)}
                                        onCanPlay={handleVideoReady}

                                    />
                                    {
                                        (item.is_purchase == 0 && item.tier_details == null
                                        ) &&
                                        <PriceTag classes={"price_tag series-price-tag home-price-tag "} firstValue={`$${item.price}`} secondValue={"00"} />
                                    }


                                </div>
                                <div style={{ cursor: "pointer" }}>
                                    {
                                        item.tier_details == null ?
                                            <>{
                                                item.is_purchase == 0 ?
                                                    <h5 className="common-btn buy-feature" style={{ zIndex: 10 }}
                                                        onClick={() => {
                                                            setOpen(true)
                                                            setItem(item)
                                                        }
                                                        }>Buy Now</h5> :
                                                    <h5 className="common-btn buy-feature" style={{ zIndex: 10 }}
                                                        onClick={() => {
                                                            navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })
                                                        }} >View</h5>
                                            }</>
                                            :

                                            <h5 className="common-btn buy-feature" style={{ zIndex: 10 }}
                                                onClick={() => {
                                                    if (item.tier_details?.is_purchased == 1) {
                                                        navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })
                                                    }
                                                    else {
                                                        localStorage.setItem('tierContentId', item.id + 'series')
                                                        navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
                                                    }
                                                }} >View</h5>
                                    }

                                    <div onClick={toggleMute} className="mute-toggle-btn">
                                        {isMuted ? <img src={mute_trailor} style={{ width: "30px" }} /> : <img src={unmute_trailor} style={{ width: "30px" }} />}
                                    </div>
                                </div>

                                <div
                                    className='detail-banner-slide'
                                >
                                    <div className="home-bottom-text">
                                        <div className='d-flex gap-2'>
                                            <h5 className="trending-text f16-size">Featured</h5>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-start flex-wrap' onClick={() => {
                                            if (item.tier_details?.is_purchased == 1) {
                                                navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })
                                            }
                                            else {
                                                localStorage.setItem('tierContentId', item.id + 'series')
                                                navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
                                            }
                                        }}
                                            style={{ cursor: "pointer" }}>
                                            <h2 className="white d-inline">{item?.title}</h2>
                                            <span className="f18-size white ms-4">{item?.episodes_count} {item?.episodes_count == 1 ? "Episode" : "Episodes"}</span>
                                        </div>
                                        <div className='gap-2 mt-0 button-home-banner'>
                                            <div className="align-items-center gap-2 mt-3 buttonflex">
                                                {item?.tags?.split(",").map((tag, idx) => (
                                                    <div key={idx} className="common-btn btn-white d-block" style={{ cursor: "pointer" }} onClick={() => handleSearch(tag)}>
                                                        <p className='m-0' >{tag}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    {
                        open &&
                        <BuyPlanSeries setOpen={setOpen} item={item} getAPI={getAPI} setIsLoading={props.setIsLoading} />

                    }
                </div>
            </div>
        </section>
    );

};

export default DashHomeSlider;
