import React, { useState, useEffect } from 'react'
import { bites1, playLight, seeAllArrow } from '../../../../Constants/images';
import ROUTES from '../../../../Helper/route-helper';
import apiHelper from '../../../../Helper/api-helper';
import { useNavigate } from 'react-router-dom';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import './bites.css'
import BiteModal from '../../../../Components/Bites';

function Bites(props) {
   const [bitesList, setBitesList] = useState([])
   const [bitesList2, setBitesList2] = useState([])
   const navigate = useNavigate()
   const [refresh, setRefresh] = useState(false)
   const [showBite, setShowBite] = useState(false)
   const [index, setIndex] = useState(0)
   const [offset, setOffset] = useState(0);
   const [limit, setLimit] = useState(4);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);

   async function getAPI() {
      props?.setIsLoading(true)
      let url
      if (props?.category != 0) {
         url = "bites/bites-list?category_id=" + props.category + "&sort=z-a"
      } else {
         url = "bites/bites-list?sort=z-a"
      }
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setBitesList(result.data?.reels)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   async function getDetailAPI(index) {
      props?.setIsLoading(true)
      let url = "bites/bites-detail?id=" + index
      let url2 = "bites/bites-list?" + "offset=" + offset + "&limit=" + limit
      let result = await apiHelper.getRequest(url)
      let result2;
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         result2 = await apiHelper.getRequest(url2);
         let newArr = [result.data, ...result2.data.data]
         setBitesList2(newArr.filter(
            (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
         ));
         props?.setIsLoading(false)
         setOffset(offset + 4);
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   const handleChange = (index, bite) => {
      if (bite.tier_details.is_purchased == 0) {
         localStorage.setItem('tierContentId', bite.id + "bite")
         navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: bite.tier_details.tier_id } })
      }
      else {
         setShowBite(true)
         setCurrentBiteIndex(index);
      }
   }

   useEffect(() => {
      getAPI()
   }, [props.category, refresh]);

   return (
      <div className='bites-home'>
         {
            bitesList?.length > 0 &&

            <section className="bite response-padding">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600 mb-0 common-btn btn-blue btn-shape f20-size" style={{ fontSize: "20px" }}>Bites</h3>
                     <button onClick={() => navigate(ROUTES.BITES)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} className="ms-2" /></button>
                  </div>
                  <div className="chanel-wrapper mt-4">
                     <div className="row gy-2" style={{ cursor: "pointer" }}>
                        {bitesList?.length > 0 && bitesList?.slice(0, 4)?.map((bite, index) =>
                           <div className="col-md-6 col-xxl-4 response-col" key={bite.id}>
                              <div className="channel-bites-content">
                                 <img src={bite.thumbnail} className="channelbite-img" alt="" onClick={() => handleChange(index, bite)} />
                                 <div className="bite-wrapper">
                                    <div className='tooltip-container'>
                                       <h4 className='mb-0'>{bite.title}</h4>
                                       <span className="tooltip-text">{bite?.title}</span>
                                    </div>
                                    <div className="chanel-text d-flex gap-3 align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}>
                                       <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                                       <div>
                                          <h6 className='fw-600 mb-0'>{bite?.biteChannel?.name}</h6>
                                          <p className='mt-1'>{bite?.category?.name}</p>
                                       </div>
                                    </div>
                                    <button onClick={() => handleChange(index, bite)} className="common-btn"><img src={playLight} alt="" />Play</button>
                                 </div>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               {showBite &&
                  <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={bitesList} showBite={showBite} setShowBite={setShowBite} />
               }
            </section>
         }
      </div>
   )
}

export default Bites;