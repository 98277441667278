import React, { useEffect, useState } from 'react';
import {  buy_btn, live_calender, live_clock, live_icon, playBtn, playGrey, seeAllArrow } from '../../../Constants/images';
import PriceTag from '../../../CreaterDashboard/component/price-tag';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../../Helper/config"
import BuyModalLive from '../../../Components/Lives/buyLive';

function LiveHome(props) {
    const [lives, setLives] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [buyModal, setBuyModal] = useState(false);
    const [item, setItem] = useState(null);
    const navigate = useNavigate()
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    async function getAPI() {
        props?.setIsLoading(true)
        let result = await apiHelper.getRequest("creator/live-creator?home_list=1")
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLives(result.liveUsers)
            props?.setIsLoading(false)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh])

    const handleTabClick = (tabIndex) => {
        // setActiveTab(tabIndex.toString());
        localStorage.setItem("activeTab", tabIndex.toString());
    }

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    };

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        originalDateTime.setHours(originalDateTime.getHours() + 5);
        originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const isCurrentDateTime = (dateTime) => {
        const itemDateTime = new Date(dateTime);
        return itemDateTime <= currentDateTime && itemDateTime >= currentDateTime;
    };
    
    let chatId = "1"
    const handleClick = (item) => {
        const trimmedName = item.schedule.name?.replace(/\s/g, '');
        const concatenatedString = `${item?.schedule.id}${trimmedName}`;
        chatId = concatenatedString
        localStorage.setItem("chatId", chatId)
        localStorage.setItem("liveHome", 0)
        navigate(ROUTES.LIVE_SECTION, { state: { item } })
    }

    return (
        <div>
            <section className="schedule-wrapper">

                <div className="container">
                    {
                        lives?.length > 0 &&
                        <>
                         <div className="chanel-header">
                            <h3 className="fw-600 mb-0 common-btn btn-blue btn-shape f20-size">Lives</h3>
                            <button
                                onClick={() => navigate(ROUTES.LIVES)}
                                className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button>
                        </div>
                        </>
                       
                    }

                    <div className='row mt-4 one-row-scroll'>
                        {
                            lives?.map((item) => (
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4">
                                    <div className="latest-video mx-auto">
                                        <div className='position-relative'>
                                            <div
                                                className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                                                style={{ cursor: "pointer" }}>
                                                <div className="position-relative">
                                                    <img src={item.schedule.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />
                                                    {(item?.schedule.is_purchase == 0 && item.schedule.price != 0) && <PriceTag classes={"price-episodes-label"} firstValue={`$${item.schedule.price}`} secondValue={"00"} />}
                                                </div>

                                                <div className="d-flex justify-content-start align-items-center gap-2 lives-logo " onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=favChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                    <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                    <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.name}</h6>
                                                </div>
                                                {
                                                    item.schedule.status == 1 &&
                                                    <div className='live-img-btn'>
                                                        <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                            <img src={live_icon} className='me-2' />Live</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="latest-video-cus px-3 py-3 schedule-grid">
                                            <div class="d-flex flex-column justify-content-start">
                                                <div className="tooltip-container">
                                                    <h4 className='schedule-title'>
                                                        {item.schedule.name}
                                                    </h4>
                                                    <span className="tooltip-text">{item.schedule.name}</span>
                                                </div>
                                                 {
                                                    item.schedule.status == 1 ? <div className='d-flex align-items-center my-3'>
                                                        <div className='live-img-btn-below '>
                                                            <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                                <img src={live_icon} className='me-2' />Live</p>
                                                        </div>
                                                    </div> :
                                                        <div className="timing-flex">
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_calender} alt="calendar" />
                                                                <span className='ps-1'>{dateFunction(item.schedule.date_time)}</span>
                                                            </div>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_clock} alt="clock" />
                                                                <span className='ps-1'>{addTime(item.schedule.date_time)}</span>
                                                            </div>
                                                        </div>
                                                }

                                            </div>

                                            <div className='dotschedule bg-transparent mb-1 edit-dots'>
                                                <div className='dropdownschedule'>
                                                    {
                                                        (item.schedule.is_purchase == 0 && item.schedule.price != 0) ?

                                                            <span style={{ cursor: "pointer" }} className="text-end">
                                                                <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }}
                                                                    onClick={() => {
                                                                        setBuyModal(true)
                                                                        setItem(item)
                                                                    }}
                                                                />                                                   </span> : <span style={{ cursor: "pointer" }} className="text-end"

                                                                >
                                                                {
                                                                    item.schedule.status == 1 ?
                                                                        <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} onClick={() => handleClick(item)}
                                                                        /> :
                                                                        <>
                                                                            {
                                                                                isCurrentDateTime(item.date_time) && item.schedule.status == 2 ? <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} /> : <img src={playGrey} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                            }
                                                                        </>
                                                                }

                                                            </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {/* {
                        (isLoading == false && lives?.length == 0) && <div style={{ flexDirection: 'column' }} className='no-search-results'>
                            <img src={DEMO_IMAGE_THREE} alt="No Challenges" />
                            <h6 className='position-absolute' style={{ top: 571 }}>No lives yet</h6>                            </div>
                    } */}
                    </div>
                </div>

            </section >
            {
                buyModal && <BuyModalLive setBuyModal={setBuyModal} getApi={getAPI} setIsLoading={props.setIsLoading} item={item} check={item.schedule.status == 1 ? "live" : "upcoming"} />
            }
        </div >
    );
}

export default LiveHome;
