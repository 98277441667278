import React, { useState } from 'react'
import { playLight, image1, videoPlayButton, favouriteLight, playLightBtn, favRed, favGrey } from '../../../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../Helper/route-helper';
import apiHelper from '../../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../../Helper/config';
import '../../../../ChannelDetails/channelDetails.css'
import BuyPlanSeries from '../../../../../Components/Buy/BuyPlanSeries';
import PriceTag from '../../../../../CreaterDashboard/component/price-tag';

function SearchSeries(props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({})
  const [_, forceUpdate] = useState(0)
  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  return (
    <div className="row" style={{ marginTop: 20 }}>
      {
        (props?.seriesList?.length > 0 && props.tabSwitch == 0) && <div className="chanel-header">
          <h3 className="fw-600 my-3">Series</h3>
        </div>
      }
      {
        props?.seriesList?.map((item) => {
          return (
            <div key={item?.id} className="col-12 col-sm-6 col-lg-4 col-xl-4 pb-4">
              <div className="latest-video mx-auto ">
                <div className='position-relative'>
                  <div
                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 "
                    style={{ cursor: "pointer" }} >
                    <img
                    onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })}
                      src={item?.thumbnail}
                      className="img-fluid latest-vid-img img-width"
                    />
                    {
                      (item.tier_details == null && item.is_purchase == 0) &&
                      <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${item.price}`} secondValue={"00"} />
                    }
                    <div className="d-flex justify-content-start align-items-center gap-2 lives-logo"
                       onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=serachSeries", { state: { item: item } })}
                      style={{ cursor: "pointer" }}>
                      <img src={item.creator_profile_pic ? item.creator_profile_pic : profile_image_placeholder} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                      <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.creator_name}</h6>
                    </div>
                  </div>
                  <div onClick={() => addFavourites(item)} className="series-like-btn " style={{ top: 26 }}>
                    {
                      item?.is_fav == 1 ?
                        <img src={favRed} /> :
                        <img src={favGrey} className='like-light' />
                    }
                  </div>
                </div>
                <div className="latest-video-cus px-3 py-3">
                  <h4 className="fw-600">{item?.title}</h4>
                  <div className="latest-series-name series-latest-name">
                    <div className="video-tile">
                      <p className='mb-0 fw-400'>{item?.episodes_count} {item.episodes_count == 1 ? 'Episode' : 'Episodes'}</p>
                      <p className='mb-0 fw-500'>{item?.year}</p>
                    </div>
                    {
                      item.tier_details == null ?
                        <>
                          {
                            item.is_purchase == 0 ?
                              <button onClick={() => {
                                setOpen(true)
                                setItem(item)
                              }
                              } className="common-btn watch-btn series-watch">
                                <span className='me-2'>Buy Now</span>
                              </button> :
                              <button onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })} className="common-btn watch-btn series-watch">
                                <span className='me-2'>Play</span><img
                                  src={playLightBtn}
                                />
                              </button>
                          }
                        </> :
                        <button onClick={() => {
                          if (item.tier_details?.is_purchased == 1) {
                            navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })
                          }
                          else {
                            localStorage.setItem('tierContentId', item.id + 'series')
                            navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item?.tier_details?.tier_id } })
                          }
                        }
                        } className="common-btn watch-btn series-watch">
                          <span className='me-2'>Play</span><img
                            src={playLightBtn}
                          />
                        </button>

                    }

                  </div>
                </div>

              </div>
            </div>

          )
        })
      }
      {
        open &&
        <BuyPlanSeries setOpen={setOpen} item={item} getAPI={props.handleSearch} setIsLoading={props.setIsLoading} />
      }
    </div>
  )
}

export default SearchSeries;