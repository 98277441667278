import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addbucket, pink_dots, pinkRightIcons } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'
import { Dropdown } from 'react-bootstrap';
import AddBucketModal from './addBucketModal';
import DeleteTier from './deleteTier';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
function BucketList({ setIsLoading, tierList, getAPI }) {
    const navigate = useNavigate()
    const [addBucketModal, setAddBucketModal] = useState(false)
    const [editBucketModal, setEditBucketModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [item, setItem] = useState({})

    async function getDeleteAPI() {
        setIsLoading(true)
        let data = {
            "id": item?.id
        }
        setIsLoading(true)
        let result = await apiHelper.postRequest("creator/delete-tier", data)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            getAPI()
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    return (
        <div>
            <section className="favchannel-main-cus">
                <div className="container-fluid">
                    <div className="chanel-wrapper mt-4 tier-wrapper">
                        <div className="row h-100">
                            {
                                tierList?.length > 0 && tierList?.map((item) => (
                                    <div className="col-md-6 col-xl-4 mb-4 h-100  " style={{ cursor: "pointer" }}>
                                        <div className="bg-white " style={{ borderRadius: '30px' }}>
                                            <div className='position-relative'>
                                                <div className='bucket-wrapper'></div>
                                                <div className='img-bucket-inner  d-flex mx-auto justify-content-center align-items-center'>
                                                    <img src={item?.image} className='img-fluid img-top-bucket'></img>
                                                </div>
                                                <div className='dotsmain bg-transparent dottier' >
                                                    <Dropdown align="end">
                                                        <Dropdown.Toggle id="custom-dropdown-toggle">
                                                            <img src={pink_dots} className="img-fluid" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                setItem(item)
                                                                setEditBucketModal(true)
                                                            }
                                                            }>Edit</Dropdown.Item>
                                                            {/* <Dropdown.Item onClick={() => {
                                                                setItem(item)
                                                                setDeleteModal(true)
                                                            }
                                                            } >Delete</Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className=' text-center px-3 pb-3' onClick={() => {
                                                    navigate(ROUTES.CREATER_BUCKET_ACCESS, {
                                                        state: {
                                                            item: item
                                                        }
                                                    })
                                                    localStorage.setItem('tierItem', JSON.stringify(item))

                                                }
                                                } >
                                                {
                                                    item?.plan_type === 'free' ? (
                                                        <h2 className='fw-600 text-pink pt-4'>Free</h2>
                                                    ) : (
                                                        <h2 className='text-pink pt-4'>
                                                            ${parseFloat(item?.price).toFixed(2).split('.')[0]}.
                                                            <span className='fw-600 bucket-price'>{parseFloat(item?.price).toFixed(2).split('.')[1]}</span>
                                                        </h2>
                                                    )
                                                }
                                                <h3 className='fw-600'>
                                                    {item?.plan_name
                                                        ? (item.plan_name[0].toUpperCase() + item.plan_name.slice(1)).length > 20
                                                            ? item.plan_name[0].toUpperCase() + item.plan_name.slice(1, 20) + ".."
                                                            : item.plan_name[0].toUpperCase() + item.plan_name.slice(1)
                                                        : ""}
                                                </h3>

                                                <p className="images-description tier-description  ">{item?.description?.length > 80 ? item?.description?.substring(0, 80) + ".." : item?.description}</p>
                                                <img src={pinkRightIcons} onClick={() => {
                                                    navigate(ROUTES.CREATER_BUCKET_ACCESS, {
                                                        state: {
                                                            item: item
                                                        }
                                                    })
                                                    localStorage.setItem('tierItem', JSON.stringify(item))

                                                }
                                                } style={{ cursor: "pointer" }}></img>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                tierList?.length != 5 &&
                                <div className="col-md-6 col-xl-4 mb-4   " onClick={() => setAddBucketModal(true)} style={{ cursor: "pointer" }}>
                                    <div className='bg-white text-center h-100 p-3' style={{ borderRadius: '30px' }}>

                                        <div className='img-bucket-inner pt-5'>
                                            <img src={addbucket} className='img-fluid'></img>
                                        </div>
                                        <h3 className=' fw-600 text-pink pt-4'>Add Plan </h3>
                                        <h4 className=' fw-600'></h4>
                                        <p className="images-description "></p>
                                        <img src={pinkRightIcons} ></img>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            {addBucketModal && <AddBucketModal setAddBucketModal={setAddBucketModal} getAPI={getAPI} tierList={tierList} />}
            {editBucketModal && <AddBucketModal setAddBucketModal={setEditBucketModal} item={item} tierList={tierList} getAPI={getAPI} />}
            {deleteModal && <DeleteTier setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />}
        </div>
    )
}

export default BucketList;