import React, { useState } from 'react'
import TrailorPlayer from '../component/TrailorPlayer';
import { profile_image_placeholder, shareIcon } from '../../Constants/images';
import ShareModalSeries from '../../Pages/Frame20/shareSeries';


function Video(props) {
  const [shareModal,setShareModal] = useState(false)

  return (
    <div>
      <section className="frame-video-banner frame-video-main">
        <div className="container position-relative">
          <div className="frame-video-img shop-trailor">
              {/* {!videoReady && (
                <img
                  src={props?.seriesDetail?.thumbnail}
                  width="100%"
                  className="video-frame-fluid"
                  alt="Video Thumbnail"
                />
              )} */}
              <TrailorPlayer
                videoUrl={props.seriesDetail?.series_clips?.find(clip => clip.type == 1)?.video}
                setIsLoading={props.setIsLoading}
              />
             
          </div>
          <img src={shareIcon} className=" like-fav-cus"  style={{ position: "absolute", top: 15, right: 35, cursor: 'pointer' }} onClick={()=>{
            setShareModal(true) 
          }}  />
          <div className="movie-wrapper">
            <div className='main' >
              <div className="d-flex flex-wrap justify-content-between gap-3">
                <div className="d-flex flex-wrap gap-3">
                  <div className="bgwhite">
                    <div className="d-flex align-items-center gap-4">
                      <div className="mvie-title">
                        <img src={props?.seriesDetail?.channelDetail?.profile_pic ? props?.seriesDetail?.channelDetail?.profile_pic : profile_image_placeholder} className="py-2 px-2 mv-title" alt="" />
                      </div>
                      <h4 className="m-0">{props?.seriesDetail?.channelDetail?.name}</h4>
                    </div>
                  </div>
                  <div className="bgwhite">
                    <div className="d-flex align-items-center gap-4">
                      <div className="mvie-title">
                        <img
                          src={props?.seriesDetail?.category?.icon}
                          className="py-2 px-2 mv-title"
                          alt=""
                        />
                      </div>
                      <h4 className="m-0">{props?.seriesDetail?.category?.name}</h4>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='bottom-button-wrapper d-flex gap-3'>
                    <button className='common-btn'
                      onClick={() => props.handleEdit()} style={{ cursor: "pointer" }
                      }
                    >Edit Series</button>
                    <button className='whitecreate-btn' style={{ cursor: "pointer" }}
                      onClick={props.handleOpen}
                    >Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="movie-detail py-5">
        <div className="container">
        <h2 className='fw-600'>{props?.seriesDetail?.title.charAt(0).toUpperCase() + props?.seriesDetail?.title.slice(1)}</h2>
        <div className="d-flex gap-5 my-3 flex-wrap">
            <h5 className='video-text mb-0'>{props?.seriesDetail?.year}</h5>
            <h5 className='video-text mb-0'>{props?.seriesDetail?.duration}</h5>
            <h5 className='video-text mb-0'> {props.seriesDetail?.episodesCount} {props.seriesDetail?.episodesCount == 1 ? "Episode" : "Episodes"} </h5>
          </div>
          <div className="stream-btn">
            {
              props?.seriesDetail?.tags?.split(",")?.map((item) => {
                return (
                  <button className=" btn-blue">{item}</button>
                )
              })
            }
          </div>
        </div>
      </section>
      <section className="plot-summary">
        <div className="container">
          <h3 className="fw-600">Plot Summary</h3>
          <p className="mt-2">
            {props?.seriesDetail?.description}
          </p>
        </div>
      </section>
      {
        shareModal && <ShareModalSeries setShareModal={setShareModal} id={props.id}/>
      }
    </div>
  )
}

export default Video;