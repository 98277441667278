import React, { useState } from 'react'
import { buy_btn, playBtn, profile_image_placeholder } from '../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import PriceTag from '../../CreaterDashboard/component/price-tag';
import BuyPlanSeries from '../../Components/Buy/BuyPlanSeries';

function MostPopular(props) {
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState({})
    const navigate = useNavigate()
    return (
        <div>
            <section className="recoomended-main-cus recomended response-padding">
                <div className="container">
                    <div className="chanel-header">
                        <h3 className="fw-600 mb-0">Most Popular</h3>
                    </div>
                    <div className="chanel-wrapper mt-4">
                        <div className="row one-row-scroll">
                            {
                                props?.featuredList?.mostpopular?.map((featured) => {
                                    return (
                                        <div

                                            key={featured?.id} style={{ cursor: "pointer" }} className=" col-12 col-md-6 col-xl-4  col-xxl-3 response-col">
                                            <div className="chanel-content dis-recommend-cus d-block">
                                                <div className="bite-relative position-relative">
                                                    <img
                                                        src={featured?.thumbnail}
                                                        className="bite-img popular-img"
                                                        alt=""
                                                    />
                                                    {
                                                        (featured.is_purchase == 0 && featured.tier_details == null) &&
                                                        <PriceTag classes={"price_tag series-price-tag popular-tag"} firstValue={`$${featured?.price}`} secondValue={"00"} />
                                                    }
                                                    <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=liveChannel", { state: { item: featured } })} style={{ cursor: "pointer", left: 12 }}>
                                                        <img src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392', objectFit: 'cover' }} />
                                                        <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{featured?.channelDetail?.name}</h6>
                                                    </div>
                                                </div>

                                                <div className="bite-wrapper mt-2">
                                                <div className='tooltip-container'>
                                                <h4>{featured?.title}</h4>
                                                <span className="tooltip-text">{featured.title}</span>
                                            </div>
                                                    <div className="chanel-text d-flex gap-3 align-items-center p-height">

                                                        <div>
                                                            <p className=''  onClick={() => navigate(ROUTES.CATEGORY, { state: { id: featured?.category?.id, catName: featured?.category?.name } })}>{featured?.category?.name}</p>
                                                            <p className=''>
                                                                {featured?.episodes_count} {featured.episodes_count == 1 ? "Episode" : "Epsiodes"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    featured.tier_details == null ?
                                                        <>
                                                            {
                                                                featured.is_purchase == 0 ?
                                                                    <div className='bites-recoomend-bottom'
                                                                        onClick={() => {
                                                                            setOpen(true)
                                                                            setItem(featured)
                                                                        }
                                                                        }>
                                                                        <img
                                                                            src={buy_btn}
                                                                            className="bite-inner-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className='bites-recoomend-bottom'
                                                                        onClick={() => {
                                                                            navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: featured?.id } })

                                                                        }
                                                                        }>
                                                                        <img
                                                                            src={playBtn}
                                                                            className="bite-inner-img"
                                                                            alt=""
                                                                        />
                                                                    </div>

                                                            }
                                                        </>
                                                        :
                                                        <div className='bites-recoomend-bottom' onClick={() => {
                                                            if (featured?.tier_details?.is_purchased == 0) {
                                                                localStorage.setItem('tierContentId', featured.id + 'series')
                                                                navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: featured.tier_details.tier_id } })
                                                            }
                                                            else {
                                                                navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: featured?.id } })
                                                            }
                                                        }
                                                        }>
                                                            <img
                                                                src={playBtn}
                                                                className="bite-inner-img"
                                                                alt=""
                                                            />
                                                        </div>

                                                }

                                            </div>
                                        </div>
                                    )

                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                open &&
                <BuyPlanSeries setOpen={setOpen} item={item} getAPI={props.getAPI} setIsLoading={props.setIsLoading} />

            }
        </div>
    )
}

export default MostPopular;