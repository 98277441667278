import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function DatePickerValue(props) {
  const handleChange = (newValue) => {
    props.setErrors("");
    if (!newValue) {
      props?.setFn("");
      return;
    }
    const formattedDate = newValue.format("YYYY-MM-DD");
    if (formattedDate === "Invalid Date") {
      props?.setFn("");
    } else {
      props?.setFn(formattedDate);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      disabled={props.item != undefined && props.check == "past"}
        label={props?.label}
        value={dayjs(props?.value)}
        onChange={(newValue) => handleChange(newValue)}
      />
    </LocalizationProvider>
  );
}
