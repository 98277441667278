import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  dotsbg, noBites, playLight,  profile_image_placeholder } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'
import { Dropdown } from 'react-bootstrap';
import DeleteContent from './deleteContent';
import PlayBiteModal from '../Bites/playBite';
function BucketBites({ setDeltId, getDeleteAPI, dataList, setDeleteModal, deleteModal }) {
  const navigate = useNavigate()
  const [showBite, setShowBite] = useState(false)
  const [videoUrl, setVideoUrl] = useState()
  const [id, setId] = useState(null)
  const [item, setItem] = useState()
  const [currentBiteIndex, setCurrentBiteIndex] = useState();
  const handleDeleteOpen = (id) => {
    setDeleteModal(true)
    setDeltId(id)
  }
  const handleBitePlay = (item) => {
    setVideoUrl(item.video)
    setShowBite(!showBite);
    setId(item.id)
    setItem(item)
  }
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className="fw-600 mb-0" style={{marginLeft:12}}>Bites</h3>
        <div className='col-6 text-end'>
          <button className="common-btn" onClick={() => {
            navigate(ROUTES.CREATER_BUCKET_BITES)
          }}>+Add New</button>
        </div>
      </div>
      <section className="favchannel-main-cus ">
        <div className="container-fluid">
          <div className='row'>
            <div className="chanel-wrapper mt-2">
              <div className="row gy-2">
                {
                  dataList?.length > 0 ?
                    <>
                      {
                        dataList.map((item) => (
                          <div className="col-md-6 col-xxl-4 response-col"  >
                            <div className="channel-bites-content">
                              <div className='position-relative'>
                                <img src={item.reel_details.thumbnail} className="channelbite-img" alt="" />
                                <div className='dotsmain'>
                                  <Dropdown>
                                    <Dropdown.Toggle id="custom-dropdown-toggle">
                                      <img src={dotsbg} className='img-fluid'></img>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                      <Dropdown.Item
                                        onClick={() => handleDeleteOpen(item.id)}
                                      > Remove</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="bite-wrapper">
                              <div className='tooltip-container'>
                                <h4 className='schedule-title mb-0'>{item.reel_details.title?.length > 40 ? item.reel_details.title?.substring(0, 40) + ".." : item.reel_details.title}</h4>
                                <span className="tooltip-text">{item.reel_details?.title}</span>
                                </div>
                                <div className="chanel-text d-flex gap-3 align-items-center">
                                  <img src={item.reel_details.biteChannel.profile_pic} className="bite-inner-img" alt="" />
                                  <div>
                                    <h6 className='fw-600 mb-0'>{item?.reel_details.biteChannel?.name ? item?.reel_details.biteChannel?.name : profile_image_placeholder}</h6>
                                    <p className='mt-1'>{item?.reel_details.category?.name}</p>
                                  </div>
                                </div>
                                <button className="common-btn" onClick={() => handleBitePlay(item.reel_details)} > <img src={playLight} alt=""></img>Play</button>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </>
                    : <div className='no-search-results'>
                      <img src={noBites} alt="No Challenges" />
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </section>
      {deleteModal && <DeleteContent setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check='bites' />}
      {
        showBite &&
        <PlayBiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} showBite={showBite} setShowBite={setShowBite} videoUrl={videoUrl} id={id} item={item} />
      }

    </div>
  )
}

export default BucketBites;