import React, { useState } from 'react'
import { dotsbg, graycalender, graytimer, profile_image_placeholder, seeAllArrow, videoPlayButton } from '../../Constants/images'
import { Dropdown } from 'react-bootstrap'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'
import { NotificationManager } from 'react-notifications'
import MultiVideoPlayer from '../component/multiVideoPlayer'
function ChallengeEpisode({ episodes, responses, getDetailsAPI }) {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [videoId, setVideoId] = useState()
  const [embededVedio, setEmbededVedio] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  localStorage.setItem("responses", JSON.stringify(responses))

  const handleClick = (item, index) => {
    setVideoId(item?.video_id)
    setShow(!show)
    if (item.video_type == 2) {
      setEmbededVedio(true);
    }
    setCurrentVideoIndex(index)
  }

  const handleClick1 = (item, index) => {
    setVideoId(item?.video_id)
    setShow1(!show1)
    if (item.video_type == 2) {
      setEmbededVedio(true)
    }
    setCurrentVideoIndex(index)
  }

  async function getShortList(id, check) {
    if (episodes?.length == 10 && check == "shortlist") {
      NotificationManager.error("Limit exceeded to shortlist the responses")
      return
    }
    setIsLoading(true)
    let result = await apiHelper.getRequest(`creator/challenge-shortlist?responseId=${id}`)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      NotificationManager.success(result.message)
      getDetailsAPI()
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
      }
    }
  }

  const formatDate = (date) => {
    let createdAtDate = new Date(date);
    const formattedDate = `${createdAtDate.getDate().toString().padStart(2, '0')}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getFullYear()}`;
    return formattedDate;
  };

  const formatTime = (date) => {
    let createdAtDate = new Date(date);
    const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedTime
  };

  function modifyDuration(duration) {
    const durationParts = duration?.split(':');
    if (durationParts && durationParts[0] === '00') {
      return durationParts.slice(1)?.join(':');
    }
    return duration;
  }

  return (
    <div>
      {
        isLoading && <ReactLoader />
      }
      <section className="schedule-wrapper">
        <div className="container-fluid">
          {/* episode */}
          {
            episodes?.length > 0 && <div className='row mt-4'>
              <div class="col-12">
                <h3 className='fw-600 mb-0'>Responses</h3>
              </div>
            </div>
          }
          <div className='row mt-4'>
            {
              episodes?.map((item, i) => (
                <div className="col-12 col-md-6 col-lg-12 col-xxl-6 pb-4" key={item.id} >
                  <div className="latest-video mx-auto challenges-detailcreate">
                    <div className='position-relative'>
                      <div
                        className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                        style={{ cursor: "pointer" }} >
                        <img onClick={() => handleClick1(item, i)}
                          src={item.thumbnail}
                          className="img-fluid latest-vid-img"
                        />
                        <div className="play-epi">
                          <img src={videoPlayButton} className="img-fluid" />
                        </div>
                        <div className="dotsmain"> <Dropdown>
                          <Dropdown.Toggle id="custom-dropdown-toggle">
                            <img src={dotsbg} className='img-fluid'></img>
                          </Dropdown.Toggle>
                          <Dropdown.Menu id={i == 1 ? "custom-drop-challenge" : "custom-dropdown-menu"} onClick={() => getShortList(item?.id, "remove")}>
                            <Dropdown.Item >Remove from episodes
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> </div>
                        <div className=''> <span className="videoepisode">
                          {item.video_type == 1 ? "URL Link" : item.video_type == 2 ? "Embeded" : "video"}
                        </span></div>                      </div>
                    </div>
                    <div className="latest-video-cus px-3 py-3 challenege-grid-create position-relative">
                      <div className='profile-challengecreate'>
                        <img src={item.user.profile_pic ? item.user.profile_pic : profile_image_placeholder}></img>                      </div>
                      <div className='text-start my-2 my-sm-0'>
                        <h4 className="schedule-title mb-0 one-line">{item.user.name}</h4>
                        <div className="timing-flex-create mt-2 d-flex gap-2 align-items-center">
                          <div className='mb-0 timing-inner-flex d-flex '>
                            <img src={graycalender} />
                            <span className='ps-1 new-text fw-400 f14-size '>{formatDate(item.created_at)}</span>
                          </div>
                          <div className='mb-0 timing-inner-flex d-flex '>
                            <img src={graytimer} />
                            <span className='ps-1 new-text f11-size fw-500 '>{formatTime(item.created_at)}</span>
                          </div>
                        </div>
                      </div>
                      <div className='dotschedule'>
                        <p className='mb-0 fw-500 f12-size'>{modifyDuration(item.duration)
                        }</p>
                      </div>
                      {
                        show1 == true && <MultiVideoPlayer show={show1}
                          setShow={setShow1}
                          video_url={episodes[currentVideoIndex]?.video_url}
                          embededVedio={embededVedio}
                          setEmbededVedio={setEmbededVedio}
                          currentVideoIndex={currentVideoIndex}
                          setCurrentVideoIndex={setCurrentVideoIndex}
                          videoList={episodes.map(video => video.video_url)}
                          videoId={videoId}

                        />
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          {
            responses?.length > 0 && <div className='row'>
              <div className="chanel-header ">
                <h3 className="fw-600 mb-0">All Responses</h3>
                <div className=' text-end '>
                  <button className="see-all fw-600 f18-size" onClick={() => navigate(ROUTES.CREATOR_ALL_RESPONSES, { state: { episodes } })}>
                    See all
                    <img src={seeAllArrow} alt="" className="ms-2" />
                  </button>
                </div>
              </div>
            </div>
          }

          <div className='row mt-4'>
            {
              responses?.slice(0, 2).map((item, i) => (
                <div className="col-12 col-md-6 col-lg-12 col-xxl-6 pb-4" key={item.id} >
                  <div className="latest-video mx-auto challenges-detailcreate">
                    <div className='position-relative'>
                      <div
                        className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                        style={{ cursor: "pointer" }} >
                        <img onClick={() => handleClick(item, i)}
                          src={item.thumbnail}
                          className="img-fluid latest-vid-img"
                        />
                        <div className="play-epi">
                          <img src={videoPlayButton} className="img-fluid" />
                        </div>
                        <div className="dotsmain"> <Dropdown>
                          <Dropdown.Toggle id="custom-dropdown-toggle">
                            <img src={dotsbg} className='img-fluid'></img>
                          </Dropdown.Toggle>
                          <Dropdown.Menu id={i == 1 ? "custom-drop-challenge" : "custom-dropdown-menu"} onClick={() => getShortList(item?.id, "shortlist")}>
                            <Dropdown.Item >Shortlist as episode
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> </div>
                        <div className=''> <span className="videoepisode">
                          {item.video_type == 1 ? "URL Link" : item.video_type == 2 ? "Embeded" : "video"}
                        </span></div>                      </div>
                    </div>
                    <div className="latest-video-cus px-3 py-3 challenege-grid-create position-relative">
                      <div className='profile-challengecreate'>
                        <img src={item.user.profile_pic ? item.user.profile_pic : profile_image_placeholder}></img>
                      </div>
                      <div className='text-start my-2 my-sm-0'>
                        <h4 className="schedule-title mb-0 one-line">{item.user.name}</h4>
                        <div className="timing-flex-create mt-2 d-flex gap-2 align-items-center">
                          <div className='mb-0 timing-inner-flex d-flex '>
                            <img src={graycalender} />
                            <span className='ps-1 new-text fw-400 f14-size '>{formatDate(item.created_at)}</span>
                          </div>
                          <div className='mb-0 timing-inner-flex d-flex '>
                            <img src={graytimer} />
                            <span className='ps-1 new-text f11-size fw-500 '>{formatTime(item.created_at)}</span>
                          </div>
                        </div>
                      </div>
                      <div className='dotschedule'>
                        <p className='mb-0 fw-500 f12-size'>{modifyDuration(item.duration)
                        }</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          {
            show == true && <MultiVideoPlayer show={show}
              setShow={setShow}
              video_url={responses[currentVideoIndex]?.video_url}
              embededVedio={embededVedio}
              setEmbededVedio={setEmbededVedio}
              currentVideoIndex={currentVideoIndex}
              setCurrentVideoIndex={setCurrentVideoIndex}
              videoList={responses.map(video => video.video_url)}
              videoId={videoId}
            />
          }
        </div>

      </section>

    </div>
  )
}

export default ChallengeEpisode