import React, { useEffect, useState } from 'react';
import { Arrow_channel, buy_btn, DEMO_IMAGE_THREE, live_calender, live_clock, playBtn, playGrey } from '../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import PriceTag from '../../CreaterDashboard/component/price-tag';
import BuyModalLive from './buyLive';
function ScheduleCreator({ getApi, scheduleLives, getScheduleApi, selectedOption, setSelectedOption, setIsLoading, isLoading }) {
    const [buyModal, setBuyModal] = useState(false);
    const [item, setItem] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "0");
    const options = [
        { value: '0', label: 'All' },
        { value: '1', label: 'Free' },
        { value: '2', label: 'Paid' },
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);


    const isCurrentDateTime = (dateTime) => {
        const itemDateTime = new Date(dateTime);
        return itemDateTime <= currentDateTime && itemDateTime >= currentDateTime;
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    useEffect(() => {
        if (activeTab === "0") {
            navigate(ROUTES.LIVES)
        }
        else if (activeTab === "1") {
            getApi()
            navigate(ROUTES.LIVES)
        }
        else {
            getScheduleApi();
        }
    }, [activeTab])

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex.toString());
        localStorage.setItem("activeTab", tabIndex.toString());
    };



    useEffect(() => {
        getScheduleApi()
    }, [])

    const navigate = useNavigate()
    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        originalDateTime.setHours(originalDateTime.getHours() + 5);
        originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    const handleClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item))
        navigate(ROUTES.USER_RECORDED_VIDEO)
    }





    return (
        <div>
            {isLoading && <ReactLoader />}
            <section className="schedule-wrapper">
                <div className="container-fluid">
                    <section className="schedule-wrapper">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className='col-lg-9'>
                                    <div className="stream-btn">
                                        <button onClick={() => handleTabClick(0)} className={`common-btn btn-white ${activeTab === "0" ? "active" : ""}`}>Lives</button>
                                        <button onClick={() => handleTabClick(2)} className={`common-btn btn-blue ${activeTab === "2" ? "active" : ""}`}>Upcoming Lives</button>
                                        <button onClick={() => handleTabClick(1)} className={`common-btn btn-white ${activeTab === "1" ? "active" : ""}`}>Recorded Lives</button>
                                        <button onClick={() => handleTabClick(3)} className={`common-btn btn-white ${activeTab === "3" ? "active" : ""}`}>Purchased Lives</button>
                                    </div>
                                </div>
                                <div className='col-lg-3 ms-auto d-flex justify-content-end  mt-3 mt-lg-0' onClick={toggleDropdown} style={{ cursor: "pointer" }}>
                                    <div className="custom-select">
                                        <div className="selected-option" >
                                            {selectedOption ? selectedOption.label : 'Select an option'}
                                        </div>
                                        {isOpen && (
                                          <div className="options">
                                                {options.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        onClick={() => handleOptionClick(option)}
                                                        className={`option ${selectedOption && selectedOption.value === option.value ? 'active-option' : ''}`}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                         </div>
                                        )}
                                        <div className='arrow-down'>
                                            <img src={Arrow_channel} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='row mt-4'>
                                {
                                    scheduleLives?.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item.id}>
                                            <div className="latest-video mx-auto"
                                            >
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3">
                                                        <div className="position-relative">
                                                            <img src={item.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />
                                                            {(item?.is_purchase == 0 && item.price != 0) && <PriceTag classes={"price-episodes-label"} firstValue={`$${item.price}`} secondValue={"00"} />}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=liveChannel", { state: { item: item } })}>
                                                        <img src={item.channelIcon} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                        <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.channelName}</h6>
                                                    </div>
                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                    <div className='d-flex flex-column'>
                                                        <div className='tooltip-container'>
                                                            <h4 className="schedule-title mb-0">{item.name}</h4>
                                                            <span className="tooltip-text">{item.name}</span>
                                                        </div>
                                                        <div className="timing-flex">
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_calender} alt="calendar" />
                                                                <span className='ps-1'>{dateFunction(item.date_time)}</span>
                                                            </div>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_clock} alt="clock" />
                                                                <span className='ps-1'>{addTime(item.date_time)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <span style={{ cursor: "pointer" }} className="text-end">
                                                        <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span> */}
                                                    <div className='dotschedule bg-transparent'>
                                                        <div className='dropdownschedule'>
                                                            {(item.is_purchase == 0 && item.price != 0) ? (
                                                                <span
                                                                    onClick={() => {
                                                                        setBuyModal(true)
                                                                        setItem(item)
                                                                    }} style={{ cursor: "pointer" }}
                                                                >
                                                                    {/* <span className='me-0 me-md-2'>Buy Now</span> */}
                                                                    <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                </span>
                                                            ) : (
                                                                <span style={{ cursor: "pointer" }}
                                                                    className="text-end">
                                                                    {
                                                                        isCurrentDateTime(item.date_time)  ? <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }}
                                                                            onClick={() => handleClick(item)}
                                                                        /> : <img src={playGrey} alt='buy-btn' style={{ width: '50px', height: '50px' }}
                                                                        //  onClick={()=>hanldeClick(item)} 
                                                                        />
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    (isLoading == false && scheduleLives?.length == 0) && <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={DEMO_IMAGE_THREE} alt="No Challenges" />
                                        <h6 className='position-absolute' style={{ top: 571 }}>No lives yet</h6>                                      </div>
                                }
                            </div>
                        </div>
                    </section >

                    {
                        buyModal && <BuyModalLive setBuyModal={setBuyModal} getApi={getScheduleApi} setIsLoading={setIsLoading} item={item} check="upcoming" />
                    }
                </div>
            </section>

        </div>);

}

export default ScheduleCreator;
