const ERR_MESSAGE = {
  EMAIL_EMPTY: "Enter email",
  EMAIL_INVALID: "Enter valid email address",
  PASSWORD_EMPTY: "Enter password",
  EPISODE_EMPTY: "Add episode",
  INVALID_DATE:"Enter valid date",
  INVALID_PASSWORD: "Password must contain 8 or more characters including 1 uppercase letter, 1 lower case letter, 1 numeric and a special character",
  INVALID_PASSWORD_1: "Password must contain 8 or more characters",
  MOBILE_EMPTY: "Enter mobile number",
  INVALID_MOBILE: "Enter valid mobile number",
  EMPTY_CONFIRMPASSWORD: "Enter confirm password",
  EMPTY_FULLNAME: "Enter full name",
  INVALID_FULLNAME: "Full name should have minimum 3 characters",
  NOTMATCHED: "Password and confirm password do not match",
  TERMS_AND_CONDITIONS: "Accept “Terms & conditions”",
  EMPTY_OTP: "Please enter OTP",
  EMPTY_VIDEO: "Upload video",
  DESCRIPTION_EMPTY:"Enter description",
  INVALID_OTP: "Invalid OTP",
  ENTER_OPTION: "Select atleast one option",
  EMPTY_TIME:"Enter time",
  EMPTY_DATE:"Enter date",
  EMPTY_CATEORY: "Select category",
  EMPTY_CHALLENGE: "Enter challenge name",
  EMPTY_OLD_PASSWORD: "Enter old password",
  EMPTY_NEW_PASSWORD: "Enter new password",
  SERIES_NAME_EMPTY: "Enter series name",
  TAGS_EMPTY: "Enter tag",
  SUMMARY_EMPTY: "Enter summary",
  THUMBNAIL_EMPTY: "Add thumbnail",
  THUMBNAIL_EMPTY1: "Upload thumbnail",
  EMPTY_SERIES: "Select Series",
  TYPE_EMPTY: "Select type",
  CLIP_EMPTY: "Add clip",
  TRAILOR_EMPTY: "Add trailor",
  EPISODES_EMPTY: "Add episode",
  CAST_EMPTY: "Add cast",
  CASTINSERIES_EMPTY: "Enter name in series",
  CASTNAME_EMPTY: "Enter name",
  TITLE_EMPTY: "Enter title",
  EMPTY_PRICE:"Enter price",
  ADD_IMAGE:"Please add image",
  ADD_VIDEO:"Please add video",
  ADD_AUDIO:"Please add audio",
  SUMMARY_DESCRIPTION:"Enter description",
  PRICE_LIMIT: "Price should be greater than 0",
  ADD_DESCRIPTION:"Enter description",
  SELECT_AUDIO_VIDEO:"Select audio or video",
  EMPTY_DURATION:"Select duration",
  EMPTY_TIERNAME:"Enter plan name"
};

export default ERR_MESSAGE;
