import React, { useState } from 'react'
import { playLightbtn, seeAllArrow } from '../../Constants/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ROUTES from '../../Helper/route-helper';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function ShopCast(props) {


  return (
    <div>
      <section className="cast-crew">
        <div className="container mt-5">
          <h3 className="fw-600">Cast & Crew</h3>
          <div className="crew-wrpper-slider text-center gap-4 mt-5 flex-wrap">
            {
              props?.seriesDetail?.series_casts.map((item) => {
                return (
                  <div className="crew-caste">
                    <div className='width-img'>
                      <img src={item?.image} alt="" className='cast-crew-img' />
                    </div>
                    <h5 className="mt-3">{item?.name?.charAt(0).toUpperCase() + item.name.slice(1)}</h5>
                    <p className="movie-tex video-text">{item?.name_in_series.charAt(0).toUpperCase() + item.name_in_series.slice(1)}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>

    </div >
  )
}

export default ShopCast;