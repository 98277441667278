import { loaderIcon } from '../Constants/images';
import './loader.css';
function ReactLoader() {
  return (
    <div className="loader-new">
      <div className="custom-loading-color" style={{ position: "absolute", top: "350px", left: "700px", color: "#5951e5" }}>
        <div className="k-loading-image" >
        <img src={loaderIcon} className='loader-img'  />
        </div>
        <div className="k-loading-color"  >
        </div>
      </div>
    </div>
  )
}
export default ReactLoader;