import React, { useEffect, useState } from 'react'
import "./shop.css"
import SeriesShop from './seriesShop'
import LivesShop from './livesShop'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'
import { NotificationManager } from 'react-notifications'
import UpcomingLivesShop from './upcomingLive'
import Images from './images'
import Audio from './audio'
import Video from './video'
import AddImageModal from './addImageModal'
import AddAudioModal from './addAudioModal'
import AddVideoModal from './addVideoModal'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"

function Shop() {
    const [loading, setIsLoading] = useState(false)
    const [tabSwitch, setTabSwitch] = useState(0)
    const [addImageModal, setAddImageModal] = useState(false)
    const [addAudioModal, setAddAudioModal] = useState(false)
    const [addVideoModal, setAddVideoModal] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [audiosList, setAudiosList] = useState([])
    const [imagesList, setImagesList] = useState([])
    const [videosList, setVideosList] = useState([])
    const navigate = useNavigate()
    let message = localStorage.getItem("message");

    useEffect(() => {
        if (message) {
            if (message.includes("live")) {
                message = message.replace("live", "")?.trim()
                NotificationManager.success(message);
                localStorage.removeItem("message")
                setTabSwitch(1)
            }
            else if (message.includes("series")) {
                message = message.replace("series", "")?.trim()
                NotificationManager.success(message);
                localStorage.removeItem("message")
                setTabSwitch(0)
            }
            else if (message.includes("schedule")) {
                message = message.replace("schedule", "")?.trim()
                NotificationManager.success(message);
                localStorage.removeItem("message")
                setTabSwitch(2)
            }

        }
        return () => {
            message = null
        }
    }, [])

    async function getImagesAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=image")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setImagesList(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getAudiosAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=audio")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setAudiosList(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getVideosAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=video")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setVideosList(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAudiosAPI()
        getImagesAPI()
        getVideosAPI()
    }, [refresh, tabSwitch])

    return (
        <div className="main-inner-content">
            {loading && <ReactLoader />}

            <div className='row align-items-center'>
                <div className='col-sm-5'>
                    <div className="px-2">
                        <h3 className="fw-600">Shop</h3>
                    </div>
                </div>
                <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                    <Dropdown>
                        <Dropdown.Toggle id="custom-dropdown-toggle">
                            <button className="common-btn"
                            >+Add</button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='record-card-dropdown'>
                            <Dropdown.Item onClick={() => navigate(ROUTES.CHOOSE_SERIES_SHOP)}><Link className='text-decoration-none custom-dropdown-anchor' to={ROUTES.CHOOSE_SERIES_SHOP}> +Add Series </Link></Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate(ROUTES.CHOOSE_LIVES_SHOP)} > <Link className='text-decoration-none custom-dropdown-anchor' to={ROUTES.CHOOSE_LIVES_SHOP} >+Add Recorded Live </Link></Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate(ROUTES.CHOOSE_UPCOMING_LIVES)} > <Link className='text-decoration-none custom-dropdown-anchor' to={ROUTES.CHOOSE_UPCOMING_LIVES}>+Add Upcoming Live </Link></Dropdown.Item>
                            <Dropdown.Item onClick={() => setAddImageModal(true)}> <Link className='text-decoration-none custom-dropdown-anchor' >+Add Images </Link></Dropdown.Item>
                            <Dropdown.Item onClick={() => setAddAudioModal(true)}> <Link className='text-decoration-none custom-dropdown-anchor' >+Add Audio </Link></Dropdown.Item>
                            <Dropdown.Item onClick={() => setAddVideoModal(true)}> <Link className='text-decoration-none custom-dropdown-anchor'>+Add Video </Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <section className="streaming py-6 response-padding ">
                {
                    tabSwitch == 0 ?
                        <SeriesShop setTabSwitch={setTabSwitch} /> :
                        tabSwitch == 1 ?
                            <LivesShop setTabSwitch={setTabSwitch} /> :
                            tabSwitch == 2 ?
                                <UpcomingLivesShop setTabSwitch={setTabSwitch} /> :
                                tabSwitch == 3 ?
                                    <Images setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} imagesList={imagesList} getImagesAPI={getImagesAPI} /> :
                                    tabSwitch === 4 ?
                                        <Audio setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} audiosList={audiosList} getAudiosAPI={getAudiosAPI} /> :
                                        <Video setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                            videosList={videosList} getVideosAPI={getVideosAPI} />
                }
                {
                    addImageModal && <AddImageModal setAddImageModal={setAddImageModal} setTabSwitch={setTabSwitch} getImagesAPI={getImagesAPI} />
                }

                {
                    addAudioModal && <AddAudioModal setAddAudioModal={setAddAudioModal} setTabSwitch={setTabSwitch} getAudiosAPI={getAudiosAPI} />
                }

                {
                    addVideoModal && <AddVideoModal setAddVideoModal={setAddVideoModal} setTabSwitch={setTabSwitch} getVideosAPI={getVideosAPI} />
                }
            </section>
        </div>
    )
}

export default Shop