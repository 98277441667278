import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import ROUTES from './Helper/route-helper';
import Layout from './Layout';
import AboutUs from './Pages/AboutUs/AboutUs';
import AllChallenges from './Pages/AllChallenges/AllChallenges';
import ChallengesDetail from './Pages/AllChallenges/Components/ChallengesDetail/ChallengesDetail';
import AllChannels from './Pages/AllChannels/index';
import Bite from './Pages/Bite/components/AllBites';
import Category from './Pages/Category/Components/EducationCategory';
import ChannelDetails from './Pages/ChannelDetails/index';
import ContactUs from './Pages/ContactUs/ContactUs';
import DashboardHome from './Pages/DashboardHome/DashboardHome';
import Discover from './Pages/Discover/Discover';
import EpisodeList from './Pages/EpisodeList';
import Favourites from './Pages/Favourites/Favourites';
import Frame from './Pages/Frame20/Frame';
import Frame2 from './Pages/Frame212/Frame2';
import InteractivePlay from './Pages/Intractives/InteractivePlay';
import Interactives from './Pages/Intractives/Interactives';
import Landing from './Pages/Landing/Landing';
import SignIn from './Pages/Signin/SignIn';
import SignUp from './Pages/Signup/SignUp';
import Talent from './Pages/Talent/Talent';
import Protected from './container/protected';
import SeriesVideoPlayer from './Components/VideoPlayer/seriesVideoPlayer';
import NotFound from './Pages/NotFound';
import MainSearch from './Pages/Search/MainSearch/MainSearch';
import MyChallenges from './Pages/my-challenges';
import MyChallengesDetail from './Pages/my-challenges/challengesDetail';
import Help from './Pages/Help/help';
import Dashboard from './CreaterDashboard/Dashboard/dashboard';
import Lives from './CreaterDashboard/Lives/lives';
import Series from './CreaterDashboard/Series/series';
import CreaterChallenges from './CreaterDashboard/CreaterChallenges/creater-challenges';
import CreaterBites from './CreaterDashboard/Bites/createrBites';
import Bucket from './CreaterDashboard/Bucket/bucket';
import Analyst from './CreaterDashboard/Analyst/analyst';
import TeamMember from './CreaterDashboard/TeamMember/team-member';
import CreaterInteractives from './CreaterDashboard/Interactives/interactives';
import CreateSeries from './CreaterDashboard/Series/createSeries';
import CreaterSubscribers from './CreaterDashboard/Subscribers/createrSubscribers';
import StartLive from './CreaterDashboard/Lives/startLive';
import CreatorMembership from './CreaterDashboard/CreatorMembership/creatorMembership';
import SeriesDetail from './CreaterDashboard/Series/seriesDetail';
import CreatorChallengesDetail from './CreaterDashboard/CreaterChallenges/challenges-detail';
import TrailorClip from './CreaterDashboard/Series/trailorClip';
import BucketDetail from './CreaterDashboard/Bucket/bucketDetail';
import InteractiveDetails from './CreaterDashboard/Interactives/interactiveDetail';
import UserLives from './Components/Lives/userLives';
import LiveSection from './Components/Lives/liveSection';
import ChooseSeries from './CreaterDashboard/Bucket/chooseSeries';
import RecordedVideoPlayer from './CreaterDashboard/component/recordedVideo';
import RecordedLives from './Components/Lives/recordedLives';
import ScheduleCreator from './Components/Lives/scheduleCreator';
import ChallengeCreate from './Pages/AllChallenges/Components/ChallengesDetail/challengeCreate';
import UserSocialFeeds from './Pages/UserSocialFeeds/userSocialFeeds';
import UserShop from './Pages/UserShop/userShop';
import UserShopDetail from './Pages/UserShop/UserShopDetail';
import SocialFeeds from './CreaterDashboard/SocialFeeds/socialFeeds';
import Shop from './CreaterDashboard/Shop/shop';
import ShopSeriesChoose from './CreaterDashboard/Shop/seriesChoose';
import ChooseLivesShop from './CreaterDashboard/Shop/chooseLivesShop';
import ShopSeriesDetail from './Pages/UserShop/shopSeriesDetail';
import MoreEpisodes from './Pages/UserShop/moreEpisodes';
import UserSeries from './Pages/Discover/Components/userSeries';
import WebView from './CreaterDashboard/webView/webView';
import AllResoponses from './CreaterDashboard/CreaterChallenges/allResponses';
import ChooseUpcomingLive from './CreaterDashboard/Shop/chooseUpcomingLive';
import Download from './Pages//Download/download';
import UserRecordedVideoPlayer from './Pages/userRecordedVideo';
import DownloadDetail from './Pages/Download/download-detail';
import ChooseLives from './CreaterDashboard/Bucket/chooseLives';
import ChooseBites from './CreaterDashboard/Bucket/chooseBites';
import ChooseChallenges from './CreaterDashboard/Bucket/chooseChallenges';
import UserBucketList from './Pages/UserBucket/userBucket';
import UserBucketDetail from './Pages/UserBucket/userBucketDetail';
import AllChannelsTiers from './Pages/UserBucket/manageTiers';
import Tip from './CreaterDashboard/Tip/tip';
import UserTip from './Pages/TipUser/tipUser';
window.Buffer = window.Buffer || require("buffer").Buffer

const UserPrivateRoutes = () => {
  return (
    <Routes>
      <Route path={'*'} element={<NotFound />} />
      <Route path={ROUTES.HOME} element={<Protected Component={DashboardHome} />} />
      <Route path={ROUTES.DISCOVER} element={<Protected Component={Discover} />} />
      <Route path={ROUTES.BITES} element={<Protected Component={Bite} />} />
      <Route path={ROUTES.BITE_SHARE} element={<Protected Component={Bite} />} />
      <Route path={ROUTES.CHALLENGES} element={<Protected Component={AllChallenges} />} />
      <Route path={ROUTES.FRAME} element={<Protected Component={Frame} />} />
      <Route path={ROUTES.FAVOURITES} element={<Protected Component={Favourites} />} />
      <Route path={ROUTES.USER_SERIES} element={<Protected Component={UserSeries} />} />
      <Route path={ROUTES.CHANNELS} element={<Protected Component={AllChannels} />} />
      <Route path={ROUTES.CHANNELS_DETAILS} element={<Protected Component={ChannelDetails} />} />
      <Route path={ROUTES.INTERACTIVE_PALY} element={<Protected Component={InteractivePlay} />} />
      <Route path={ROUTES.INTERACTIVES} element={<Protected Component={Interactives} />} />
      <Route path={ROUTES.CHALLENGES_DETAILS} element={<Protected Component={ChallengesDetail} />} />
      <Route path={ROUTES.NEW_TALENT} element={<Protected Component={Talent} />} />
      <Route path={ROUTES.CATEGORY} element={<Protected Component={Category} />} />
      <Route path={ROUTES.EPISODE_LIST} element={<Protected Component={EpisodeList} />} />
      <Route path={ROUTES.MORE_EPISODE} element={<Protected Component={MoreEpisodes} />} />
      <Route path={ROUTES.EPISODE_DETAIL} element={<Protected Component={Frame2} />} />
      <Route path={ROUTES.SERIES_DETAIL} element={<Protected Component={Frame} />} />
      <Route path={ROUTES.USER_SERIES_DETAIL} element={<Protected Component={Frame} />} />
      <Route path={ROUTES.DISCOVER_USER_SERIES_DETAIL} element={<Protected Component={Frame} />} />
      <Route path={ROUTES.VIDEO_PLAYER} element={<SeriesVideoPlayer />} />
      <Route path={ROUTES.LIVE_SECTION} element={<LiveSection />} />
      <Route path={ROUTES.GLOBAL_SEARCH} element={<MainSearch />} />
      <Route path={ROUTES.MY_CHALLENGES} element={<MyChallenges />} />
      <Route path={ROUTES.MY_CHALLENGES_DETAIL} element={<MyChallengesDetail />} />
      <Route path={ROUTES.USER_RECORDED_VIDEO} element={<RecordedVideoPlayer />} />
      <Route path={ROUTES.LIVES} element={<UserLives />} />
      <Route path={ROUTES.RECORDING_LIVES} element={<RecordedLives />} />
      <Route path={ROUTES.SCHEDULE_CREATOR} element={<ScheduleCreator />} />
      <Route path={ROUTES.USER_SOCIAL_FEEDS} element={<UserSocialFeeds />} />
      <Route path={ROUTES.USER_SOCIAL_FEEDS_SHARE} element={<UserSocialFeeds />} />
      <Route path={ROUTES.USER_SHOP} element={<UserShop />} />
      <Route path={ROUTES.USER_CHALLENGE_CREATE} element={<ChallengeCreate />} />
      <Route path={ROUTES.USER_SHOP_DETAIL} element={< UserShopDetail />} />
      <Route path={ROUTES.SHOP_SERIES_DETAIL} element={< ShopSeriesDetail />} />
      <Route path={ROUTES.DOWNLOAD_SERIES_DETAIL} element={< ShopSeriesDetail />} />
      <Route path={ROUTES.DOWNLOAD} element={<Download />} />
      <Route path={ROUTES.DOWNLOAD_DETAIL} element={<DownloadDetail />} />
      <Route path={ROUTES.USER_RECORDED_VIDEO_DOWNLOAD} element={<UserRecordedVideoPlayer />} />
      <Route path={ROUTES.USER_RECORDED_VIDEO_SHOP} element={<UserRecordedVideoPlayer />} />
      <Route path={ROUTES.USER_BUCKET} element={<UserBucketList />} />
      <Route path={ROUTES.MANAGE_TIERS_LIST} element={<UserBucketList />} />
      <Route path={ROUTES.USER_BUCKET_DETAIL} element={<UserBucketDetail />} />
      <Route path={ROUTES.MANAGE_TIERS} element={<AllChannelsTiers />} />
      <Route path={ROUTES.TIPS} element={<UserTip />} />
    </Routes>
  )
}

const CreaterPrivateRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.CREATER_DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTES.CREATER} element={<Dashboard />} />
      <Route path={ROUTES.CREATER_LIVES} element={<Lives />} />
      <Route path={ROUTES.CREATER_SERIES} element={<Series />} />
      <Route path={ROUTES.CREATER_BITES} element={<CreaterBites />} />
      <Route path={ROUTES.CREATER_INTERACTIVES} element={<CreaterInteractives />} />
      <Route path={ROUTES.CREATER_INTERACTIVE_DETAIL} element={< InteractiveDetails />} />
      <Route path={ROUTES.CREATER_SUBSCRIBERS} element={<CreaterSubscribers />} />
      <Route path={ROUTES.CREATER_BUCKETS} element={<Bucket />} />
      <Route path={ROUTES.CREATER_ANALYST} element={<Analyst />} />
      <Route path={ROUTES.CREATER_TEAM_MEMBER} element={<TeamMember />} />
      <Route path={ROUTES.CREATER_CREATER_SERIES} element={<CreateSeries />} />
      <Route path={ROUTES.CREATER_EDIT_SERIES} element={<CreateSeries />} />
      <Route path={ROUTES.CREATER_EDIT_SERIES1} element={<CreateSeries />} />
      <Route path={ROUTES.CREATER_START_LIVE} element={<StartLive />} />
      <Route path={ROUTES.CREATER_MEMBERSHIP} element={<CreatorMembership />} />
      <Route path={ROUTES.CREATOR_ALL_RESPONSES} element={<AllResoponses />} />
      <Route path={ROUTES.CREATER_SERIES_DETAIL} element={<SeriesDetail />} />
      <Route path={ROUTES.CREATER_SHOP_SERIES_DETAIL} element={<SeriesDetail />} />
      <Route path={ROUTES.CREATER_CHALLENGES_DETAIL} element={<CreatorChallengesDetail />} />
      <Route path={ROUTES.CREATER_TRAILOR_CLIP} element={<TrailorClip />} />
      <Route path={ROUTES.CREATER_BUCKET_ACCESS} element={<BucketDetail />} />
      <Route path={ROUTES.CREATER_CHALLENGES} element={< CreaterChallenges />} />
      <Route path={ROUTES.CREATER_CHOOSE_SERIES} element={< ChooseSeries />} />
      <Route path={ROUTES.CREATER_RECORDED_VIDEO} element={< RecordedVideoPlayer />} />
      <Route path={ROUTES.CREATER_SHOP_RECORDED_VIDEO} element={< RecordedVideoPlayer />} />
      <Route path={ROUTES.SOCIAL_FEEDS} element={<SocialFeeds />} />
      <Route path={ROUTES.SHOP} element={<Shop />} />
      <Route path={ROUTES.CHOOSE_SERIES_SHOP} element={<ShopSeriesChoose />} />
      <Route path={ROUTES.CHOOSE_LIVES_SHOP} element={<ChooseLivesShop />} />
      <Route path={ROUTES.CHOOSE_UPCOMING_LIVES} element={<ChooseUpcomingLive />} />
      <Route path={ROUTES.CREATER_BUCKET_SERIES} element={<ChooseSeries />} />
      <Route path={ROUTES.CREATER_BUCKET_LIVES} element={<ChooseLives />} />
      <Route path={ROUTES.CREATER_BUCKET_BITES} element={<ChooseBites />} />
      <Route path={ROUTES.CREATER_BUCKET_CHALLENGES} element={<ChooseChallenges />} />
      <Route path={ROUTES.TIPS_CREATOR} element={<Tip />} />
      <Route path={'*'} element={<NotFound />} />
    </Routes>
  )
}

const PrivateRoutes = () => {
  return (
    <div>
      <Layout>
        {
          localStorage.getItem("role") == 1 && <UserPrivateRoutes />

        }
        {
          localStorage.getItem("role") == 2 && <CreaterPrivateRoutes />
        }
      </Layout>
    </div>
  )
}


function App() {
  let token = localStorage.getItem("token")
  const location = useLocation()
  if (location.pathname == '/') {
    if (token != null) {
      if (localStorage.getItem("role") == 2) {
        return (<Navigate to={ROUTES.CREATER_DASHBOARD} />)
      }
      else {
        return (<Navigate to={ROUTES.HOME} />)
      }
    }
  }



  return (
    <>
      {token != null ?
        <PrivateRoutes /> :
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
          <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
          <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
          <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
          <Route path={ROUTES.HELP} element={<Help />} />
          <Route path={ROUTES.DISCLAIMER} element={<Help />} />
          <Route path={ROUTES.TERM} element={<Help />} />
          {/* <Route path={'*'} element={<NotFound />} /> */}
          <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} state={{ from: location }} />} />
          <Route path={ROUTES.RECORDED_VIDEO} element={<WebView />} />
        </Routes>
      }
    </>
  );
}

export default App;
