import React, { useState } from 'react'
import { modalCross } from '../../../../Constants/images';

function AllChallengesFilter(props) {

  function handleSelection(dataObj) {
    let tagsData = [...props.selectedTags];
    const tagsIndex = tagsData.findIndex(product => product.id === dataObj.id);
    if (tagsIndex != -1) {
      if (tagsIndex > -1) {
        tagsData.splice(tagsIndex, 1);
      }
    } else {
      tagsData.push(dataObj);
    }
    props.setSelectedTags(tagsData);
    props.setTagsSelected(tagsData);
  }

  const handleFilter = () => {
    let array = []
    props.selectedTags.forEach(element => {
      array.push(element.id)
    });
    props.setTags(array)
    props.getFilterApi(array)
  }

  const clearFilter = ()=>{
    props.setSelectedTags([])
    props.searchTagsApi()
  }

  return (
    <div>
      <div className="modal fade" id="Filter" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-radius forgot-body">
            <div className="modal-header forgot-header align-items-center">
              <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Filter</h3>
              <a className="m-0 px-0" data-bs-dismiss="modal" aria-label="Close"><img src={modalCross} alt="" className="img-fluid" style={{cursor:'pointer'}}/></a>
            </div>
            <div className="modal-body forgot-body pt-4 px-2 px-sm-4">
              <div className="filter-category-cus pt-3 pb-4">
                <h4>Category</h4>
              </div>
              <div className="filter-head" style={{cursor:"pointer"}}>
                {
                  props?.cat?.map((dataObj, index) => {
                    return (
                      <div key={dataObj?.id} onClick={() => handleSelection(dataObj)}>
                        <p className={props.selectedTags?.findIndex(
                          item => item.id === dataObj.id,
                        ) != -1 ? "active cus-btn-blue" : "cus-btn-blue"}>{dataObj?.name}</p>
                      </div>
                    )
                  })
                }
              </div>
              <div className="filter-category-cus pt-5 pb-3" style={{ cursor: "pointer" }}>
                <h4>Sort By</h4>
              </div>
              <div className="filter-head"  style={{ cursor: "pointer" }}>
                <div onClick={() => props.setTabSwitch(0)}>
                  <p className={props.tabSwitch == 0 ? "active cus-btn-blue" : "cus-btn-blue"}>Latest</p>
                </div>
                <div onClick={() => props.setTabSwitch(1)} style={{ cursor: "pointer" }}>
                  <p className={props.tabSwitch == 1 ? "active cus-btn-blue" : "cus-btn-blue"}>Popular</p>
                </div>
              </div>
              {
                props?.filterData ?
                <div onClick={clearFilter} className="my-5">
                <a className="common-btn w-100 text-white" data-bs-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }}>Clear Filter</a>
              </div>:
                 <div onClick={handleFilter} className="my-5">
                 <a className="common-btn w-100 text-white" data-bs-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }}>Save</a>
               </div> 
              }
           
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllChallengesFilter;