import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Pages/Bite/bite.css";
import { favGrey, favRed, bites_icon, shareIcon } from "../../Constants/images";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from "../../ControlComponents/react-loader";
import ROUTES from "../../Helper/route-helper";
import { useLocation, useNavigate } from "react-router-dom";
import ShareModalBite from "./shareBite";

const BiteModal = (props) => {
  const [shareModal, setShareModal] = useState(false)
  const navigate = useNavigate()
  const videoRef = useRef(null);
  const [_, forceUpdate] = useState(0);
  const { currentBiteIndex, setCurrentBiteIndex } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const userId = localStorage.getItem("creator_id")
const location = useLocation()
  function handleClose(e) {
    if (e.target === videoRef.current) {
      props?.setShowBite(!props?.showBite);
      if(location.pathname.includes("/bites")){
        navigate("/bites")
      }
    }
  }

  const likeDislikeReelFunction = async (item) => {
    console.log(item, "item")
    if (item?.is_liked === 1) {
      item.is_liked = 0;
      item.like_count -= 1;
    } else {
      item.is_liked = 1;
      item.like_count += 1;
    }
    forceUpdate(Math.random())
    let data = {
      reel_id: item?.id,
      creator_id: parseInt(userId),
    }
    let result = await apiHelper.postRequest("bites/like-dislike-reel", data);
    if (result.code !== DEVELOPMENT_CONFIG.statusCode && result.code === 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        refresh_token: refreshToken,
        scope: "refresh_token",
      };
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
      if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
        likeDislikeReelFunction(item);
      }
    }
  };

  const handlePrevious = () => {
    setCurrentBiteIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      props.handleBitePlay(newIndex);
      return newIndex;
    });
  };


  const handleNext = () => {
    setCurrentBiteIndex((prevIndex) => {
      const newIndex = prevIndex < props.bitesList.length - 1 ? prevIndex + 1 : prevIndex;
      props.handleBitePlay(newIndex);  
      return newIndex;
    });
  };


  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  const description = props.bitesList[currentBiteIndex]?.description || '';
  const isLongDescription = description.length > 100;

  const handleSeeMoreClick = () => {
    setShowFullDescription(true);
  };

  const handleSeeLessClick = () => {
    setShowFullDescription(false);
  };
 
  return (
    <div
      ref={videoRef}
      onClick={handleClose}
      className="modal fade play-video show"
      style={{ display: "block", backdropFilter: "blur(6px)" }}
      id="bites"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-bitesreel-custom justify-content-center">
        <div className="modal-content">
          <div className="videoCard position-relative">
            {isLoading && <ReactLoader />}
            <video
              className="videoCard_player"
              src={props.bitesList[currentBiteIndex]?.video}
              loop
              autoPlay={true}
              onLoadedData={handleVideoLoaded}
              onLoadStart={() => setIsLoading(true)}
            />
            {
              !isLoading &&
              <div className="share-like-main">
                <div
                  onClick={() => likeDislikeReelFunction(props.bitesList[currentBiteIndex])}
                  className="like-video"
                >
                  {props.bitesList[currentBiteIndex]?.is_liked === 1 ? (
                    <img src={favRed} className="img-fluid" />
                  ) : (
                    <img src={favGrey} className="img-fluid" />
                  )}
                  <p className="like-count mb-0 f12-size" style={{ lineHeight: "19px" }}>{props.bitesList[currentBiteIndex]?.like_count}</p>
                </div>
                <div className="share-video mt-4">
                  <img src={shareIcon} className="img-fluid" onClick={() => {
                    setShareModal(true)
                  }} />
                </div>
              </div>
            }
            {!isLoading &&
              <div className="video-carousel">
                <div className="chanel-text d-flex gap-2 align-items-center" style={{ cursor: "pointer" }}>
                  <img src={props.bitesList[currentBiteIndex]?.biteChannel?.profile_pic} className="bite-inner-img video-bite-logo" onClick={() => {
                    if (props.check != "channel") {
                      navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: props.bitesList[currentBiteIndex]?.biteChannel } })
                    }
                  }
                  }
                  />
                  <div>
                    <h5 className="fw-600 text-white mb-1 mt-2" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: props.bitesList[currentBiteIndex] } })}>{props.bitesList[currentBiteIndex]?.biteChannel?.name}</h5>
                    <p className="mb-0"
                      onClick={() => navigate(ROUTES.CATEGORY, { state: { id: props.bitesList[currentBiteIndex]?.category.id, catName: props.bitesList[currentBiteIndex]?.category.name } })}>{props.bitesList[currentBiteIndex]?.category?.name}</p>
                  </div>
                </div>
              </div>
            }
            <div className="bites-description">
              <h6 className="text-white fw-500 f18-size ps-1">{props.bitesList[currentBiteIndex].title}</h6>
              {
                props.bitesList[currentBiteIndex].series_title &&
                <div className="bite-toggle mb-2 f14-size" style={{ cursor: "pointer" }}
                  // onClick={() => navigate(ROUTES.USER_SERIES_DETAIL, { state: { id: props?.bitesList[currentBiteIndex]?.series_id } })}

                  onClick={() => { navigate(`/series-list/series-detail/${props?.bitesList[currentBiteIndex]?.series_id}`) }}
                >
                  <span className="text-white fw-500 f12-size px-2">{props.bitesList[currentBiteIndex].series_title}</span>
                  <img src={bites_icon} className="bites-icon" alt="arrow-icon" />
                </div>
              }
              <p className="text-white fw-500 f12-size ps-1" style={{ lineHeight: "19px" }}>
                {showFullDescription || !isLongDescription
                  ? description
                  : `${description.substring(0, 100)}...`}
                {isLongDescription && !showFullDescription && (
                  <span onClick={handleSeeMoreClick} className=" fw-700 f12-size ps-1" style={{ cursor: "pointer", color: "#07143A" }}> See More</span>
                )}
                {isLongDescription && showFullDescription && (
                  <span onClick={handleSeeLessClick} className="fw-700 f12-size ps-1" style={{ cursor: "pointer", color: "#07143A" }}> See Less</span>
                )}
              </p>
            </div>
          </div>
          <div
            className="carousel-control-prev"
            style={{ display: "block", top: "275px" }}
            onClick={handlePrevious}
          >
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </span>
            <span className="visually-hidden">Previous</span>
          </div>
          <div
            className="carousel-control-next"
            style={{ display: "block", top: "275px" }}
            onClick={handleNext}
          >
            <span className="carousel-control-next-icon" aria-hidden="true">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
            <span className="visually-hidden">Next</span>
          </div>
        </div>
      </div>
      {
        shareModal && <ShareModalBite
          setShareModal={setShareModal} id={currentBiteIndex}
          item={props.bitesList[currentBiteIndex]}
        />
      }
    </div>
  );
};

export default BiteModal;
