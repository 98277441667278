import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from '../../ControlComponents/react-loader'
import { useNavigate } from 'react-router';
import "./userLives"
import ROUTES from '../../Helper/route-helper';
import { NotificationManager } from 'react-notifications';
import UserLivesSection from './userLivesSection';
import ScheduleCreator from './scheduleCreator';
import RecordedLives from './recordedLives';
import PurchaseRecordedLives from './purchaseLives';

const UserLives = () => {
    const navigate = useNavigate()
    const [scheduleLives, setScheduleLives] = useState([])
    const [lives, setLives] = useState([])
    const [recordedLives, setRecordedLives] = useState([])
    const [purchasedLives, setPurchasedLives] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const activeTab = localStorage.getItem("activeTab") || "0"
    const [selectedOption, setSelectedOption] = useState({
        value: '0', label: 'All'
    });

    let message = localStorage.getItem("message")
    let chatId = "1"

    
    useEffect(() => {
        if (message) {
            NotificationManager.success(message);
            localStorage.removeItem("message")
            getApi()
        }
        return () => {
            message = null
        }
    }, [])


    async function getScheduleApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/upcoming-live?is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setScheduleLives(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/live-creator?is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setLives(result?.liveUsers)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getrecordingApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/seriesAndRecordingList?type=2&is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setRecordedLives(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getPurchaseApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`live/purchased-recorded-lives?is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            if (result.message == 'No subscribed plans found') {
                setPurchasedLives([])
            } else {
                setPurchasedLives(result.data)
            }
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getApi()
        getrecordingApi()
        getScheduleApi()
        getPurchaseApi()
    }, [refresh, selectedOption])

    
    const handleClick = (item) => {
        const trimmedName = item.schedule.name?.replace(/\s/g, '');
        const concatenatedString = `${item?.schedule.id}${trimmedName}`;
        chatId = concatenatedString
        localStorage.setItem("chatId", chatId)
        navigate(ROUTES.LIVE_SECTION, { state: { item } })
    }

    useEffect(() => {
        localStorage.setItem("activeTab", 0)
        return (() => {
            localStorage.removeItem("activeTab")
        })
    }, [])

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5'>
                        <div className="px-2">
                            <h3 className="fw-600" >Lives</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <section className="schedule-wrapper mt-4">
                        <div className="container-fluid">
                            {
                                activeTab === "0" ?
                                    <UserLivesSection
                                        lives={lives}
                                        setRefresh={setRefresh}
                                        isLoading={isLoading}
                                        handleClick={handleClick}
                                        getrecordingApi={getrecordingApi}
                                        getApi={getApi}
                                        setIsLoading={setIsLoading}
                                        setSelectedOption={setSelectedOption}
                                        selectedOption={selectedOption}
                                    /> : activeTab === "2" ?
                                        <ScheduleCreator
                                            setRefresh={setRefresh}
                                            isLoading={isLoading}
                                            getApi={getApi}
                                            scheduleLives={scheduleLives}
                                            getScheduleApi={getScheduleApi}
                                            setSelectedOption={setSelectedOption}
                                            selectedOption={selectedOption}
                                            setIsLoading={setIsLoading}
                                        />
                                        : activeTab === "1" ?
                                            <RecordedLives recordedLives={recordedLives} setSelectedOption={setSelectedOption}
                                                selectedOption={selectedOption}
                                                getrecordingApi={getrecordingApi}
                                                setIsLoading={setIsLoading}
                                                isLoading={isLoading}
                                                getPurchaseApi={getPurchaseApi}
                                            /> :
                                            <PurchaseRecordedLives
                                                recordedLives={purchasedLives} setSelectedOption={setSelectedOption}
                                                selectedOption={selectedOption}
                                                getrecordingApi={getrecordingApi}
                                                setIsLoading={setIsLoading}
                                                isLoading={isLoading}

                                            />
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default UserLives;