import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './createrBites.css';
import ReactLoader from '../../ControlComponents/react-loader'
import MyBites from './myBites';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import BiteModalCreator from './createBiteModal';
import { DEMO_IMAGE_ONE } from '../../Constants/images';


const CreaterBites = () => {
    const [favSeries, setFavSeries] = useState([])
    const [bitesList, setBitesList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [biteModal, setBiteModal] = useState(false)
    const [progress, setProgress] = useState();
    const [progressLoading, setProgressLoading] = useState(false)
    const handleOpen = () => {
        setBiteModal(!biteModal)
    }

    async function getSeriesAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/my-series")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setFavSeries(result.data.allSeries)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }
    
    useEffect(() => {
        getSeriesAPI()
    }, [refresh])


    async function getBiteAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/my-bites")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setBitesList(result?.data?.allbite)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getBiteAPI()
    }, [refresh])

   

    return (
        <div>
            {isLoading && <ReactLoader />}
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>
            }
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5'>
                        <div className="">
                            <h3 className="fw-600">Bites</h3>
                        </div>
                    </div>
                    <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                        <button className="common-btn" onClick={handleOpen}>Create Bite</button>
                    </div>
                </div>
                <MyBites bitesList={bitesList} setProgressLoading={setProgressLoading} setProgress={setProgress} setIsLoading={setIsLoading} getBiteAPI={getBiteAPI} favSeries={favSeries} setRefresh={setRefresh} />
                {(bitesList.length == 0 && isLoading == false) && (
                    <div className="no-search-results">
                        <img src={DEMO_IMAGE_ONE} alt="No Challenges" />
                        <h6 className='position-absolute' style={{ top: 475 }}>No bites yet</h6>
                    </div>
                )}

                {biteModal && <BiteModalCreator getBiteAPI={getBiteAPI} setIsLoading={setIsLoading} setProgressLoading={setProgressLoading} setProgress={setProgress} setBiteModal={setBiteModal} favSeries={favSeries} setRefresh={setRefresh} refresh={refresh}/>}
            </div>
        </div>
    )
}

export default CreaterBites;