import React from 'react';
import {  useNavigate } from 'react-router-dom';
import { live_calender, live_clock, no_live } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
function UserBucketLives({ dataList, detail }) {
    const navigate = useNavigate()
    const tierContentId = localStorage.getItem('tierContentId') || 0 + 'live'

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }


    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    const handleClick = (item) => {
        if (detail.is_activated == 1) {
            localStorage.setItem("item", JSON.stringify(item))
            navigate(ROUTES.USER_RECORDED_VIDEO)
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className="fw-600 mb-0 mt-3" style={{marginLeft:12}}>Recorded Lives</h3>
            </div>
            <section className=" schedule-wrapper ">
                <div className="container-fluid">
                    <div className='row mt-4'>
                        {dataList?.length > 0 ? (
                            <>
                                {dataList?.map((item) => {
                                    const isHighlighted = tierContentId.replace(/\D/g, '') === item.recording_details?.id.toString();

                                    return (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" style={{ cursor: detail?.is_activated == 1 ? 'pointer' : 'not-allowed' }} >
                                            <div className={`latest-video mx-auto ${isHighlighted ? 'highlight' : ''}`}>
                                                <div className='position-relative'
                                                    onClick={() => handleClick(item.recording_details)}>
                                                    <div
                                                        className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3">
                                                        <img
                                                            src={item?.recording_details.thumbnail}
                                                            className={`img-fluid latest-vid-img ${detail?.is_activated == 0 && 'cursor'}`}
                                                            alt="thumbnail"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                    <div className=''>
                                                        <h4 className="schedule-title">{item?.recording_details.name}</h4>
                                                        <div className="timing-flex">
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_calender} alt="calendar" />
                                                                <span className='ps-1'>{dateFunction(item.recording_details.start_time)}</span>
                                                            </div>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_clock} alt="clock" />
                                                                <span className='ps-1'>{addTime(item.recording_details.start_time)}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                )}
                            </>
                        ) : <>
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={no_live} alt="No Challenges" />
                            </div>
                        </>
                        }
                    </div>
                </div>
            </section>


        </div>
    )
}

export default UserBucketLives