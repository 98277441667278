import React, { useState } from 'react'
import { biteImg, favRed, favouriteLike, image1, noFavourites, playLight, playLightBtn, profile_image_placeholder, signBg, videoPlayButton } from '../../Constants/images';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import apiHelper from '../../Helper/api-helper';
import PriceTag from '../../CreaterDashboard/component/price-tag';
import BuyPlanSeries from '../../Components/Buy/BuyPlanSeries';

function AllFavSeries({ setTabSwitch, favSeries, getDetailsAPI, setIsLoading }) {
  const navigate = useNavigate()
  const [_, forceUpdate] = useState(0)
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({})
  async function addFavourites(item) {
    setIsLoading(true)
    let data = {
      "series_id": item?.series_id,
      "type": 2
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result == undefined) {
      setIsLoading(false)
      getDetailsAPI()
    }
  }

  return (
    <div> <section className="channel-detail favchannel-main-cus">
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3 pb-4">
            <h3 className="latest-vid-head fw-600">Favourites</h3>
            <div className="streaming py-6 response-padding">
              <div className="row">
                <div className="stream-btn">
                  <button onClick={() => setTabSwitch(0)} className="common-btn btn-blue">Series</button>
                  <button onClick={() => setTabSwitch(1)} className="common-btn btn-white">Network</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {
            favSeries?.map((series) => {
              return (
                <div key={series?.series.id} className="col-12 col-sm-6 col-lg-4 col-xl-4 pb-4">
                  <div className="latest-video mx-auto">
                    <div className='position-relative'>
                      <div
                        className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"

                      >
                        <Link to="" className="play-vid-btn" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.series.id } })}
                        ><img
                            src={videoPlayButton}
                            className="img-fluid"
                          /></Link>

                        <img
                          style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.series.id } })}
                          src={series?.series?.thumbnail}
                          className="img-fluid latest-vid-img"
                        />
                        {
                          (series.series.tier_details == null && series.series.is_purchase == 0) &&
                          <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${series.series.price}`} secondValue={"00"} />
                        }
                        <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=favrouiteChannel", { state: { item: series.series } })} style={{ cursor: "pointer" }}>
                          <img src={series.series.ChannelImage ? series.series.ChannelImage : profile_image_placeholder} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                          <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{series.series.ChannelName}</h6>
                        </div>
                      </div>
                      <div className=" like-fav-cus" onClick={() => addFavourites(series)} style={{ top: 26 }}>
                        <img src={favRed} alt="" className='fav-like-series' />
                      </div>
                    </div>
                    <div className="latest-video-cus px-3 py-3">
                      <h4 className="fw-600">{series?.series?.title}</h4>
                      <div className="latest-series-name">
                        <div className="video-tile">
                          <p className='mb-0 fw-400'>{series?.series?.episodesCount} {series.series.episodesCount == 1 ? "Episode" : "Episodes"}</p>
                          <p className='mb-0 fw-500'>{series?.series?.year}</p>
                        </div>
                        {
                          series.series.tier_details == null ?
                            <>{
                              series?.series?.is_purchase == 0 ?
                                <button onClick={() => {
                                  setOpen(true)
                                  setItem(series.series)
                                }
                                } className="common-btn watch-btn series-watch">
                                  <span className='me-2'>Buy Now</span>
                                </button> :
                                <button onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.series.id } })} className="common-btn watch-btn series-watch">
                                  <span className='me-2'>Play</span><img
                                    src={playLightBtn}
                                  />
                                </button>
                            }</>
                            :
                            <button onClick={() => {
                              if (series.series.tier_details.is_purchased == 1) {
                                navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.series.id } })
                              }
                              else {
                                localStorage.setItem('tierContentId', series.series.id + 'series')
                                navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: series.series?.tier_details?.tier_id } })
                              }
                            }
                            } className="common-btn watch-btn series-watch">
                              <span className='me-2'>Play</span><img
                                src={playLightBtn}
                              />
                            </button>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
      {
        open &&
        <BuyPlanSeries setOpen={setOpen} item={item} getAPI={getDetailsAPI} setIsLoading={setIsLoading} />
      }
    </section>
    </div>
  )
}

export default AllFavSeries;