import React, { useEffect, useRef } from 'react'
import { profile_image_placeholder, no_plan_placeholder, playBtn } from '../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';
import PriceTag from '../../../CreaterDashboard/component/price-tag';

function MySeriesList(props) {
    const loadMoreRef = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        if (loadMoreRef.current && props.scroll) {
            loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            props.setScroll(false)
        }
    }, [props.featuredList, props.scroll]);

    return (
        <div>
            <div className="chanel-wrapper mt-4">
                <div className="row">
                    {
                        props?.featuredList?.series?.length > 0 ? <>{
                            props?.featuredList?.series?.map((featured) => {
                                return (
                                    <div
                                        // onClick={() => navigate(ROUTES.USER_SERIES_DETAIL, { state: { id: featured?.id } })}
                                        key={featured?.id} style={{ cursor: "pointer" }} className="col-md-6 col-xxl-4  response-col">
                                        <div className="chanel-content dis-recommend-cus">
                                            <div className="bite-relatives position-relative">
                                                <img
                                                    src={featured?.thumbnail}
                                                    className="bite-img"
                                                    alt=""
                                                />
                                                <PriceTag classes={"price_tag shop-tag"} firstValue={`${featured?.tier_details == null ? 'Shop' : 'Plan'}`} secondValue={"00"} />
                                            </div>
                                            <div className="bite-wrapper">
                                                <div className='tooltip-container'>
                                                    <h4>{featured?.title}</h4>
                                                    <span className="tooltip-text">{featured.title}</span>
                                                </div>
                                                <div className="chanel-text d-flex gap-3 align-items-center" >
                                                    <img src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder} className="series-inner-img" alt="" onClick={() => {
                                                        navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: featured } });
                                                    }} />
                                                    <div>
                                                        <h6 className='fw-600 mb-0' onClick={() => {
                                                            navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: featured } });
                                                        }}>{featured?.channelDetail?.name ? featured?.channelDetail?.name : profile_image_placeholder}</h6>
                                                        <p className='mt-1' onClick={() => navigate(ROUTES.CATEGORY, { state: { id: featured?.category?.id, catName: featured?.category?.name } })}>{featured?.category?.name}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    {featured?.episodes_count} {featured.episodes_count == 1 ? "Episode" : "Epsiodes"}</div>
                                            </div>
                                            <div className='bites-recoomend-bottom'>
                                                <img
                                                    onClick={() => {
                                                        navigate(`/series-list/series-detail/${featured.id}`)
                                                    }}
                                                    src={playBtn}
                                                    className="bite-inner-img"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }</> : <>
                            {<>
                                <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                    <img src={no_plan_placeholder} alt="No Challenges" />
                                    <button className='common-btn' onClick={() => navigate(ROUTES.CHANNELS + "?cd=network")} >Explore</button>
                                </div></>}
                        </>
                    }

                </div>
                {
                    props.hasMoreItems && <div style={{ cursor: 'pointer' }} onClick={props.handleLoadMore} className="load-text mb-3">
                        Load More...
                    </div>
                }
                <div ref={loadMoreRef} ></div>
            </div>

        </div>
    )
}

export default MySeriesList;