import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './bucket.css';
import ReactLoader from '../../ControlComponents/react-loader'
import BucketSeries from './bucketSeries';
import Subscribers from './subscribers';
import BucketLives from './bucketLives';
import BucketBites from './bucketBites';
import BucketChallenges from './bucketChallenges';
import AddBucketModal from './addBucketModal';
import { NotificationManager } from 'react-notifications';


const BucketDetail = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [bucketModal, setBucketModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const item = JSON.parse(localStorage.getItem('tierItem'))
    const [detail, setDetail] = useState({})
    const [deltId, setDeltId] = useState(null)
    const [dataList, setDataList] = useState([])
    const [liveList, setLiveList] = useState([])
    const [biteList, setBiteList] = useState([])
    const [challengeList, setChallengeList] = useState([])
    const [subscriberList, setSubscriberList] = useState([])
    const handleOpen = () => {
        setBucketModal(!bucketModal)
    }

    let message = localStorage.getItem("message")

    useEffect(() => {
        if (message) {
            if (message.includes("series")) {
                message = message.replace("series", "")?.trim()
                NotificationManager.success('Series added successfully');
                localStorage.removeItem("message")
                setTabSwitch(0)
            }
            else if (message.includes("live")) {
                message = message.replace("live", "")?.trim()
                NotificationManager.success('Recorded live added successfully');
                localStorage.removeItem("message")
                setTabSwitch(1)
            }

            else if (message.includes("bite")) {
                message = message.replace("bite", "")?.trim()
                NotificationManager.success('Bite added successfully');
                localStorage.removeItem("message")
                setTabSwitch(2)
            }
            else if (message.includes("challenge")) {
                message = message.replace("challenge", "")?.trim()
                NotificationManager.success('Challenge added successfully');
                localStorage.removeItem("message")
                setTabSwitch(3)
            }
        }
        return () => {
            message = null
        }
    }, [])

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/tiers-details?id=${item?.id}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setDetail(result?.data)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getSubscriberAPI() {
        setIsLoading(true)
          let  url = `creator/tier-subscribers-list?tier_id=${item.id}`
        
        let result = await apiHelper.getRequest(url)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setSubscriberList(result?.data)
        }
        else if (result?.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }
    
    async function handleChange(value) {
        let  url = `creator/tier-subscribers-list?tier_id=${item.id}&search=${value}`
        let result = await apiHelper.getRequest(url)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setSubscriberList(result?.data)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getContentAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/get-tier-content?tier_id=${item?.id}&item_type=${tabSwitch == 0 ? '1' : tabSwitch == 1 ? '2' : tabSwitch == 2 ? '3' : '4'}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            if (tabSwitch == 0) {
                setDataList(result?.data)
            }
            else if (tabSwitch == 1) {
                setLiveList(result?.data)
            }
            else if (tabSwitch == 2) {
                setBiteList(result.data)
            }
            else if (tabSwitch == 3) {
                setChallengeList(result?.data)
            }
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getDeleteAPI(check) {
        setIsLoading(true)
        let data = {
            "id": deltId
        }
        setIsLoading(true)
        let result = await apiHelper.postRequest("creator/delete-tier-content", data)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            getContentAPI()
            setDeleteModal(false)
            let message;
            if (check == 'series') {
                message = 'Series deleted successfully'
            }
            else if (check == 'bites') {
                message = 'Bite deleted successfully'
            }
            else if (check == 'lives') {
                message = 'Recorded live deleted successfully'
            }
            else {
                message = 'Challenge deleted successfully'
            }
            NotificationManager.success(message)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
        getContentAPI()
        getSubscriberAPI()
    }, [tabSwitch, refresh])

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className="bucket-inner p-3">
                    <div className='row align-items-center'>
                        <div className='col-sm-5 ps-4'>
                            <div className="">
                                <h3 className="fw-600">Plan Detail</h3>
                            </div>
                        </div>
                        <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                            <button className="common-btn" onClick={handleOpen}> Edit</button>
                        </div>
                    </div>
                    <div className='row align-items-center mt-3'>
                        <div className='col-sm-5 ps-4'>
                            <div className="getaccess_text">
                                <h2 className="fw-600 channel-text">{detail.plan_name}</h2>
                            </div>
                        </div>
                        {
                            detail.plan_type == 'free' ?
                                <div className="col-sm-7 ms-auto text-end d-flex justify-content-end">
                                    <h3 className="text-pink pt-3 number-count">
                                        Free
                                    </h3>
                                </div> :
                                <div className="col-sm-7 ms-auto text-end d-flex justify-content-end">
                                    <h3 className="text-pink pt-3 number-count">
                                        ${Math.floor(detail.price)}.
                                        <span className="fw-600 bucket-price">{(detail.price % 1).toFixed(2).split('.')[1]}</span>
                                    </h3>
                                </div>
                        }

                        <p className="tier-description">{detail.description}</p>
                    </div>
                    <section className="streaming py-6 response-padding ">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="stream-btn pt-3">
                                    {
                                        detail.plan_type == 'free' ?
                                            <> <button onClick={() => setTabSwitch(0)} className={`common-btn ${tabSwitch == 0 ? "btn-blue" : "btn-white"}`}>Series</button>
                                                <button onClick={() => setTabSwitch(1)} className={`common-btn ${tabSwitch == 1 ? "btn-blue" : "btn-white"}`}>Recorded Lives</button>
                                                <button onClick={() => setTabSwitch(2)} className={`common-btn ${tabSwitch == 2 ? "btn-blue" : "btn-white"}`}>Bites</button>
                                                <button onClick={() => setTabSwitch(3)} className={`common-btn ${tabSwitch == 3 ? "btn-blue" : "btn-white"}`}>Challenges</button>

                                            </> :
                                            <> <button onClick={() => setTabSwitch(0)} className={`common-btn ${tabSwitch == 0 ? "btn-blue" : "btn-white"}`}>Series</button>
                                                <button onClick={() => setTabSwitch(1)} className={`common-btn ${tabSwitch == 1 ? "btn-blue" : "btn-white"}`}>Recorded Lives</button>

                                                <button onClick={() => setTabSwitch(4)} className={`common-btn ${tabSwitch == 4 ? "btn-blue" : "btn-white"}`}>Subscribers</button></>
                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                    {tabSwitch == 0 ?
                        <>
                            <BucketSeries setTabSwitch={setTabSwitch} setDeltId={setDeltId} getDeleteAPI={getDeleteAPI} dataList={dataList}
                                setDeleteModal={setDeleteModal} deleteModal={deleteModal} />
                        </> :
                        tabSwitch == 1 ?
                            <BucketLives setTabSwitch={setTabSwitch} setDeltId={setDeltId} getDeleteAPI={getDeleteAPI} dataList={liveList} setDeleteModal={setDeleteModal} deleteModal={deleteModal} />
                            :
                            tabSwitch == 2 ?
                                <BucketBites setTabSwitch={setTabSwitch} setDeltId={setDeltId} getDeleteAPI={getDeleteAPI} dataList={biteList} setDeleteModal={setDeleteModal} deleteModal={deleteModal} />
                                :
                                tabSwitch == 3 ?
                                    <BucketChallenges setTabSwitch={setTabSwitch} setDeltId={setDeltId} getDeleteAPI={getDeleteAPI} dataList={challengeList} setDeleteModal={setDeleteModal} deleteModal={deleteModal} />
                                    :
                                    <>
                                        <Subscribers setTabSwitch={setTabSwitch} subscriberList={subscriberList} handleChange={handleChange}/>
                                    </>
                    }
                    {bucketModal && <AddBucketModal setAddBucketModal={setBucketModal} item={item}
                        getAPI={getDetailsAPI} />}
                </div>
            </div>
        </div>
    )
}

export default BucketDetail;