import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { arrow_large, check_blue, no_plans, } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import ActivateTier from './activateTier';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader';
import { NotificationManager } from 'react-notifications';

function UserBucketList() {
    const navigate = useNavigate()
    const [activateTier, setActivateTier] = useState(false)
    const [tierlist, setTierList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const location = useLocation()
    const id = location?.state?.id
    const [isLoading, setIsLoading] = useState(false)
    const [item, setItem] = useState('')
    const tierStatus = localStorage.getItem("purchase")
    async function getApi() {
        setIsLoading(true)
        let url;
        if (tierStatus == 1) {
            url = `tier/activated-tiers-list?channel_id=${id}`
        }
        else {
            url = `tier/tiers-list?channel_id=${id}`
        }
        let result = await apiHelper.getRequest(url)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setTierList(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getApi()
    }, [refresh])

    const getBuyApi = async () => {
        let data;
        data = {
            "tier_id": item.id,
            "channel_id": id,
        }
        if (item.plan_type != 'free') {
            data.transaction_id = "358oih90876eyrc"
        }
        setIsLoading(true)
        let result = await apiHelper.postService('tier/activate-tier', data)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setActivateTier(false)
            getApi()
            NotificationManager.success(result.message)
        }
        else {
            setIsLoading(false)
            NotificationManager.error(result.message)
        }
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5 ps-4'>
                        <div className="">
                            <h3 className="fw-600 mb-0">Plans</h3>
                        </div>
                    </div>
                    <section className="favchannel-main-cus">
                        <div className="container-fluid">
                            <div className='row'>
                                <div className="chanel-wrapper mt-4 tier-wrapper">
                                    <div className="row h-100">

                                        {
                                            tierlist.length > 0 ? <> {
                                                tierlist?.map((item) => {
                                                    const isWithinGracePeriod = () => {
                                                        const today = new Date();
                                                        const expiryDate = new Date(item.expiry_date);
                                                        const differenceInDays = Math.ceil((today - expiryDate) / (1000 * 60 * 60 * 24));
                                                        return differenceInDays >= 0 && differenceInDays <= 5;
                                                    };
                                                    return (
                                                        <div className="col-md-6 col-xxl-4 mb-4" style={{ cursor: "pointer" }} key={item.id}>
                                                            <div className="bg-white" style={{ borderRadius: '30px' }}>
                                                                <div className="position-relative">
                                                                    <div className={`bucket-wrapper ${(tierStatus == 0 ? item.is_activated == 1 && !isWithinGracePeriod() : !isWithinGracePeriod()) && "bucket-wrapper-grey"}`}></div>
                                                                    <div className="img-bucket-inner d-flex mx-auto justify-content-center align-items-center">
                                                                        <img src={item.image} className="img-fluid img-top-bucket" alt={item.plan_name} />
                                                                    </div>
                                                                </div>
                                                                <div className="text-center px-3 pb-3">
                                                                    {
                                                                        item.plan_type === 'free' ? (
                                                                            <h2 className="fw-600 text-pink pt-4">Free</h2>
                                                                        ) : (
                                                                            <h2 className="text-pink pt-4">
                                                                                ${parseFloat(item?.price).toFixed(2).split('.')[0]}.
                                                                                <span className="fw-600 bucket-price">{parseFloat(item?.price).toFixed(2).split('.')[1]}</span>
                                                                            </h2>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.is_activated === 0 ? (
                                                                            <>
                                                                                {
                                                                                    item.plan_type === 'free' ? (
                                                                                        <div className="d-flex justify-content-center my-3">
                                                                                            <div className="tier-month">
                                                                                                <p className="channel-para mb-0 text-pink">Unlimited</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="d-flex justify-content-center my-3">
                                                                                            <div className="tier-month">
                                                                                                <p className="channel-para mb-0 text-pink">{item.duration} {item.duration == 1 ? 'month':'months' }</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    item.plan_type === 'free' ? (
                                                                                        <div className="d-flex justify-content-center my-3">
                                                                                            <div className="tier-month">
                                                                                                <p className="channel-para mb-0 text-pink">Unlimited</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="d-flex justify-content-center gap-2 gap-sm-3 my-3 tier-month-flex">
                                                                                            <div className="tier-month">
                                                                                                <p className="channel-para mb-0 text-pink">{item.duration} Months</p>
                                                                                            </div>
                                                                                            <div className="tier-date">
                                                                                                {
                                                                                                    isWithinGracePeriod() ? (
                                                                                                        <p className="text-black mb-0">Expired</p>
                                                                                                    ) : (
                                                                                                        <p className="text-black mb-0">Expiry date: {formatDate(item.expiry_date)}</p>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                    <h3 className="fw-600">
                                                                        {item?.plan_name
                                                                            ? item.plan_name[0].toUpperCase() + item.plan_name.slice(1, 20) + (item.plan_name.length > 20 ? ".." : "")
                                                                            : ""}
                                                                    </h3>
                                                                    <p className="images-description mb-4 tier-description">
                                                                        {item?.description?.length > 80 ? item?.description?.substring(0, 80) + ".." : item?.description}
                                                                    </p>
                                                                    <div className="d-flex justify-content-center flex-wrap" style={{ gap: 10 }}>
                                                                        {
                                                                            item.is_activated === 0 ? (
                                                                                <>
                                                                                    {
                                                                                        item.plan_type === 'free' ? (
                                                                                            <button className="common-btn btn-tier min-width-200" onClick={() => {
                                                                                                setItem(item);
                                                                                                setActivateTier(true);
                                                                                            }}>Activate Plan</button>
                                                                                        ) : (
                                                                                            <button className="common-btn btn-tier min-width-200" onClick={() => {
                                                                                                setItem(item);
                                                                                                setActivateTier(true);
                                                                                            }}>Buy Plan</button>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {
                                                                                        item.plan_type === 'free' ? (
                                                                                            <button className="btn-tier grey-tier min-width-200"><img src={check_blue} alt="Purchased" className='pe-1'/><span className='me-2'>Activated</span> </button>
                                                                                        ) : isWithinGracePeriod() ? (
                                                                                            <button className="common-btn btn-tier min-width-200" onClick={() => {
                                                                                                setItem(item);
                                                                                                setActivateTier(true);
                                                                                            }}>Renew Plan</button>
                                                                                        ) : (
                                                                                            <button className="btn-tier grey-tier min-width-200">
                                                                                                <img src={check_blue} alt="Purchased" /> Purchased
                                                                                            </button>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                        <button className="common-btn btn-tier" style={{padding:'14px 46px !important;'}} onClick={() => navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.id } })}>
                                                                            <img src={arrow_large} alt="Details" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }</> :
                                                <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                                    <img src={no_plans} alt="No Challenges" />
                                                </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        activateTier &&
                        <ActivateTier setActivateTier={setActivateTier} getBuyApi={getBuyApi} item={item} />
                    }
                </div>
            </div>
        </div>
    )
}

export default UserBucketList;