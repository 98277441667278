import React, { useState, useEffect, useRef } from 'react'
import { DEMO_IMAGE_ONE, no_plan_placeholder, playLight } from '../../../Constants/images';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config'
import ReactLoader from '../../../ControlComponents/react-loader';
import BiteModal from '../../../Components/Bites';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';

function AllBites() {
   const navigate = useNavigate();
   const [bitesList, setBitesList] = useState([]);
   const [showBite, setShowBite] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [index, setIndex] = useState(0);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);
   const [page, setPage] = useState(1);
   const loadMoreRef = useRef(null);
   const [scroll, setScroll] = useState(false)
   const [hasMoreItems, setHasMoreItems] = useState(true);
   const { id } = useParams()

   const handleAPIError = async (result) => {
      if (result.code === 401) {
         const refreshToken = localStorage.getItem("refresh-token");
         const data = {
            refresh_token: refreshToken,
            scope: "refresh_token",
         };
         const refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
         if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token);
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
            setRefresh(true);
         }
      }
   };

   const fetchBitesList = async () => {
      setIsLoading(true);
      try {
         const result = await apiHelper.getRequest(`bites/bites-list?page=${page}`);
         if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            const newBites = result?.data.reels;
            if (page === 1) {
               setBitesList(result?.data.reels);
            } else {
               setBitesList((prevBites) => [...prevBites, ...newBites]);
            }
            setHasMoreItems(newBites.length === 12);
            if (id != undefined) {
               const index = result?.data.reels.findIndex(bite => bite.id == id);
               const biteObject = result?.data.reels[index]
               handleBitePlay(index, biteObject)
            }
         } else {
            handleAPIError(result);
         }
      } catch (error) {
         console.error("Error fetching bites list:", error);
      } finally {
         setIsLoading(false);
      }
   };


   const handleBitePlay = (index, bite) => {
      if (id != undefined) {
         const selectedBiteId = bitesList[index]?.id;
         if (selectedBiteId != undefined) {
            navigate(`/bites/${selectedBiteId}`);
         }
      }
      if (bite?.tier_details?.is_purchased == 0) {
         localStorage.setItem('tierContentId', bite.id + "bite")
         navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: bite.tier_details.tier_id } })
      }
      else {
         setShowBite(true);
         setCurrentBiteIndex(index)
      }
   };

   const handleLoadMore = () => {
      setScroll(true)
      setPage(prevPage => prevPage + 1);
   };

   useEffect(() => {
      fetchBitesList();
   }, [refresh, page])

   useEffect(() => {
      if (loadMoreRef.current && scroll) {
         loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
         setScroll(false)
      }
   }, [bitesList, scroll]);

   return (
      <div className='main-inner-content'>
         {isLoading && <ReactLoader />}
         {
            bitesList.length > 0 &&
            <section className="featured response-padding py-60">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600">Featured</h3>
                  </div>

                  <div className="challene-wrapper mt-4">
                     <div className=" row" style={{ cursor: "pointer" }}>
                        {
                           bitesList.length > 0 && bitesList?.slice(0, 3).map((bite, index) => (
                              <div
                                 key={bite.id}
                                 className="featured-box-cus position-relative col-lg-4 mb-4 mb-lg-0">
                                 <div className="video-bg video-wrapr">
                                    <div className="video-content">
                                       <img
                                          onClick={() => handleBitePlay(index, bite)}
                                          src={bite.thumbnail}
                                          className="img-video img-fluid"
                                          alt=""
                                       />
                                    </div>
                                    <div className='content-video-feat'>
                                       <h4 className="mt-3">{bite?.title}</h4>
                                       <div className="video-detail" style={{ cursor: "pointer" }} >
                                          <div>
                                             <img
                                                src={bite?.biteChannel?.profile_pic}
                                                className="bite-inner-img chllge-img"
                                                alt=""
                                                onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}
                                             />
                                          </div>
                                          <div>
                                             <h5 className="mb-0 fw-600" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}>{bite?.biteChannel?.name}</h5>
                                             <p className="mb-0" onClick={() => navigate(ROUTES.CATEGORY, { state: { id: bite?.category?.id, catName: bite?.category?.name } })}>{bite?.category?.name}</p>
                                          </div>
                                       </div>
                                    </div>

                                 </div>

                              </div>
                           ))
                        }
                     </div>
                  </div>
               </div>
            </section>
         }

         <section className="bite response-padding">
            <div className="container px-0 px-md-2">
               <div className="chanel-header">
                  <h3 className="fw-600 mb-0">Bites</h3>
               </div>

               <div className="chanel-wrapper mt-4">
                  <div className="row gy-2" style={{ cursor: "pointer" }}>
                     {bitesList?.length > 0 && bitesList?.map((bite, index) =>
                        <div className="col-md-6 col-xxl-4  response-col" key={bite.id}>
                           <div className="channel-bites-content">
                              <img src={bite.thumbnail} className="channelbite-img" alt="" onClick={() => handleBitePlay(index, bite)} />
                              <div className="bite-wrapper">
                                 <div className='tooltip-container'>
                                    <h4 className='mb-0'>{bite.title}</h4>
                                    <span className="tooltip-text">{bite?.title}</span>
                                 </div>
                                 <div className="chanel-text d-flex gap-3 align-items-center" style={{ cursor: "pointer" }} >
                                    <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" alt="" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })} />
                                    <div>
                                       <h6 className='fw-600 mb-0' onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}>{bite?.biteChannel?.name}</h6>
                                       <p className='mt-1'  onClick={() => navigate(ROUTES.CATEGORY, { state: { id: bite?.category?.id, catName: bite?.category?.name } })}>{bite?.category?.name}</p>
                                    </div>
                                 </div>
                                 <button onClick={() => handleBitePlay(index, bite)} className="common-btn"><img src={playLight} alt="" />Play</button>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
               {
                  showBite &&
                  <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={bitesList} showBite={showBite} setShowBite={setShowBite} handleBitePlay={handleBitePlay} />
               }
               {
                  hasMoreItems && <div style={{ cursor: 'pointer' }} onClick={handleLoadMore} className="load-text mb-3">
                     Load More...
                  </div>
               }
               <div ref={loadMoreRef} ></div>
            </div>

         </section>

         {
            !isLoading &&
            <>
               {
                  bitesList?.length === 0 &&
                  <>
                     <h3 className="fw-600 mb-0 mx-5 my-3">Bites</h3>
                     <div style={{ flexDirection: 'column' }} className='no-search-results'>
                        <img src={no_plan_placeholder} alt="No Challenges" />
                        <button className='common-btn' onClick={() => navigate(ROUTES.CHANNELS + "?cd=network")} >Explore</button>
                     </div>
                  </>
               }
            </>
         }

      </div>
   );
}

export default AllBites;
