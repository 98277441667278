import React, { useEffect, useState } from 'react'
import { playLightBtn, seeAllArrow } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config'
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
function NewChallenges(props) {
   const [refresh, setRefresh] = useState(false)
   const [challengesList, setChallengesList] = useState([])
   const navigate = useNavigate()

   async function getAPI() {
      props?.setIsLoading(true)
      let url;
      url = "challenges/challenges-list?offset=1&limit=4&sort=z-a"
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setChallengesList(result?.data.allChallenge)
         console.log(result.data.allChallenge, "allChallengeallChallenge")
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   useEffect(() => {
      getAPI()
   }, [refresh]);

   const handleNavigate = (item) => {
      if (item.tier_details?.is_purchased == 0) {
         localStorage.setItem('tierContentId', item.id + 'challenge')
         navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
       }
       else{
         localStorage.setItem("userChallengeId", item.id)
         localStorage.setItem("challengeItem", JSON.stringify(item))
         navigate(ROUTES.CHALLENGES_DETAILS, { state: { item } })
       } 
   }

   return (
      <div>
         {
            challengesList?.length > 0 &&
            <section className=" latest-challdata latest-challenge response-padding">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600 mb-0">New Challenges</h3>
                     <button onClick={() => navigate(ROUTES.CHALLENGES)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2"/></button>
                  </div>
                  <div className='col-12'>
                     <div className='d-flex gap-4 align-items-center justify-content-start latest-challenge-list'>
                        {
                           challengesList.reverse().map((challenge, index) => (
                              <div key={index} className='challenge-data mt-4' style={{ minWidth: challenge.orientation == "Portrait" ? '220px' : '350px', width: challenge.orientation == "Portrait" ? '200px' : '350px', cursor: 'pointer' }} onClick={() => handleNavigate(challenge)} >
                                 <div className='video-challenge position-relative'>
                                    <img src={challenge.thumbnail} className="video-deatil-chall img-fluid" alt="" />
                                    <div className="btn-play-video">
                                       <img src={playLightBtn} alt="" className='img-fluid play-icon-data' />
                                    </div>
                                    <div className="grid-data-detail">
                                       <div className='grid-circle-img'>
                                          <img src={challenge?.channelData?.profile_pic} className="img-fluid grid-data-img" alt=""/>
                                       </div>
                                       <div>
                                          <h6 className="white fw-500 mb-0">{challenge?.channelData?.name}</h6>
                                          <p className='mb-0'>{challenge?.category.name}</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className='challenge-video-title'>
                                    <p className='mb-0'>{challenge?.title}</p>
                                 </div>
                              </div>
                           ))
                        }
                     </div>
                  </div>
               </div>
            </section>
         }
      </div>
   )
}

export default NewChallenges   