import React, { useState } from 'react';
import { dotsbg, graycalender, graytimer, profile_image_placeholder, videoPlayButton } from '../../Constants/images';
import { Dropdown } from 'react-bootstrap';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader';
import { NotificationManager } from 'react-notifications';
import ROUTES from '../../Helper/route-helper';
import { useLocation, useNavigate } from 'react-router-dom';
import MultiVideoPlayer from '../component/multiVideoPlayer';

function AllResoponses() {
    const location = useLocation()
    const responses = JSON.parse(localStorage.getItem("responses"))
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [embededVedio, setEmbededVedio] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    localStorage.setItem("responses", JSON.stringify(responses))

    const handleClick = (item, index) => {
        setShow(!show)
        if (item.video_type == 2) {
            setEmbededVedio(true);
        }
        setCurrentVideoIndex(index)
    }

    async function getShortList(id, check) {
        if (location.state.episodes?.length == 10 && check == "shortlist") {
            NotificationManager.error("Limit exceeded to shortlist the responses")
            return
        }
        try {
            setIsLoading(true);
            let result = await apiHelper.getRequest(`creator/challenge-shortlist?responseId=${id}`);

            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setIsLoading(false);
                navigate(ROUTES.CREATER_CHALLENGES_DETAIL);
                localStorage.setItem("message", result.message);
            } else if (result.code === 401) {
                let refreshToken = localStorage.getItem("refresh-token");
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                };
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);

                if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token);
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                    result = await apiHelper.getRequest(`creator/challenge-shortlist?responseId=${id}`);
                    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                        setIsLoading(false);
                        navigate(ROUTES.CREATER_CHALLENGES_DETAIL);
                        localStorage.setItem("message", result.message);
                    } else {
                        setIsLoading(false);
                        console.error('Error:', result.message);
                    }
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                console.error('Error:', result.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Unexpected error:', error);
        }
    }



    const formatTime = (date) => {
        let createdAtDate = new Date(date);
        const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return formattedTime
    };

    function modifyDuration(duration) {
        const durationParts = duration?.split(':');
        if (durationParts && durationParts[0] === '00') {
            return durationParts.slice(1)?.join(':');
        }
        return duration;
    }

    const formatDate = (date) => {
        let createdAtDate = new Date(date);
        const formattedDate = `${createdAtDate.getDate().toString().padStart(2, '0')}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getFullYear()}`;
        return formattedDate;
    };
    return (
        <div className="main-inner-content">
            {
                isLoading && <ReactLoader />
            }
            <section className="episode-list-main bite response-padding ">
                <div className="container">

                    <div className='note-challenge'>
                        <h6>Note: Creator can select up to 10 responses for a specific challenge.</h6>
                    </div>

                    <h3 className="fw-600 mt-4">All Responses</h3>
                    <div className="chanel-wrapper mt-4">
                        <section className="schedule-wrapper">
                            <div className="container-fluid">
                                <div className='row mt-4'>
                                    {
                                        responses?.map((item, i) => (
                                            <div className="col-12 col-lg-6 col-xl-4 pb-4" key={item.id} >
                                                <div className="latest-video mx-auto challenges-detailcreate">
                                                    <div className='position-relative'>
                                                        <div
                                                            className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                                                            style={{ cursor: "pointer" }} >
                                                            <img
                                                                onClick={() => handleClick(item, i)}
                                                                src={item.thumbnail}
                                                                className="img-fluid latest-vid-img"
                                                            />
                                                            <div className="play-epi">
                                                                <img src={videoPlayButton} className="img-fluid" />
                                                            </div>
                                                            <div className="dotsmain"> <Dropdown>
                                                                <Dropdown.Toggle id="custom-dropdown-toggle">
                                                                    <img src={dotsbg} className='img-fluid'></img>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu id={i == 1 ? "custom-drop-challenge" : "custom-dropdown-menu"}
                                                                    onClick={() => getShortList(item?.id, "shortlist")}
                                                                >
                                                                    <Dropdown.Item >Shortlist as episode
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown> </div>
                                                            <div className=''> <span className="videoepisode">
                                                                {item.video_type == 1 ? "URL Link" : item.video_type == 2 ? "Embeded" : "video"}
                                                            </span></div>                      </div>
                                                    </div>
                                                    <div className="latest-video-cus px-3 py-3 challenege-grid-create position-relative">
                                                        <div className='profile-challengecreate'>
                                                            <img src={item.user.profile_pic ? item.user.profile_pic : profile_image_placeholder}></img>                      </div>
                                                        <div className='text-start my-2 my-sm-0'>
                                                            <h4 className="schedule-title mb-0 one-line">{item.user.name}</h4>
                                                            <div className="timing-flex-create mt-2 d-flex gap-2 align-items-center">
                                                                <div className='mb-0 timing-inner-flex d-flex '>
                                                                    <img src={graycalender} />
                                                                    <span className='ps-1 new-text fw-400 f14-size '>
                                                                        {formatDate(item.created_at)}
                                                                    </span>
                                                                </div>
                                                                <div className='mb-0 timing-inner-flex d-flex '>
                                                                    <img src={graytimer} />
                                                                    <span className='ps-1 new-text f11-size fw-500 '>
                                                                        {formatTime(item.created_at)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='dotschedule'>
                                                            <p className='mb-0 fw-500 f12-size'>
                                                                {modifyDuration(item.duration)
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </section>
                        {
                            show == true && <MultiVideoPlayer show={show}
                                setShow={setShow}
                                video_url={responses[currentVideoIndex]?.video_url}
                                embededVedio={embededVedio}
                                setEmbededVedio={setEmbededVedio}
                                currentVideoIndex={currentVideoIndex}
                                setCurrentVideoIndex={setCurrentVideoIndex}
                                videoList={responses.map(video => video.video_url)}
                            />
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AllResoponses;