import React, { useState } from 'react'
import { modalCross } from '../../../Constants/images'
import DEVELOPMENT_CONFIG from "../../../Helper/config"
import { NotificationManager } from 'react-notifications';
import apiHelper from '../../../Helper/api-helper';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';
const BuySeries = ({ setOpen, item,getDetailsAPI,setIsLoading,check
}) => {
    const navigate = useNavigate()
    async function buyNowApi() {
        setIsLoading(true);
        let data = {
            "item_id": item.id,
            "item_type": 1,
            "price": item.price,
            "mode_of_payment": "credit_card"
        }
        try {
            const result = await apiHelper.postRequest("shop/order-now", data);
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                setIsLoading(false)
                setOpen(false)
                NotificationManager.success(result.message)
                getDetailsAPI()
            }
        } catch (error) {
            setIsLoading(false)
        }
    }
    
    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Buy Series</h3>
                        <a onClick={() => {
                            {
                                if(check == 'frame'){
                                    navigate(ROUTES.HOME)
                                }
                                setOpen(false)
                            }
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                        <div className='delete-content text-center'>
                            <p> Are you sure you want to buy the series
                            </p>
                        </div>
                        <div className='text-center p-3' style={{ cursor: "pointer" }}>
                            <button className="common-btn yes-option" onClick={() => buyNowApi()}>Yes</button>
                            <button className="no-btn" onClick={() => {
                                setOpen(false)
                            }}> No</button>  </div>

                    </div>
                </div>
            </div> 
        </div>
    )
}

export default BuySeries
