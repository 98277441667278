import React from 'react';
import { noChallenges } from '../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
function ChallengesChannel({loading,detailList}) {
  const navigate = useNavigate()

  const handleNavigate = (item) => {
    if (item.tier_details?.is_purchased == 0) {
      localStorage.setItem('tierContentId', item.id + 'challenge')
      navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
    }
    else{
      localStorage.setItem("userChallengeId", item.id)
      localStorage.setItem("challengeItem", JSON.stringify(item))
      navigate(ROUTES.CHALLENGES_DETAILS, { state: { item } }) 
    }
  }
  
  return (
  
      <section className="favourite-cus bite response-padding ">
       
        <div className="container-fluid">
          
          <div className="chanel-wrapper">
            <div className="row gy-2">
              {
               detailList.length > 0 && detailList?.map((element, index) => {
                  return (
                    <div key={element?.id} className="col-12 col-lg-6 col-xl-4 col-xxl-3 px-0">
                      <div className="chanel-content">
                        {element?.thumbnail != null && <img src={element?.thumbnail} className="bite-img-fav img-width" alt="" />}
                        <div className="bite-wrapper">
                          <h4 className='mb-0'>{element.title}</h4>
                          <div className="chanel-text my-2 d-flex gap-2 align-items-center">
                            {element?.channelData?.profile_pic != null && <img src={element?.channelData?.profile_pic} className="bite-inner-img" alt="" />}
                            <div>
                              <p className='fw-600 mb-0'>{element?.channelData?.name}</p>
                              <p className=''>{element?.category?.name}</p>
                            </div>
                          </div>
                          <button className="common-btn" style={{ cursor: "pointer", padding:'5px 10px' }}
                            onClick={() => handleNavigate(element)}
                          >View Detail</button>
                        </div>
                      </div>
                    </div>
                  )

                })
              }
              {
                !loading &&
                <>
                  {detailList?.length == 0 &&
                    <div className='no-search-results'>
                      <img src={noChallenges} />
                    </div>

                  }
                </>
              }
            </div>
          </div>
        </div>
      </section>

  )
}

export default ChallengesChannel;