import React, { useState } from 'react';
import { favRed, favouriteLight, playLightBtn, seeAllArrow } from '../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ROUTES from '../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';
import BuyModal from './buyModal';
import BuySeries from '../Frame20/Components/buySeries';
import PriceTag from '../../CreaterDashboard/component/price-tag';


function ShopOtherSeries(props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState(null)
  const [_, forceUpdate] = useState(0)
  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  const handleClick = (item) => {
    props?.setId(item)
    props.setClick(true)
  }
  const handleOpen = (item) => {
    setItem(item)
  }

  return (
    <div>
      {
        props?.moreSeries?.length > 0 &&
        <section className="more-movies mb-5">
          <div className="container">
            <div className="chanel-header">
              <h3 className="fw-600 mb-0 mt-4">More From {props?.seriesDetail?.channelDetails?.name}</h3>
              <button onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=shopChannel", { state: { item: props?.seriesDetail } })} className="see-all fw-600 f18-size">
                See all
                <img src={seeAllArrow} alt="" className="ms-2" />
              </button>
            </div>

            <div className="row mt-4 pt-3">
              {
                props?.moreSeries?.map((item, index) => {
                  return (
                    <div className='col-12 col-sm-6 col-lg-4'>
                      <div key={item?.id} className="item mb-5">
                        <div className="epsoide-slider-custom position-relative series-episodes-thumb">
                          <div className='position-relative' >
                            <img
                              src={item?.thumbnail}
                              className="episode-img-thumb"
                              alt=""
                            />
                            {(item?.is_purchase == 0 && item.price != 0) && <PriceTag classes={"price-episodes-label"} firstValue={`$${item.price}`} secondValue={"00"} />}
                          </div>
                          <div className='epsoide-title d-flex justify-content-between align-items-center gap-3'>
                            <div>
                              <h5 className=" m-0 fw-600">  <h5 className="m-0 fw-600">
                                {item?.title.length > 15 ? item.title.substring(0, 25) + "..." : item.title}
                              </h5>
                              </h5>
                              <p className="mb-0 fw-500" style={{ color: "#9DA4BF" }}>
                                {item.episodes_count} {item.episodes_count == 1 ? "Episode" : "Episodes"}
                              </p>
                            </div>
                            {
                              item?.tier_details == null ?
                                <> {
                                  item.is_purchase == 0 ? <button className="more-play-btn" onClick={() => {
                                    setOpen(true)
                                    setItem(item)
                                  }}>Buy now</button>
                                    : <button className="more-play-btn" onClick={() => {
                                      if (item.is_purchase == 1) {
                                        handleClick(item?.id);
                                      }
                                    }}>View</button>
                                }</> :
                                <button className="more-play-btn" onClick={() => {
                                  if (item.tier_details?.is_purchased == 1) {
                                    handleClick(item?.id);
                                  }
                                  else {
                                    localStorage.setItem('tierContentId', item.id + 'series')
                                    navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item?.tier_details.tier_id } })
                                  }
                                }}>View</button>
                            }
                          </div>
                          {/* <div className="trending-number-epi">
                            <h2 className="white">{index + 1}</h2>
                          </div> */}
                          <div className="epsoide-play">
                            <button className="eps-play esp-playbtn">
                              <img src={playLightBtn} alt="" className='play-epi-slider' />
                            </button>
                          </div>
                          <div onClick={() => addFavourites(item)} className="like-btn-muvi">
                            {
                              item?.is_fav == 1 ?
                                <img src={favRed} /> :
                                <img src={favouriteLight} />
                            }
                          </div>
                        </div>

                      </div>
                    </div>

                  )
                })
              }
            </div>
            {
              open && <BuySeries setOpen={setOpen} item={item} getDetailsAPI={props.getDetailsAPI} setIsLoading={props.setIsLoading} />
            }
          </div>
          {/* {buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} check="series" getListAPI={getListAPI} />} */}
        </section>

      }

    </div>
  )
}

export default ShopOtherSeries;