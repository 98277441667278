import React, { useEffect, useRef, useState } from 'react'
import { no_tip, profile_image_placeholder } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"

function Tip() {
    const [tiplist, setTipList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [detail, setDetail] = useState()
    const [clickedId, setClickedId] = useState(null)
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [offset, setOffset] = useState(1);
    const [scroll, setScroll] = useState(false)
    const loadMoreRef = useRef(null);

    const handleClick = (item) => {
        setClickedId(item.id)
        getDetailApi(item.user_details.id)
    };

    const handleLoadMore = () => {
        setScroll(true)
        setOffset(prevPage => prevPage + 1);
    };

    async function getApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/get-tips-history?limit=${9}&offset=${offset}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            const id = result?.data?.data[0]?.user_details?.id
            const newList = result?.data.data;
            if (offset === 1) {
                setTipList(result?.data?.data)
                setClickedId(result?.data.data[0]?.id)
            } else {
                setTipList((prevList) => [...prevList, ...newList]);
            }
            setHasMoreItems(newList?.length == 9);
            getDetailApi(id)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        if (loadMoreRef.current && scroll) {
            loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setScroll(false)
        }
    }, [tiplist, scroll]);

    async function getDetailApi(id) {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/tip-details?customer_id=${id}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setDetail(result?.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getApi()
    }, [refresh, offset])

    const getDate = (createdAt) => {
        const date = new Date(createdAt);
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
        return formattedDate
    }

    return (
        <div className="main-inner-content">
            {
                isLoading && <ReactLoader />
            }
            <div className="row pb-4">
                {
                    tiplist?.length > 0 ? <>  <div className="col-12 col-lg-7 pb-4 pb-lg-0">
                        <div className="subscribers-table">
                            <h3 className='fw-600 mt-2 mb-4 mx-2'>Tips</h3>
                            <div className="table-responsive tip-scroll">
                                <table className="table">
                                    <tbody>
                                        {
                                            tiplist?.map((item) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <div className="d-flex  align-items-center justify-content-start">
                                                            <img src={item?.user_details?.profile_pic ? item?.user_details?.profile_pic : profile_image_placeholder}
                                                                className=" rounded-circle subs-img" />
                                                            <p className="subscribers ms-3 mb-0 f20-size fw-600">{item?.user_details?.name}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="subscribers f20-size fw-500 mb-0 lh-0" style={{ color: "#737373" }}>{getDate(item?.created_at)}</p>
                                                    </td>
                                                    <td>
                                                        <p className="subscribers f20-size fw-500 mb-0 lh-0" style={{ color: '#ca3392' }}>${item?.price}</p>
                                                    </td>
                                                    <td><button
                                                        onClick={() => handleClick(item)}
                                                        className={`btn text-white view-btn text-center mx-0 ${clickedId == item?.id && 'view-click'}`}>View Details</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>

                                </table>
                                {
                                    hasMoreItems &&
                                    <div style={{ cursor: 'pointer' }} onClick={handleLoadMore} className="load-text mb-3">
                                        Load More...
                                    </div>
                                }
                                <div ref={loadMoreRef} ></div>

                            </div>
                        </div>
                    </div>
                        {
                            detail &&
                            <div className="col-12 col-lg-5">
                                <div className="subscribers-table subscribers-table-detail ">
                                    <div className='tip text-center my-3'>
                                        <img src={detail.profile_pic} className='img-fluid' />
                                        <h3 class="fw-600 mx-2 my-2">{detail.name}</h3>
                                        <div className="d-flex justify-content-center">
                                            <p className="total-tip">Total Tip: ${detail.total_tip}</p>
                                        </div>
                                        <hr style={{ height: 2, margin: '0.5rem 0' }} />
                                    </div>
                                    <h4 className='fw-600 mt-2 mb-4 mx-2' style={{ paddingLeft: "15px" }}>Tip History</h4>

                                    <div className="table-responsive tip-history" style={{ padding: "0px 15px 15px 15px" }}>
                                        <table className="table">
                                            <tbody>
                                                {
                                                    detail.tips_data.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <p className="subscribers f20-size fw-500 mb-0 lh-0" style={{ color: "#737373" }}>{getDate(item.created_at)}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="subscribers f20-size fw-500 mb-0 lh-0 d-flex justify-content-end" style={{ color: '#ca3392' }}>${item.price}</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }</> : <> <h3 className='fw-600 mt-2 mb-4 mx-2'>Tips</h3>
                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                            <img className='img-fluid' src={no_tip} />
                            <h5 className='d-flex justify-content-center mt-2'>No Tip yet</h5>
                        </div>
                    </>
                }

            </div>

        </div>
    )
}

export default Tip