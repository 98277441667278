import React, { useEffect, useState } from "react";
import {live_calender, live_clock, no_live } from "../../Constants/images";
import PriceModal from "./priceModal";
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from "../../ControlComponents/react-loader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";

const ChooseUpcomingLive = () => {
    const [seriesId, setSeriesId] = useState("");
    const [viewOn, setViewOn] = useState(false);
    const navigate = useNavigate()

    const [liveList, setLiveList] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const handleRadio = (id) => {
        if (seriesId === id) {
            setSeriesId("");
        } else {
            setSeriesId(id);
        }
    }

    const handleSubmit = () => {
        if (seriesId) {
            setViewOn(true);
        }
    }
    
    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }


    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        originalDateTime.setHours(originalDateTime.getHours() + 5);
        originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);

        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    async function getLivesAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/get-schedules?type=2")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setLiveList(result?.data.upcoming)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }
    useEffect(() => {
        getLivesAPI()
    }, [refresh])

    const hanldeClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item))
        navigate(ROUTES.CREATER_RECORDED_VIDEO)
      }

    return (

        <div className="main-inner-content">
            {
                loading && <ReactLoader />
            }
            <section className="choose-wrapper">
                <div className="container">
                    <div className="row align-items-center py-3">
                        <div className="col-sm-5">
                            <div className="">
                                <h3 className="fw-600">Choose Upcoming Live</h3>
                            </div>
                        </div>
                        <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
                            <button className={seriesId ? "common-btn" : "common-btn-grey"} onClick={handleSubmit} style={{ cursor: seriesId ? "pointer" : "not-allowed" }}>Submit</button>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='row mt-4'>
                            {liveList.filter(item => item.price == 0)?.length > 0 ? (
                                <>
                                    {liveList?.filter(item => item.price == 0)?.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                            key={item?.id}
                                        >
                                            <div className="latest-video mx-auto" >
                                                <div className='position-relative'
                                                 onClick={() => hanldeClick(item)} >
                                                    <div
                                                        className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                                        style={{ cursor: "pointer" }}>
                                                        <img
                                                            src={item.thumbnail}
                                                            className="img-fluid latest-vid-img new-latest-video"
                                                            alt="thumbnail"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                    <div className=''  onClick={() => hanldeClick(item)}>
                                                        <h4 className="schedule-title">{item.name} </h4>
                                                        <div className="timing-flex">
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_calender} alt="calendar" />
                                                                <span className='ps-1'>
                                                                    {dateFunction(item.date_time)}
                                                                </span>
                                                            </div>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_clock} alt="clock" />
                                                                <span className='ps-1'>
                                                                    {addTime(item.date_time)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="choose-live-card-radio" style={{ cursor: "pointer" }}>
                                                        <input className="radio-btn" type="radio" checked={seriesId === item?.id} onClick={() => handleRadio(item?.id)} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : <>
                                {
                                    loading == false &&
                                    (
                                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                            <img src={no_live} alt="no live" />
                                        </div>
                                    )
                                }
                            </>
                            }
                        </div>

                    </div>
                </div>
            </section>
            {viewOn && <PriceModal handlerFunction={setViewOn} seriesId={seriesId} check="schedule" />}
        </div>

    )
}
export default ChooseUpcomingLive