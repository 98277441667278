import React, { useState } from 'react'
import { DEMO_IMAGE_ONE, playLightBtn } from '../../Constants/images'
import './seriesDetail.css'
import PlayBiteModal from '../Bites/playBite'

function BitesSeries({ seriesDetail }) {
    const [videoUrl, setVideoUrl] = useState("")
    const [id, setId] = useState(null)
    const [item, setItem] = useState("")
    const [showBite, setShowBite] = useState(false)
    const handleBitePlay = (item) => {
        setVideoUrl(item.video)
        setShowBite(!showBite);
        setId(item.id)
        setItem(item)
    }
    return (
        <div>
            <section className=" latest-challdata latest-challenge response-padding mt-5">
                <div className="container">
                    {
                        seriesDetail?.reels && <div className="chanel-header">
                            <h3 className="fw-600 mb-0">Bites</h3>
                            {/* <button
                                onClick={() => navigate(ROUTES.CREATER_BITES)}
                                className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button> */}
                        </div>
                    }

                    <div className='d-flex flex-wrap gap-4'>
                        {
                            seriesDetail?.reel?.length > 0 ?
                                <> {
                                    seriesDetail?.reels?.map((item) => {
                                        return (
                                            <div className='new-bites-series'
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className='bites-seriesdetails mt-5' onClick={() => handleBitePlay(item)}>
                                                    <div className='video-challenge position-relative'>
                                                        <img src={item.thumbnail} className="video-deatil-chall img-fluid" alt="video-thumbnail" style={{ borderRadius: '25px' }} />
                                                        <div className=" btn-play-video"><img src={playLightBtn} alt="" className='img-fluid play-icon-data' /></div>
                                                        <h4 className='text-white' style={{ position: 'absolute', bottom: '10px', paddingLeft: '10px', fontSize: '22px', fontWeight: '500' }}>{item.title}</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }</> :
                                <div className="no-search-results position-relative">
                                    <img src={DEMO_IMAGE_ONE} alt="No Challenges" />
                                    <h6 className='position-absolute' style={{ top: 412 }}>No bites yet</h6>
                                </div>
                        }

                    </div>
                </div>
            </section>
            {
                showBite &&
                <PlayBiteModal showBite={showBite} setShowBite={setShowBite} videoUrl={videoUrl} id={id} item={item} check="creator" />
            }
        </div>

    )
}
export default BitesSeries