import React, { useState, useEffect } from 'react'
import ROUTES from '../../../Helper/route-helper';
import BuyModalLive from '../../../Components/Lives/buyLive';
import { useNavigate } from 'react-router-dom';
import PriceTag from '../../../CreaterDashboard/component/price-tag';
import { buy_btn, live_calender, live_clock, live_icon, playBtn, playGrey } from '../../../Constants/images';

function LiveSearch({ lives, setIsLoading, handleSearch, tabSwitch }) {
    let chatId = "1"
    const [buyModal, setBuyModal] = useState(false);
    const [item, setItem] = useState(null);
    const navigate = useNavigate()
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    };

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        originalDateTime.setHours(originalDateTime.getHours() + 5);
        originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const isCurrentDateTime = (dateTime) => {
        const itemDateTime = new Date(dateTime);
        return itemDateTime <= currentDateTime && itemDateTime >= currentDateTime;
    };
    
    const handleClick = (item) => {
        const trimmedName = item.schedule.name?.replace(/\s/g, '');
        const concatenatedString = `${item?.schedule.id}${trimmedName}`;
        chatId = concatenatedString
        localStorage.setItem("chatId", chatId)
        localStorage.setItem("liveHome", 0)
        navigate(ROUTES.LIVE_SECTION, { state: { item } })
    }

    return (
        <div className="row" style={{ marginTop: 0 }}>
            {
                (lives?.length > 0 && tabSwitch == 0
                ) && <div className="chanel-header">
                    <h3 className="fw-600 my-3">Lives</h3>
                </div>
            }
            <div className='row mt-4'>
                {
                    lives?.slice(0, 4)?.map((item) => (
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4">
                            <div className="latest-video mx-auto">
                                <div className='position-relative'>
                                    <div
                                        className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                                        style={{ cursor: "pointer" }}>
                                        <div className="position-relative">
                                            <img src={item?.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />
                                            {(item?.is_purchase == 0 && item.price != 0) && <PriceTag classes={"price-episodes-label"} firstValue={`$${item.price}`} secondValue={"00"} />}
                                        </div>
                                        <div
                                            className="d-flex justify-content-start align-items-center gap-2 lives-logo"
                                            onClick={() => {
                                                const customItem = {
                                                    name: item.user.name,
                                                    id: item.user.id,
                                                    profile_pic: item.user.profile_pic
                                                };
                                                navigate(ROUTES.CHANNELS_DETAILS + "?cd=favChannel", {
                                                    state: { item: customItem }
                                                });
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                src={item.user.profile_pic}
                                                className="img-fluid grid-data-img"
                                                alt=""
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '100%',
                                                    border: '1px solid #CA3392'
                                                }}
                                            />
                                            <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>
                                                {item.user.name}
                                            </h6>
                                        </div>


                                        {
                                            item.status == 1 &&
                                            <div className='live-img-btn'>
                                                <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                    <img src={live_icon} className='me-2' />Live</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="latest-video-cus px-3 py-3 schedule-grid">
                                    <div class="grid-data-detail-lives position-relative">
                                        <div className="tooltip-container">
                                            <h6 className='fw-600 f20-size channel-text text-capitalize  me-2 mb-3'>
                                                {item.name.length > 20 ? item.name.substring(0, 20) + ".." : item.name}
                                            </h6>
                                            <span className="tooltip-text">{item.name}</span>
                                        </div>
                                        {
                                            item.status == 1 ? <div className='d-flex align-items-center my-3'>
                                                <div className='live-img-btn-below '>
                                                    <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                        <img src={live_icon} className='me-2' />Live</p>
                                                </div>
                                            </div> :
                                                <div className="timing-flex">
                                                    <div className='mb-0 timing-inner-flex'>
                                                        <img src={live_calender} alt="calendar" />
                                                        <span className='ps-1'>{dateFunction(item.date_time)}</span>
                                                    </div>
                                                    <div className='mb-0 timing-inner-flex'>
                                                        <img src={live_clock} alt="clock" />
                                                        <span className='ps-1'>{addTime(item.date_time)}</span>
                                                    </div>
                                                </div>
                                        }

                                    </div>

                                    <div className='dotschedule bg-transparent mb-1'>
                                        <div className='dropdownschedule'>
                                            {
                                                (item.is_purchase == 0 && item.price != 0) ?

                                                    <span style={{ cursor: "pointer" }} className="text-end">
                                                        <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }}
                                                            onClick={() => {
                                                                setBuyModal(true)
                                                                setItem(item)
                                                            }}
                                                        />                                                   </span> : <span style={{ cursor: "pointer" }} className="text-end"

                                                        >
                                                        {
                                                            item?.status == 1 ?
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} onClick={() => handleClick(item)}
                                                                /> :
                                                                <>
                                                                    {
                                                                        isCurrentDateTime(item.date_time) && item.status == 2 ? <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} /> : <img src={playGrey} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                    }
                                                                </>
                                                        }

                                                    </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
            {
                buyModal && <BuyModalLive setBuyModal={setBuyModal}
                    getApi={handleSearch}
                    setIsLoading={setIsLoading}
                    item={item} check={item?.schedule?.status == 1 ? "live" : "upcoming"} />
            }
        </div>
    )
}

export default LiveSearch;