import React from 'react'
import { downArrow, profile_image_placeholder } from '../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';

const User = (props) => {
   const role = localStorage.getItem("role")
   const navigate = useNavigate()
   const userDropdown = () => {
      props?.setShowModal(!props?.showModal)
   }

   const logout = () => {
      localStorage.removeItem("token")
      localStorage.removeItem("refresh-token")
      localStorage.removeItem("active")
      navigate(ROUTES.SIGN_IN)
   }

   const handleChallengesClick = () => {
      if (role == 1) {
         navigate(ROUTES.MY_CHALLENGES)
      }
      else {
         navigate(ROUTES.CREATER_CHALLENGES)
      }
      props?.setShowModal(!props?.showModal)
   }

   const handleTipsClick = () => {
      if (role == 1) {
         navigate(ROUTES.TIPS)
      }
      else{
         navigate(ROUTES.TIPS_CREATOR)
      }
      props?.setShowModal(!props?.showModal)

   }

   const handleEditProfile = () => {
      props?.setShowModal(!props?.showModal)
      props?.setIsModalVisible(!props?.isModalVisible)
   }

   const handleChangePassword = () => {
      props?.setShowModal(!props?.showModal)
   }



   return (
      <div className="dropdown user-drop " >
         <a onClick={userDropdown} className="login-info nav-item nav-link position-relative dropdown-toggle" style={{ cursor: 'pointer' }}>
            {props?.profileDetail?.profile_pic != null ? (
               <img src={props?.profileDetail?.profile_pic && !props?.profileDetail?.profile_pic?.includes("CoreSimulator") ? props?.profileDetail?.profile_pic : profile_image_placeholder} className="img-fluid" />
            ) : (
               <img src={profile_image_placeholder} className="img-fluid" />
            )}
            <span>
               <div className="text-black fw-600 mb-0 profiletext">
                  {props?.profileDetail?.name
                     ? props.profileDetail.name.charAt(0).toUpperCase() + props.profileDetail.name.slice(1)
                     : ''}
               </div>

            </span>
         </a>
         {
            props?.showModal &&
            <ul className="dropdown-menu edit-profile show">
               <form>
                  <div className="profile-upload text-center" style={{ cursor: 'pointer' }}>
                     {props?.profileDetail?.profile_pic != null ? <img src={props?.profileDetail?.profile_pic ? props?.profileDetail?.profile_pic : profile_image_placeholder} className="img-fluid" /> :
                        <img src={profile_image_placeholder} className="img-fluid" />}
                     <p className="f18-size mb-0 channel-text fw-500">{props?.profileDetail?.name}</p>
                     {
                        props?.profileDetail?.country &&
                        <p className="">+ {props?.profileDetail?.country} {props?.profileDetail?.phone_no}</p>
                     }
                  </div>
                  <hr>
                  </hr>
                  <div onClick={handleEditProfile} className="border-edit position-relative mt-3" >
                     <a>
                        <p className="mb-0 fw-500">Edit Profile</p>
                        <p className="arrow-icon mb-0"><img src={downArrow} className="img-fluid" /></p>

                     </a>
                  </div>
                  <div onClick={handleChangePassword} className="border-edit position-relative mt-3" data-bs-toggle="modal" data-bs-target="#change">
                     <a>
                        <p className="mb-0 fw-500">Change Password</p>
                        <p className="arrow-icon mb-0"><img src={downArrow} className="img-fluid" /></p>
                     </a>
                  </div>
                  <div onClick={handleChallengesClick} className="border-edit position-relative mt-3">
                     <a>
                        <p className="mb-0 fw-500">Challenges</p>
                        <p className="arrow-icon mb-0"><img src={downArrow} className="img-fluid" /></p>
                     </a>
                  </div>
                  <div onClick={handleTipsClick} className="border-edit position-relative mt-3">
                     <a>
                        <p className="mb-0 fw-500">Tips</p>
                        <p className="arrow-icon mb-0"><img src={downArrow} className="img-fluid" /></p>
                     </a>
                  </div>
                  <div className="login-btn mt-4 mt-lg-4">
                     <button onClick={logout} className="common-btn w-100 text-white" >Logout</button>
                  </div>
               </form>
            </ul>
         }

      </div>

   )
}

export default User