import $ from 'jquery'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../Components/Sidebar/sidebar.css'
import { logoPurple, search } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ROUTES from '../../Helper/route-helper'
import ChangePasssword from '../ChangePassword/ChangePasssword'
import EditProfile from '../EditProfile/editProfile'
import User from '../User/User'
const Header2 = (props) => {
    const location = useLocation();
    const [profileDetail, setProfileDetail] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [state, setState] = useState(true)
    const headerRef = useRef();
    const [showModal, setShowModal] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const navigate = useNavigate()
    const role = localStorage.getItem("role")
    const handleNavigate = () => {
        if (role == 1) {
            navigate(ROUTES.GLOBAL_SEARCH)
        }
    }
    
    async function getDetailsAPI() {
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setProfileDetail(result?.data);
        } else if (result?.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult?.data?.token)
                localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
                setRefresh(true)
            }
        }
    }

    function handleFunction() {
        if (state) {
            $('.sidebar-wrapper').addClass("sidebar-closed");
            $('.main-content').addClass("content-open");
            $('.sidebar-wrapper').addClass("sidebar-uper");
            $('.main-content').addClass("content-open-uper");
        } else {
            $('.main-content').removeClass("content-open");
            $('.sidebar-wrapper').removeClass("sidebar-uper");
            $('.main-content').removeClass("content-open-uper");
            $('.sidebar-wrapper').removeClass("sidebar-closed");
        }
        props.userFn(setShowModal)
        setState(!state)
    }

    const closeModel = (e) => {
        if (e.target == headerRef.current) {
            props.userFn(setShowModal)
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [refresh])

    useEffect(() => {
        props.userFn(setShowModal)
    }, [props.user])

    return (
        <>
            <div className="header-top">
                <div onClick={closeModel} ref={headerRef} className="top-dashboard d-flex justify-content-between">
                    <div className="grid-column-dashboard navbar-item flex-row custom-serach">
                        <i onClick={() => handleFunction(state)} className="fa fa-chevron-right bars-check-list bar-icon-cus greyslivdropdown user-droper-text icons-zie mr-0  mr-lg-4 anchor-cursor "
                            aria-hidden="true"></i>
                        {
                            (location.pathname === "/creator/subscription" || location.pathname === "/creator/subscription/") ? (
                                <img src={logoPurple} />
                            ) : (
                                <i
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleFunction()}
                                    className="fa fa-chevron-left right-icon-cus bars-check-list arrow-icon-res greyslivdropdown user-droper-text icons-zie mr-4 anchor-cursor"
                                    aria-hidden="true"
                                ></i>
                            )
                        }
                        <div className="form-search-second position-relative ms-3">
                            {/* <form>
                                <input type="text" name="search" className="form-control" placeholder="Search" onClick={() => navigate(ROUTES.SEARCH)} />
                                <button type="button" className="icon-search">
                                    <img src={search} alt="" className="search-img" />
                                </button>
                                <button type="button" className="filter-search"> <img src={filter} alt="" className="search-img" /></button>
                            </form> */}
                        </div>
                    </div>
                    <div className='ms-2'><div className="logo-header logo-responsive-head"><img src="/assets/images/header/logo_purple.svg" className="img-fluid" /></div></div>

                    <div className="ml-auto navbar-item flex-row header-search">
                        <div className="navbar-text d-flex align-items-center ms-auto">
                            {
                                role == 1 &&
                                <div onClick={() => handleNavigate()} className='form-inline mr-3'>
                                    <a className="form-control">Search</a>
                                    <button onClick={() => handleNavigate()} type="button" className="search-icon notify-bg">
                                        <img src={search} alt="" className="search-img" />
                                    </button>
                                </div>
                            }

                            <User isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} setShowModal={setShowModal} showModal={showModal} setProfileDetail={setProfileDetail} profileDetail={profileDetail} />
                        </div>
                    </div>
                </div>
            </div>
            <ChangePasssword />

            <EditProfile isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} setShowModal={setShowModal} showModal={showModal} setProfileDetail={setProfileDetail} profileDetail={profileDetail} />
        </>
    )
}

export default Header2