import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dotsbg, noChallenges, } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'
import { Dropdown } from 'react-bootstrap';
import DeleteContent from './deleteContent';
function BucketChallenges({ setDeltId, getDeleteAPI, dataList, setDeleteModal, deleteModal }) {
  const navigate = useNavigate()
  const handleDeleteOpen = (id) => {
    setDeltId(id)
    setDeleteModal(true)
  }
  const handleDetail = (id) => {
    localStorage.setItem("challenge_id", id)
    navigate(ROUTES.CREATER_CHALLENGES_DETAIL)
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className="fw-600 mb-0" style={{ marginLeft: 12 }}>Challenges</h3>
        <div className='col-6 text-end'>
          <button className=" common-btn" onClick={() => {
            navigate(ROUTES.CREATER_BUCKET_CHALLENGES)
          }}>+Add New</button>
        </div>
      </div>
      <section className="favchannel-main-cus ">
        <div className="container-fluid">
          <div className='row'>
            <div className="chanel-wrapper mt-2">
              <div className="row gy-2">
                {
                  dataList.length > 0 ? <> {
                    dataList?.map((item) => (
                      <div className="col-md-6 col-xxl-4 response-col" >
                        <div className="channel-bites-content">
                          <div className='position-relative'>
                            <img src={item.challenges_details.thumbnail} className="new-channelbite-img" alt="" />
                            <div className='dotsmain'>
                              <Dropdown style={{ cursor: "pointer" }}>
                                <Dropdown.Toggle id="custom-dropdown-toggle">
                                  <img src={dotsbg} className='img-fluid'></img>
                                </Dropdown.Toggle>
                                <Dropdown.Menu onClick={() => handleDeleteOpen(item.id)}>
                                  <Dropdown.Item> Remove</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>                                    <div className="bite-wrapper">

                            <div className='tooltip-container'>
                              <h4 className="schedule-title mb-0">{item.challenges_details.title?.length > 40 ? item.challenges_details.title?.substring(0, 40) + ".." : item.challenges_details.title}</h4>
                              <span className="tooltip-text">{item.challenges_details?.title}</span>
                            </div>
                            <div className="chanel-text d-flex gap-3 align-items-center">
                              <img src={item.challenges_details.channelData.profile_pic} className="bite-inner-img" alt="" />
                              <div>
                                <h6 className='fw-600 mb-0'>{item.challenges_details.channelData.name}</h6>
                                <p className='mt-1'>{item.challenges_details.category.name}</p>
                              </div>
                            </div>
                            <button className="common-btn" onClick={() => handleDetail(item.challenges_details.id)}> View Detail</button>
                          </div>
                        </div>
                      </div>
                    ))

                  }</> : <div className='no-search-results'>
                    <img src={noChallenges} alt="No Challenges" />
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </section>
      {deleteModal && <DeleteContent setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check='challenges' />}
    </div>
  )
}

export default BucketChallenges