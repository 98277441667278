import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom'
import { DEMO_IMAGE_FOUR, SCHEDULE_DOT } from '../../Constants/images'
import PriceTag from '../component/price-tag'
import { Dropdown } from 'react-bootstrap'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from "../../ControlComponents/react-loader";
import DeleteShopModal from './deleteModal'
import { NotificationManager } from 'react-notifications'
import EditModal from './editModal'
import ROUTES from '../../Helper/route-helper'

function SeriesShop({ setTabSwitch }) {
    const [loading, setLoading] = useState(false);
    const [series, setSeries] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const [price, setPrice] = useState("")
    const navigate = useNavigate()
    const handleDelete = (id) => {
        setDeltId(id)
        setDeleteModal(true)
    }

    const handleEdit = (item) => {
        setDeltId(item.id)
        setPrice(item.price)
        setEditOpen(true)
    }

    async function getDeleteAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/remove-series-price?id=" + deltId)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false)
            setDeleteModal(false)
            NotificationManager.success(result.message)
            fetchAllSeries()
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
        else {
            NotificationManager.error(result.message)

        }
    }

    async function fetchAllSeries() {
        setLoading(true)
        try {
            const result = await apiHelper.getRequest("creator/my-series?is_premium=1")
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                setSeries(result?.data?.allSeries)
            }
            else if (result.code == 401) {
                let refreshToken = localStorage.getItem("refresh-token")
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                }
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
                if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token)
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                    setRefresh(true)
                }
            }
        } catch (error) {
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchAllSeries();
    }, [refresh])

    const handleView = (id) => {
        localStorage.setItem("series_id", id)
        navigate(ROUTES.CREATER_SHOP_SERIES_DETAIL)
    }

    return (
        <>
            {loading && <ReactLoader />}
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>

                    </div>
                    {series.length == 0 && loading == false ? (
                        <div className="no-search-results">
                            <img src={DEMO_IMAGE_FOUR} alt="No Challenges" />
                            <h6 className='position-absolute' style={{ bottom: 15 }}>No Series yet</h6>
                        </div>
                    ) : (
                        series.map((data) => (
                            <div className="col-12 col-sm-6 col-lg-4 col-xxl-3 pb-4">
                                <div key={data?.id} className="latest-video mx-auto">
                                    <div className='position-relative' onClick={() => handleView(data.id)} style={{ cursor: "pointer" }}>
                                        <div className="latest-video-content position-relative  pt-2 pt-sm-3 px-2 px-sm-3"
                                            style={{ cursor: "pointer" }} >
                                            <img src={data?.thumbnail} className="img-fluid latest-vid-img new-latest-video" alt="Thumbnail" />
                                            <PriceTag classes={"price_tag"} firstValue={`$${data.price}`} secondValue={"00"} />
                                        </div>
                                    </div>
                                    <div className="latest-video-cus px-3 py-3">
                                        <div className='tooltip-container'>
                                            <h4 className="schedule-title fw-600" onClick={() => handleView(data.id)} style={{ cursor: "pointer" }}>{data.title}</h4>               
                                             <span className="tooltip-text">{data.title}</span>
                                        </div>
                                          <div className="latest-series-name">
                                            <div className="video-tile" onClick={() => handleView(data.id)} style={{ cursor: "pointer" }}>
                                                <p className='mb-0 fw-400'><span>{data?.episodesCount}</span> {data?.episodesCount === 1 ? "Episode" : "Episodes"}</p>
                                                <p className='mb-0 fw-500'>{data?.year}</p>
                                            </div>
                                            <div className='dotschedule bg-transparent'>
                                                <div className='dropdownschedule'>
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="custom-dropdown-toggle">
                                                            <img src={SCHEDULE_DOT} className='img-fluid' alt="dot" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className='record-card-dropdown'>
                                                            <Dropdown.Item onClick={() => handleEdit(data)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleDelete(data.id)}>Remove</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    {
                        deleteModal && <DeleteShopModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check="series" />
                    }
                    {
                        editOpen && <EditModal fetchAllSeries={fetchAllSeries} iniPrice={price} deltId={deltId} setEditOpen={setEditOpen} check="series" />
                    }

                </div>
            </div>
        </>
    )
}

export default SeriesShop