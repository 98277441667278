import React, { useState, useEffect } from "react";
import { image1, noSeries } from "../../Constants/images";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from "../../ControlComponents/react-loader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";
const ChooseSeries = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const tierItem = JSON.parse(localStorage.getItem("tierItem"));
  const navigate = useNavigate()

  async function getAPI() {
    setIsLoading(true);
    let result = await apiHelper.getRequest("creator/series-list");
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      setData(result.data);
      setIsLoading(false);
    } else if (result.code === 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        refresh_token: refreshToken,
        scope: "refresh_token",
      };
      let refreshTokenResult = await apiHelper.postRequest(
        "auth/refresh-token",
        data
      );
      if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem(
          "refresh-token",
          refreshTokenResult.data.refresh_token
        );
        setRefresh(true);
      }
    }
  }

  useEffect(() => {
    getAPI();
  }, [refresh]);

  async function handleSubmit() {
    setIsLoading(true);
    let data = {
      tier_id: tierItem.id,
      item_type: "1",
      item_id: selectedList,
    };
    let result = await apiHelper.postRequest("creator/add-tier-content", data);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false);
      navigate(ROUTES.CREATER_BUCKET_ACCESS)
      localStorage.setItem('message', result.message + 'series')

    } else if (result.code === 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        refresh_token: refreshToken,
        scope: "refresh_token",
      };
      let refreshTokenResult = await apiHelper.postRequest(
        "auth/refresh-token",
        data
      );
      if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem(
          "refresh-token",
          refreshTokenResult.data.refresh_token
        );
        setRefresh(true);
      }
    }
  }

  const handleCheckboxChange = (id) => {
    setSelectedList((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((seriesId) => seriesId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };



  const handleView = (id) => {
    localStorage.setItem("series_id", id)
    navigate(ROUTES.CREATER_SERIES_DETAIL)
  }

  return (
    <div className="main-inner-content">
      {isLoading && <ReactLoader />}
      <section className="choose-wrapper">
        <div className="container">
          <div className="row align-items-center py-3">
            <div className="col-sm-5">
              <div className="">
                <h3 className="fw-600">Choose Series</h3>
              </div>
            </div>
            <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
              <button className={selectedList?.length > 0 ? "common-btn" : "common-btn-grey"} onClick={handleSubmit} style={{ cursor: selectedList.length > 0 ? "pointer" : "not-allowed" }}>Submit</button>
            </div>
          </div>
          <div className="row">
            {
              data.length > 0 ?
                <>{data?.map((item) => (
                  <div className="col-lg-6 col-xxl-4 mb-3" key={item.id} style={{ cursor: 'pointer' }}>
                    <div className="channel-bites-content tier-choose position-relative">
                      <div className="chooseimg-main" onClick={() => handleView(item.id)}>
                        <img src={item.thumbnail} className="chooseinner-img" />
                      </div>
                      <div className="chooseinner-heading" onClick={() => handleView(item.id)}>
                        <h5>{item.title}</h5>
                        <p className="mb-0">{item?.category?.name}</p>
                        <p className="mb-0">
                          {item.episodes_count}{" "}
                          {item.episodes_count == 1 ? "Episode" : "Episodes"}
                        </p>
                      </div>
                      <div className="roundcheckbox">
                        <input
                          type="checkbox"
                          id={`checkbox-${item.id}`}
                          checked={selectedList.includes(item.id)}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                        <label htmlFor={`checkbox-${item.id}`}></label>
                      </div>
                    </div>
                  </div>
                ))}</> :
                <div style={{ flexDirection: 'column' }} className='no-search-results'>
                  <img src={noSeries} alt="No Challenges" />
                </div>
            }

          </div>
        </div>
      </section>
    </div>
  );
};

export default ChooseSeries;
