import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import { noChallenges } from '../../Constants/images';


function UserBucketChallenges({ dataList, detail }) {
    const navigate = useNavigate()
    const tierContentId = localStorage.getItem('tierContentId') || 0 + 'challenge'
    const handleDetail = (id) => {
        console.log(id,"item.challenges_details.id")
        if (detail?.is_activated == 1) {
            navigate(ROUTES.CHALLENGES_DETAILS, { state: { id: id } })
            localStorage.setItem("userChallengeId",id)
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className="fw-600 mb-0 mt-3" style={{ marginLeft: 12 }}>Challenges</h3>
            </div>
            <section className="favchannel-main-cus">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="chanel-wrapper mt-4">
                            <div className="row gy-2">
                                {
                                    dataList?.length > 0 ? <>{
                                        dataList?.map((item) => {
                                            const isHighlighted = tierContentId.replace(/\D/g, '') === item.challenges_details?.id.toString();
                                            return (
                                                <div className="col-md-6 col-xxl-4 response-col" style={{ cursor: detail.is_activated == 1 ? 'pointer' : 'not-allowed' }}>
                                                    <div className={` channel-bites-content  ${isHighlighted ? 'highlight' : ''}`}>
                                                        <div className='position-relative'>
                                                            <img src={item.challenges_details.thumbnail} className="channelbite-img" alt="" />
                                                        </div>
                                                        <div className="bite-wrapper">
                                                            <div className='tooltip-container'>
                                                                <h4 className="schedule-title mb-0">{item.challenges_details.title?.length > 40 ? item.challenges_details.title?.substring(0, 40) + ".." : item.challenges_details.title}</h4>
                                                                <span className="tooltip-text">{item.challenges_details?.title}</span>
                                                            </div>
                                                            <div className="chanel-text d-flex gap-3 align-items-center">
                                                                <img src={item.challenges_details.channelData.profile_pic} className="bite-inner-img" alt="" />
                                                                <div>
                                                                    <h6 className='fw-600 mb-0'>{item.challenges_details.channelData.name}</h6>
                                                                    <p className='mt-1'>{item.challenges_details.category.name}</p>
                                                                </div>
                                                            </div>
                                                            <button className="common-btn " onClick={() => handleDetail(item.challenges_details.id)} style={{ cursor: detail.is_activated == 1 ? 'pointer' : 'not-allowed' }}> View Detail</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        )

                                    }</> : <div className='no-search-results'>
                                        <img src={noChallenges} alt="No Challenges" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default UserBucketChallenges